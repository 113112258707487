import { Controller } from '@hotwired/stimulus';
import Coloris from '@melloware/coloris';
import MenuEditor from '../app/blog_menu_editor';

export default class extends Controller {
  static targets = [ 'iframe', 'designForm', 'spinner' ];

  static values = {
    siteId: Number,
    section: String,
  }

  connect() {
    this.styleTemplate = null;
    const _this = this;

    if (this.sectionValue === 'design') {
      const fontSelects = this.element.querySelectorAll('#blogDesignForm select');

      Coloris.init();
      Coloris({
        el: '.color-input',
        theme: 'default',
        onChange: () => this.updateIframeStyles(),
      });

      fontSelects.forEach(fontSelect => {
        fontSelect.addEventListener('change', () => this.updateIframeStyles());
      });
    }

    if (this.sectionValue === 'settings') {
      this.element.querySelectorAll('input[name="blog[blog_template_id]"]').forEach((elem) => {
        elem.addEventListener('change', function(event) {
          const templateId = event.target.value;
          _this.loadPreview(`/de/sites/${_this.siteIdValue}/blog/preview?blog_template_id=${templateId}`);
        });
      });

      const showDateCheckbox = this.element.querySelector('input#show_date');

      showDateCheckbox.addEventListener('change', function(event) {
        const articleDatesElements = _this.iframeTarget.contentWindow.document.querySelectorAll('.card-footer');
        const display = event.target.checked ? 'block' : 'none';

        articleDatesElements.forEach((element) => {
          element.style.display = display
        });
      });
    }

    if (this.sectionValue === 'menu') {
      const updateBtn = this.element.querySelector('#btnUpdate');
      const addBtn = this.element.querySelector('#btnAdd');
      const newItemLink = this.element.querySelector('#newItemLink');
      const editor = new MenuEditor('blogMenuEditor',
                                    'formModal',
                                    'frmEdit',
                                    this.iframeTarget.id);
      updateBtn.addEventListener('click', () => editor.update());
      addBtn.addEventListener('click', () => editor.add());
      newItemLink.addEventListener('click', () => editor.openNewItemForm());

      const blogForm = this.element.querySelector('#blogForm');
      const menuItemsInput = blogForm.querySelector("input[name='blog[menu_items]']");
      const menuItems = JSON.parse(menuItemsInput.value);

      editor.setData(menuItems);

      blogForm.addEventListener('submit', (e) => {
        e.preventDefault();
        menuItemsInput.value = editor.getString();
        e.target.submit();
      })
    }

    this.buildBlogPreview();
  }

  buildBlogPreview() {
    this.iframeTarget.setAttribute('src', '');

    fetch(`/de/sites/${this.siteIdValue}/blog.json`)
      .then(response => response.json())
      .then(data => {
        this.styleTemplate = data.data.attributes.style_template;
        this.loadPreview(`/de/sites/${this.siteIdValue}/blog/preview`);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  loadPreview(url) {
    const _this = this;

    this.iframeTarget.onload = function() {
      _this.spinnerTarget.classList.add('d-none');

      const previewDocument = this.contentDocument;

      const forms = previewDocument.querySelectorAll('form');
      const links = previewDocument.querySelectorAll('a');

      forms.forEach(form => {
        form.addEventListener('submit', function(e) {
          e.preventDefault();
        });
      });

      links.forEach(link => {
        link.addEventListener('click', function(e) {
          e.preventDefault();
        });
      });

      if (section == 'design') updateIframeStyles();
    };

    this.iframeTarget.src = url;
  }

  updateIframeStyles() {
    const formData = new FormData(this.designFormTarget);
    this.iframeTarget.contentWindow.loadBlogStyles(this.styleTemplate, formData);
  }
}