import * as bootstrap from 'bootstrap';

document.addEventListener('turbo:load', function() {
  // Default Tooltip
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  tooltipTriggerList.forEach(function (tooltipTriggerEl) {
    new bootstrap.Tooltip(tooltipTriggerEl);
  });

  // Default Popover
  const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
  popoverTriggerList.forEach(function (popoverTriggerEl) {
    new bootstrap.Popover(popoverTriggerEl);
  });

  // Menu toggle for admin dashboard
  const navToggle = document.getElementById('nav-toggle');
  const dbWrapper = document.getElementById('db-wrapper');
  if (navToggle && dbWrapper) {
    navToggle.addEventListener('click', function (e) {
      e.preventDefault();
      dbWrapper.classList.toggle('toggled');
    });
  }

  // Sidenav fixed for docs
  const sidebarNavLinks = document.querySelectorAll('.sidebar-nav-fixed a');
  sidebarNavLinks.forEach(function (link) {
    link.addEventListener('click', function (event) {
      if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
        var target = document.querySelector(this.hash);
        target = target ? target : document.querySelector('[name=' + this.hash.slice(1) + ']');
        if (target) {
          event.preventDefault();
          window.scrollTo({
            top: target.offsetTop - 90,
            behavior: 'smooth',
          });
          target.focus();
          if (target !== document.activeElement) {
            target.setAttribute('tabindex', '-1');
            target.focus();
          }
        }
      }
      sidebarNavLinks.forEach(function (navLink) {
        navLink.classList.remove('active');
      });
      this.classList.add('active');
    });
  });

  // Check all for checkbox
  const checkAll = document.querySelector('#checkAll');

  if (checkAll) {
    checkAll.addEventListener('click', function () {
      var checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach(function(checkbox) {
        if (checkbox !== checkAll) {
          checkbox.checked = checkAll.checked;
        }
      });
    });
  }

  // Btn icon for active
  const btnIcons = document.querySelectorAll('#btn-icon .btn-icon');
  if (btnIcons) {
    btnIcons.forEach(function (btnIcon) {
      btnIcon.addEventListener('click', function () {
        btnIcon.parentElement.classList.add('active');
        const siblings = getSiblings(btnIcon.parentElement);
        siblings.forEach(function (sibling) {
          sibling.classList.remove('active');
        });
      });
    });
  }

  function getSiblings(elem) {
    const siblings = [];
    let sibling = elem.parentNode.firstChild;
    for (; sibling; sibling = sibling.nextSibling) {
      if (sibling.nodeType === 1 && sibling !== elem) {
        siblings.push(sibling);
      }
    }
    return siblings;
  }

  // Stopevent for dropdown
  const stopEvents = document.querySelectorAll('.stopevent');
  stopEvents.forEach(function (stopEvent) {
    stopEvent.addEventListener('click', function (e) {
      e.stopPropagation();
    });
  });

  // Offcanvas
  const offcanvasElements = document.querySelectorAll('.offcanvas');
  offcanvasElements.forEach(function (offcanvasEl) {
    new bootstrap.Offcanvas(offcanvasEl);
  });

  // Dropdown toggle
  const dropdownToggleElements = document.querySelectorAll('.dropdown-toggle');
  dropdownToggleElements.forEach(function (dropdownToggleEl) {
    new bootstrap.Dropdown(dropdownToggleEl);
  });

  // Live Alert
  const liveAlertPlaceholder = document.getElementById('liveAlertPlaceholder');
  const liveAlertBtn = document.getElementById('liveAlertBtn');
  if (liveAlertPlaceholder && liveAlertBtn) {
    liveAlertBtn.addEventListener('click', function () {
      alert('Nice, you triggered this alert message!', 'success');
    });
  }

  function alert(message, type) {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = `
      <div class="alert alert-${type} alert-dismissible" role="alert">
        ${message}
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
        </button>
      </div>
    `;
    liveAlertPlaceholder.appendChild(wrapper);
  }

  var progressWrap = document.querySelector('.btn-scroll-top');
  if (progressWrap !== null) {
    var progressPath = document.querySelector('.btn-scroll-top path');
    var pathLength = progressPath.getTotalLength();
    var offset = 50;
    progressPath.style.transition = progressPath.style.WebkitTransition =
      'none';
    progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
    progressPath.style.strokeDashoffset = pathLength;
    progressPath.getBoundingClientRect();
    progressPath.style.transition = progressPath.style.WebkitTransition =
      'stroke-dashoffset 10ms linear';
    window.addEventListener('scroll', function (event) {
      var scroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      var height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      var progress = pathLength - (scroll * pathLength) / height;
      progressPath.style.strokeDashoffset = progress;
      var scrollElementPos =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollElementPos >= offset) {
        progressWrap.classList.add('active-progress');
      } else {
        progressWrap.classList.remove('active-progress');
      }
    });
    progressWrap.addEventListener('click', function (e) {
      e.preventDefault();
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    });
  }
});
