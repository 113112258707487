export default {
  empty: function(el) {
    while(el.firstChild) {
      el.firstChild.remove();
    }
  },
  findGetParameter: function(parameterName) {
    var result = null,
      tmp = [];
    var items = location.search.substr(1).split('&');
    for (var index = 0; index < items.length; index++) {
      tmp = items[index].split('=');
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
  },
  uuidv4: function() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  },
  parent: function(element, selector) {
    let parent;
    if (!element) {
      return;
    }

    while(element.parentNode) {
      parent = element.parentNode;

      if (selector) {
        element = parent.querySelector(selector);
        if (element) {
          break;
        }
      } else {
        element = parent;
        break;
      }

      element = parent;
    }
    return element;
  },
  isObjectEmpty: function(value) {
    if (value === null) {
      return true;
    }
    for (var key in value) {
      if (hasOwnProperty.call(value, key)) {
        return false;
      }
    }
    return true;
  },
  dig(target, keys) {
    let digged = target;
    for (const key of keys) {
      if (typeof digged === 'undefined' || digged === null) {
        return undefined;
      }
      if (typeof key === 'function') {
        digged = key(digged);
      } else {
        digged = digged[key];
      }
    }
    return digged;
  },
};