import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
  static targets = [ 
    'totalUsers',
    'totalPayingUsers',
    'totalOnePaymentUsers',
    'totalFreeUsers',
    'totalDowngradedUsers',
    'totalNoPlanUsers',
    'totalStripePlans',
    'totalDigistorePlans',
    'totalOnePaymentPlans',
    'stripePlans',
    'digistorePlans',
    'onePaymentPlans',
    'activeUsersForWeek',
    'activeUsersForMonth',
    'activeUsersForQuarter',
    'cancellationsForWeek',
    'cancellationsForMonth',
    'cancellationsForQuarter',
    'usersGrowthForWeek',
    'usersGrowthForMonth',
    'usersGrowthForQuarter',
    'monthSignups',
    'monthTrialStarted',
    'monthFirstPaymentMade',
    'monthClientsRetained',
    'quarterSignups',
    'quarterTrialStarted',
    'quarterFirstPaymentMade',
    'quarterClientsRetained',
    'halfYearSignups',
    'halfYearTrialStarted',
    'halfYearFirstPaymentMade',
    'halfYearClientsRetained',
  ];
  static values = { url: String };

  async connect() { 
    const loaderEl = document.getElementById('kpi-report-loader');

    const response  = await get(this.urlValue, { responseKind: 'json' });

    loaderEl.classList.add('d-none');

    if (response.ok) {
      const data = await response.json;
      this.totalUsersTarget.innerHTML = data.total_users;
      this.totalPayingUsersTarget.innerHTML = data.total_paying_users;
      this.totalOnePaymentUsersTarget.innerHTML = data.total_one_payment_users;
      this.totalFreeUsersTarget.innerHTML = data.total_free_users;
      this.totalDowngradedUsersTarget.innerHTML = data.total_downgraded_users;
      this.totalNoPlanUsersTarget.innerHTML = data.total_no_plan_users;
      this.totalStripePlansTarget.innerHTML = data.total_stripe_plans;
      this.totalOnePaymentPlansTarget.innerHTML = data.total_one_payment_plans;
      this.totalDigistorePlansTarget.innerHTML = data.total_digistore_plans;
      this.activeUsersForWeekTarget.innerHTML = data.active_users_for_week;
      this.activeUsersForMonthTarget.innerHTML = data.active_users_for_month;
      this.activeUsersForQuarterTarget.innerHTML = data.active_users_for_quarter;
      this.cancellationsForWeekTarget.innerHTML = data.cancellations_for_week;
      this.cancellationsForMonthTarget.innerHTML = data.cancellations_for_month;
      this.cancellationsForQuarterTarget.innerHTML = data.cancellations_for_quarter;
      this.usersGrowthForWeekTarget.innerHTML = data.users_growth_for_week;
      this.usersGrowthForMonthTarget.innerHTML = data.users_growth_for_month;
      this.usersGrowthForQuarterTarget.innerHTML = data.users_growth_for_quarter;

      let stripePlans = document.createElement('ul');
      stripePlans.classList.add('list-unstyled');
      Object.keys(data.stripe_plans).forEach((key) => {
        const listItem = document.createElement('li');
        listItem.innerHTML = `<span>${key}</span>: <span class='report-value'>${data.stripe_plans[key]}</span>`;
        stripePlans.appendChild(listItem);
      });
      this.stripePlansTarget.appendChild(stripePlans);

      let digistorePlans = document.createElement('ul');
      digistorePlans.classList.add('list-unstyled');
      Object.keys(data.digistore_plans).forEach((key) => {
        const listItem = document.createElement('li');
        listItem.innerHTML = `<span>${key}</span>: <span class='report-value'>${data.digistore_plans[key]}</span>`;
        digistorePlans.appendChild(listItem);
      });
      this.digistorePlansTarget.appendChild(digistorePlans);

      let onePaymentPlans = document.createElement('ul');
      onePaymentPlans.classList.add('list-unstyled');
      Object.keys(data.one_payment_plans).forEach((key) => {
        const listItem = document.createElement('li');
        listItem.innerHTML = `<span>${key}</span>: <span class='report-value'>${data.one_payment_plans[key]}</span>`;
        onePaymentPlans.appendChild(listItem);
      });
      this.onePaymentPlansTarget.appendChild(onePaymentPlans);

      this.monthSignupsTarget.innerHTML = data.month_signups;
      this.monthTrialStartedTarget.innerHTML = data.month_trial_started;
      this.monthFirstPaymentMadeTarget.innerHTML = data.month_first_payment_made;
      this.monthClientsRetainedTarget.innerHTML = data.month_clients_retained;

      this.quarterSignupsTarget.innerHTML = data.quarter_signups;
      this.quarterTrialStartedTarget.innerHTML = data.quarter_trial_started;
      this.quarterFirstPaymentMadeTarget.innerHTML = data.quarter_first_payment_made;
      this.quarterClientsRetainedTarget.innerHTML = data.quarter_clients_retained;

      this.halfYearSignupsTarget.innerHTML = data.half_year_signups;
      this.halfYearTrialStartedTarget.innerHTML = data.half_year_trial_started;
      this.halfYearFirstPaymentMadeTarget.innerHTML = data.half_year_first_payment_made;
      this.halfYearClientsRetainedTarget.innerHTML = data.half_year_clients_retained;
    } else {
      alert('Failed to fetch KPI report data');
    }
  }

}