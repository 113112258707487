export default {
  merge: function(originalText, variables, mergeSymbol) {
    let mergedText = originalText;
    mergeSymbol = mergeSymbol || '+';

    if (!originalText) {
      return false;
    }

    for (const key in variables) {
      let regex = new RegExp('\\' + mergeSymbol + '\\{' + key + '\\}', 'g');
      const value = typeof(variables[key]) === 'object'
        ? variables[key].value
        : variables[key];
      mergedText = mergedText.replace(regex, value);
    }

    // Cleaning not existing variables
    let emptyRegex = new RegExp('\\' + mergeSymbol + '\\{\.+\\}', 'g');
    return  mergedText.replace(emptyRegex, '');
  },
};
