class MpUploadAdapter {
  constructor(loader, fileUploadUrl) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.fileUploadUrl = fileUploadUrl;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file
      .then((file) => new Promise((resolve, reject) => {
        this._initRequest();
        this._initListeners(resolve, reject, file);
        this._sendRequest(file);
      }));
  }

  // Aborts the upload process.
  abort() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  // Initializes the XMLHttpRequest object using the URL passed to the constructor.
  _initRequest() {
    const xhr = this.xhr = new XMLHttpRequest();

    xhr.open('POST', this.fileUploadUrl, true);
    xhr.responseType = 'json';
  }

  // Initializes XMLHttpRequest listeners.
  _initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${ file.name }.`;

    xhr.addEventListener( 'error', () => reject( genericErrorText ) );
    xhr.addEventListener( 'abort', () => reject() );
    xhr.addEventListener( 'load', () => {
      const response = xhr.response;

      if ( !response || response.error ) {
        return reject(
          response && response.error ? response.error.message : genericErrorText
        );
      }

      resolve( {
        default: response.url,
      });
    });

    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt) => {
        if ( evt.lengthComputable ) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  _sendRequest( file ) {
    const data = new FormData();
    data.append('upload', file);
    this.xhr.send(data);
  }
}

function MpUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new MpUploadAdapter(loader, editor.config.get('fileUploadUrl'));
  };
}

export default MpUploadAdapterPlugin;
