import { Controller } from '@hotwired/stimulus';
import Swal from 'sweetalert2';
import { loadStripe } from '@stripe/stripe-js';

export default class extends Controller {
  static targets = [
    'submitButton',
    'paymentMethodSelect',
  ]

  static values = {
    returnUrl: String,
    stripeKey: String,
    clientSecret: String,
  }

  async connect() {
    this.stripe = await loadStripe(this.stripeKeyValue);
  }

  async processPayment(event) {
    event.preventDefault();
    const paymentMethodId = this.paymentMethodSelectTarget.value;
    const buttonText = this.submitButtonTarget.innerHTML;
    
    if (!paymentMethodId) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Please select a payment method!',
      });
      return;
    }

    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.innerHTML = this.submitButtonTarget.dataset.disableWith;

    const { error } = await this.stripe.confirmPayment({
      clientSecret: this.clientSecretValue,
      confirmParams: {
        return_url: this.returnUrlValue,
        payment_method: paymentMethodId,
      },
    });

    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        this.showErrorMessage(error.message);
      } else {
        this.showErrorMessage('Something went wrong!');
      }
    } 

    this.submitButtonTarget.innerHTML = buttonText;
    this.submitButtonTarget.disabled = false;
  }

  showErrorMessage(message) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  }
}