import { Controller } from '@hotwired/stimulus';
import ApexCharts from 'apexcharts';
import theme from '../app/theme';
import I18n from '../app/i18n';

export default class extends Controller {

  connect() {
    let sources = this.element.dataset.sources;
    sources = sources.replace(/&quot;/g, '"');
    const trafficSources = JSON.parse(sources);
    let labels = [], series = [];

    for(var i = 0; i < trafficSources.length; i++) {
      const typeTranslation = I18n.t(
        `traffic.${trafficSources[i].type.toLowerCase().replace(' ', '_')}`,
      );
      series.push(trafficSources[i].count);
      labels.push(typeTranslation || trafficSources[i].type);
    }

    const options = {
      series: series,
      labels: labels,
      colors: [
        theme.primary,
        theme.success,
        theme.danger,
        theme.warning,
        theme.info,
        theme.secondary,
      ],
      chart: {
        type: 'donut',
        height: 320,
      },
      legend: {
        show: true,
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: '50%',
          },
        },
      },
      stroke: {
        width: 2,
        colors: [theme.transparent],
      },
    };

    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }

  disconnect() {
    this.chart.destroy();
  }

}