import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  connect() {
    this.element.onload = function(e) {
      const previewDocument = e.target.contentDocument;
      if (previewDocument) {
        const forms = previewDocument.querySelectorAll('form');
        forms.forEach((form) => {
          form.addEventListener('submit', function(e) {
            e.preventDefault();
          });
        });
      }
    };
  }

}