import { Controller } from '@hotwired/stimulus';
import I18n from '../app/i18n';

export default class extends Controller { 
  static values = {
    domain: String,
    siteId: Number,
  }

  connect() {
    const styleVariationsUrl = this.domainValue + '/css/blog.styles.variations.css';

    tinymce.init({
      selector: `#${this.element.id}`,
      document_base_url: this.domainValue + '/',
      absolute_urls: true ,
      remove_script_host: true,
      height: '450',
      relative_urls: false,
      plugins: "image link",
      language: I18n.locale(),
      content_css: [
        'https://scripts.masterpages.com/vendor/bootstrap/css/bootstrap.min.css',
        'https://scripts.masterpages.com/vendor/fontawesome-free/css/all.min.css',
        'https://scripts.masterpages.com/css/fonts.css',
        styleVariationsUrl
      ],
      font_size_formats: "8px 9px 10px 11px 12px 14px 16px 18px 24px 30px 36px 48px 60px 72px 96px",
      font_family_formats: `
        Verdana='Verdana', Verdana, Arial, sans-serif;
        Abril FatFace='AbrilFatFace', Verdana, Arial, sans-serif;
        Bio Rhyme='BioRhyme', Verdana, Arial, sans-serif;
        Century Gothic='CenturyGothic', Verdana, Arial, sans-serif;
        Chivo='Chivo', Verdana, Arial, sans-serif;
        Corinthia='Corinthia', Verdana, Arial, sans-serif;
        Dosis='Dosis', Verdana, Arial, sans-serif;
        Enriqueta='Enriqueta', Verdana, Arial, sans-serif;
        Gothic='Gothic', Verdana, Arial, sans-serif;
        Halant='Halant', Verdana, Arial, sans-serif;
        Jaldi='Jaldi', Verdana, Arial, sans-serif;
        Maven Pro='MavenPro', Verdana, Arial, sans-serif;
        Merriweather='Merriweather', Verdana, Arial, sans-serif;
        Merriweather Sans='Merriweather Sans', Verdana, Arial, sans-serif;
        Montserrat='Montserrat', Verdana, Arial, sans-serif;
        Open Sans='Open Sans', Verdana, Arial, sans-serif;
        Oswald='Oswald', Verdana, Arial, sans-serif;
        PlayFair='PlayFair', Verdana, Arial, sans-serif;
        Quicksand='Quicksand', Verdana, Arial, sans-serif;
        Raleway='Raleway', Verdana, Arial, sans-serif;
        Roboto='Roboto', Verdana, Arial, sans-serif;
        Roboto Slab='Roboto Slab', Verdana, Arial, sans-serif;
        Rockwell='Rockwell', Verdana, Arial, sans-serif;
        Sansita='Sansita', Verdana, Arial, sans-serif;
        Spectral='Spectral', Verdana, Arial, sans-serif;`,
      menu: {
        edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall' },
        format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | styles | removeformat' },
      },
      toolbar: 'undo redo | link image | blocks | fontsize fontfamily forecolor | alignleft aligncenter alignright',
      images_upload_url: '/sites/' + this.siteIdValue + '/upload_article_image',
      file_picker_types: 'image',
      automatic_uploads: true,
    });
  }
}