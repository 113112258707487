import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  connect() {
    this.element.addEventListener('keypress', function() {
      if (this.classList.contains('is-invalid')) {
        this.classList.remove('is-invalid');
      }
    });
  }
}