const theme = {
  primary: 'var(--gk-primary)',
  secondary: 'var(--gk-secondary)',
  success: 'var(--gk-success)',
  info: 'var(--gk-info)',
  warning: 'var(--gk-warning)',
  danger: 'var(--gk-danger)',
  dark: 'var(--gk-dark)',
  light: 'var(--gk-light)',
  white: 'var(--gk-white)',
  gray100: 'var(--gk-gray-100)',
  gray200: 'var(--gk-gray-200)',
  gray300: 'var(--gk-gray-300)',
  gray400: 'var(--gk-gray-400)',
  gray500: 'var(--gk-gray-500)',
  gray600: 'var(--gk-gray-600)',
  gray700: 'var(--gk-gray-700)',
  gray800: 'var(--gk-gray-800)',
  gray900: 'var(--gk-gray-900)',
  black: 'var(--gk-black)',
  lightPrimary: 'var(--gk-light-primary)',
  lightSecondary: 'var(--gk-light-secondary)',
  lightSuccess: 'var(--gk-light-success)',
  lightDanger: 'var(--gk-light-danger)',
  lightWarning: 'var(--gk-light-warning)',
  lightInfo: 'var(--gk-light-info)',
  darkPrimary: 'var(--gk-dark-primary)',
  darkSecondary: 'var(--gk-dark-secondary)',
  darkSuccess: 'var(--gk-dark-success)',
  darkDanger: 'var(--gk-dark-danger)',
  darkWarning: 'var(--gk-dark-warning)',
  darkInfo: 'var(--gk-dark-info)',
  transparent: 'transparent',
  borderColor: 'var(--gk-border-color)',
};

export default theme;
