export default {
  make: function(method, url, body, headers) {
    let request;
    switch(method) {
      case 'GET':
        request = this.get(url, body);
        break;
      case 'POST':
        request = this.post(url, body, headers);
        break;
      default:
        console.log('Method not found');
        break;
    }

    return request;
  },
  get: function (url) {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url);

      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        }
      };

      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      };

      xhr.send();
    });
  },
  post: function(url, body, headers) {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open('POST', url);

      xhr.onreadystatechange = function () {
        if (this.readyState !== 4) return;

        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          resolve(xhr.response);
        }

        if (this.readyState === XMLHttpRequest.DONE && this.status >= 400) {
          reject(xhr.response);
        }
      };

      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      };

      for(let header in headers) {
        if (headers.hasOwnProperty(header)) {
          xhr.setRequestHeader(header, headers[header]);
        }
      }

      xhr.send(body);
    });
  },
  postJson: function(url, params) {
    let body = JSON.stringify(params);

    let headers = {
      'Content-Type': 'application/json',
    };

    return this.make('POST', url, body, headers);
  },
  getJson: function(url) {
    const body = '';
    const headers = {};

    return this.make('GET', url, body, headers).
      then((res) => {
        return JSON.parse(res);
      });
  },
};
