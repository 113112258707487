import TextMerger from '../utils/text_merger';

const toolPreview  = {
  id: 'tool-preview',
  htmlTemplate: null,
  cssTemplate: null,
  merger: TextMerger,
  previewElement: null,
  designFormId: 'tool-design-form',
  toolUI: null,
  loadDesign: function(design) {
    this.previewElement = document.getElementById(this.id);
    this.htmlTemplate = design.internal_html_template;
    this.cssTemplate =  design.internal_css_template;
  },
  hide: function() {
    this.previewElement.classList.add('d-none');
  },
  show: function() {
    this.previewElement.classList.remove('d-none');
  },
  update: function(htmlVariables, cssVariables) {
    const mergedHTML = this.merger.merge(this.htmlTemplate, htmlVariables);
    let mergedStyles = this.merger.merge(this.cssTemplate, cssVariables);

    this.show();

    mergedStyles = `<style>${mergedStyles}</style>`;

    const updatedTool = mergedStyles + mergedHTML;
    this.previewElement.innerHTML = updatedTool;

    document.querySelectorAll(
      `#${this.id} input[type="submit"], #${this.id} a`,
    ).forEach(function(input) {
      input.addEventListener('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
      });
    });
  },
};

export default toolPreview;
