import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';
import I18n from '../app/i18n';

export default class extends Controller {
  static targets = [
    'mainMessage',
    'errorMessage',
    'spinner',
  ]
  
  static values = { 
    siteId: Number,
    pageId: Number
  };

  connect() {
    this.checkStatus();
  }

  async checkStatus() {
    const url = `/sites/${this.siteIdValue}/pages/${this.pageIdValue}.json`;
    const response  = await get(url, { responseKind: 'json' });

    if (!response.ok) {
      setTimeout(function() {
        this.showError();
      }.bind(this), 2000);
      return;
    }

    const json = await response.json;
    const attributes = json.data?.attributes;
    
    if (attributes) {
      if (attributes.status === 'active' || attributes.status === 'draft') {
        setTimeout(function() {
          this.redirect();
        }.bind(this), 2000);
      } else if (attributes.status === 'defective') {
        setTimeout(function() {
          this.showError();
        }.bind(this), 2000);
      } else {
        setTimeout(function() {
          this.checkStatus();
        }.bind(this), 3000);
      }
    } else {
      setTimeout(function() {
        this.checkStatus();
      }.bind(this), 3000);
    }
  }

  redirect() {
    const url = `/${I18n.locale()}/sites/${this.siteIdValue}/pages/${this.pageIdValue}/editor`
    window.location.href = url;
  }

  showError() {
    this.mainMessageTarget.classList.add('d-none');
    this.spinnerTarget.classList.add('d-none');
    this.errorMessageTarget.classList.remove('d-none');
  }
}