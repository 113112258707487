import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'facebookServerConversionInput', 'facebookServerConversionToggle' ];

  connect() {
    this.toggleFacebookServerConversion();
  }

  toggleFacebookServerConversion() {
    this.facebookServerConversionInputTargets.forEach((inputEl) => {
      inputEl.disabled = !this.facebookServerConversionToggleTarget.checked;
    });
  }

  updateTrackingOptions(e) {
    const target = e.target;
    const trackIfUserAgreesOption = this.element.querySelector('#track_if_user');
    const dontTrackOption = this.element.querySelector('#dont');
    if (target.checked) {
      trackIfUserAgreesOption.parentElement.classList.remove('d-none');
    } else {
      trackIfUserAgreesOption.parentElement.classList.add('d-none');
      if (trackIfUserAgreesOption.checked) dontTrackOption.checked = true;
    }
  }
}

