const BasicForm = function(toolData, mainForm) {
  const mainInput = mainForm.input;

  this.id = 'tool-basic-form';

  this.changeAction = (input) => {
    mainForm.input[input.name] = input.value;
  };

  this.updateFields = () => {
    document.querySelectorAll(`#${this.id} input`).forEach((input) => {
      input.value = mainInput[input.name];
    });
  };

  document.querySelectorAll(`#${this.id} input`).forEach((input) => {
    input.addEventListener('change', () => {
      this.changeAction(input);
    });
  });

  this.init = () => {
    this.updateFields();
  };
};

export default BasicForm;