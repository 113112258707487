import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';
import Swal from 'sweetalert2';

export default class extends Controller {
  static values = {
    newMethodUrl: String,
    statusUrl: String,
  }  

  connect() {
    this.loadForm();
  }

  async loadForm() {
    const response  = await get(this.newMethodUrlValue, { responseKind: 'json' });

    if (response.ok) {
      const { client_secret, locale, user, stripe_publishable_key } = await response.json;

      const stripe = Stripe(stripe_publishable_key);
      const form = this.element.querySelector("#newPaymentMethod");
      const submitBtn = form.querySelector("#submit");
      const options = {
        clientSecret: client_secret,
        locale: locale,
      };
      const elements = stripe.elements(options);
      const paymentElement = elements.create('payment', {
        defaultValues: {
          billingDetails: { ...user }
        }
      });

      paymentElement.mount('#payment-element');

      form.addEventListener('submit', async (event) => {
        event.preventDefault();
        submitBtn.disabled = true;

        const confirmSetupResponse = await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: this.statusUrlValue,
          }
        });

        if (confirmSetupResponse.error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: confirmSetupResponse.error.message,
          });
          submitBtn.disabled = false;
        }
      });  
    } else {
      console.log('Unable to load payment method form');
    }
  }
}