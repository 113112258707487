export default {
  createMessage: function(message, container, type) {
    let existentMessage = container.querySelector('.custom-alert');

    if (existentMessage) {
      existentMessage.remove();
    }

    let messageContainer = document.createElement('div');
    if (type === 'error') {
      messageContainer.className = 'alert alert-danger custom-alert';
    } else {
      messageContainer.className = 'alert alert-success custom-alert';
    }

    messageContainer.innerHTML = `<p>${message}</p>`;

    container.insertBefore(messageContainer, container.firstChild);

    return messageContainer;
  },

  createSuccessMessage: function(message, container) {
    this.createMessage(message, container, 'success');
  },

  createErrorMessage: function(message, container) {
    this.createMessage(message, container, 'error');
  },
};
