// Dropzone.autoDiscover = false;
// import { Dropzone } from 'dropzone';
import * as bootstrap from 'bootstrap';
import { Validator } from './validator';
import I18n from '../i18n';
import messageHelper from '../utils/message_helper';
import request from '../utils/request';
import Coloris from '@melloware/coloris';
import toolPreview from './preview';
import BasicForm from './steps/basic';
import ContentForm from './steps/content';
import RulesForm from './steps/rules';
import OptinForm from './steps/optin';
import DesignForm from './steps/design';
import Quiz from './steps/quiz';

const LeadToolWizard = function(toolUrl, toolId, siteToolId, options = {}) {
  let that = this;
  let toolData = {};
  let leadToolId = toolId;
  let siteId = document.body.dataset.siteId;
  let quizForm;
  let designForm;

  this.rootContainer = document.getElementById('tool-form-container');
  this.action = this.rootContainer.dataset.action;
  this.validationUrl = '/api/v1/lead_tools/validate';
  this.optinProcesses = options.optinProcesses || {};
  this.rootContainer = document.getElementById('tool-form-container');
  this.previewCb = null;
  this.isQuiz = false;

  this.input = {
    site_id: siteId,
    lead_tool_id: leadToolId,
    content: {},
    design_settings: {},
    settings: {},
    rules: {},
    removed_assets: [],
  };

  this.validator = Validator;

  this.validateSettings = function() {
    return this.validator.validateSettings(this.input);
  };

  this.siteId = document.body.dataset.siteId;

  this.showMainError = function(message) {
    let errorContainer = document.querySelector('#main-error');
    let mainForm = document.querySelector('.leads-form');

    if (!errorContainer) {
      errorContainer = document.createElement('span');
      errorContainer.id = 'main-error';
      errorContainer.className = 'alert alert-danger';
      mainForm.insertBefore(errorContainer, mainForm.firstChild);
    }

    errorContainer.textContent = message;
    errorContainer.parentNode.scrollIntoView();
  };

  this.hideMainError = function() {
    let errorContainer = document.querySelector('#main-error');
    if (errorContainer) {
      errorContainer.remove();
    }
  };

  this.validateOptinAction = function() {
    let optinProcess = this.input.settings.optin_process;
    if (optinProcess.value === 'embedded-form') {
      if(!this.input.settings.third_party) {
        const tabEl = document.querySelector('#optin-tab');
        bootstrap.Tab.getInstance(tabEl).show();
        let formInputContainer = document
          .querySelector('textarea[name="third-party-form"]')
          .parentNode.parentNode;
        messageHelper.createMessage(
          I18n.t('errorFormSubmit'), formInputContainer, 'error',
        );
        formInputContainer.scrollIntoView();
        return false;
      }
    }

    if (optinProcess.value === 'optin-list') {
      if (!optinProcess.options.optin_process_id) {
        const tabEl = document.querySelector('#optin-tab');
        bootstrap.Tab.getInstance(tabEl).show();
        let formInputContainer = document
          .querySelector('select[name="optin_process_id"]')
          .parentNode.parentNode;
        messageHelper.createMessage(
          'You must create an optin process  first',formInputContainer, 'error',
        );
        formInputContainer.scrollIntoView();
        return false;
      }
    }

    return true;
  };

  this.submit = function() {
    const that = this;
    const submitBtn = document.getElementById('submit-lead-tool');
    const headers = {
      'Content-Type': 'application/json',
    };
    let postUrl, body;
    let optinValid = this.validateOptinAction();

    if (siteToolId) {
      postUrl = `/sites/${siteId}/lead_tools/${siteToolId}.json`;
    } else {
      postUrl = `/sites/${siteId}/lead_tools.json`;
    }

    submitBtn.disabled = true;

    if (optinValid) {
      body = JSON.stringify(this.input);
      this.validate(
        () => {
          let req = request.make('POST', postUrl, body, headers).
            then(function(response) {
              response = JSON.parse(response);
              const data = response.data;

              if (data) {
                window.location.href = `/sites/${siteId}/lead_tools/${data.id}/edit`;
              } else {
                that.showMainError(I18n.t('errorsomethingWrong'));
              }
            });

          req.catch(function() {
            submitBtn.disabled = false;
            that.showMainError(I18n.t('errorsomethingWrong'));
          });
        },
        () => {
          submitBtn.disabled = false;
        },
      );
    } else {
      submitBtn.textContent = I18n.t('save');
      submitBtn.disabled = false;
    }
  };

  this.validate = function(onSuccess, onError, opts) {
    opts = opts || {};
    const body = JSON.stringify(
      Object.assign(
        {
          slug: toolData.slug,
          lead_tool_id: toolData.lead_tool_id,
          validate_on: opts.validate_on,
        },
        this.input,
      ),
    );

    const headers = {
      'Content-Type': 'application/json',
    };

    request.make('POST', this.validationUrl, body, headers).
      then((response) => {
        response = JSON.parse(response);
        if (response.valid) {
          this.hideMainError();
          onSuccess && onSuccess();
        } else {
          const errors = response.errors;
          this.showMainError(errors.join('\n'));
          onError && onError();
        }
      })
      .catch((e) => {
        console.log('Error trying to validate', e);
      });
  };

  this.start = function() {
    const submitBtn = document.getElementById('submit-lead-tool');

    request.make('GET', toolUrl, '', {}).
      then((response) => {
        toolData = JSON.parse(response);
        toolData = toolData.data.attributes;

        this.loadInput(toolData);
      }).
      then(() => {
        const triggerTabList = [].slice.call(
          document.querySelectorAll('[data-bs-toggle="tab"]'),
        );
        triggerTabList.forEach(function(triggerEl) {
          const tabTrigger = new bootstrap.Tab(triggerEl);
          triggerEl.addEventListener('click', function (event) {
            event.preventDefault();
            tabTrigger.show();
          });
        });


        const basicForm = new BasicForm(toolData, this);
        basicForm.init();

        if (toolData.slug === 'quiz' || toolData.slug === 'quiz_on_page') {
          this.isQuiz = true;
          quizForm = new Quiz(this);
          quizForm.load();
        }

        const contentForm = new ContentForm(toolData, this);
        contentForm.init();

        const rulesForm = new RulesForm(toolData, this);
        rulesForm.init();

        const optinForm = new OptinForm(toolData, this);
        optinForm.init();

        const designForm = new DesignForm(toolData, this);
        designForm.init();

        // TODO: Find a way to change conditional of update preview
        if (this.currentDesign) {
          this.updatePreview();
          this.loadColorPickers();
        } else {
          console.log('No Designs present');
        }
      }).catch(function(e) {
        console.log('ERROR!', e);
      });

    submitBtn.addEventListener('click', function(e) {
      e.preventDefault();
      that.submit();
    });
  };

  this.loadColorPickers = function() {
    Coloris.init();
    Coloris({
      el: '.color-input',
      theme: 'default',
      onChange: (color, input) => designForm.changeAction(input),
    });
  };

  this.getChosenDesign = function() {
    return this
      .designs
      .find(
        (d) => Number(d.id) === Number(this.input.chosen_design_id),
      ) || this.currentDesign;
  };

  this.loadInput = function(toolData) {
    if (this.action === 'new') {
      this.adaptToolData(toolData);
    } else {
      this.currentDesign = toolData.current_design;
      toolPreview.loadDesign(this.currentDesign);

      this.input.slug = toolData.slug;
      this.input.name = toolData.name || '';
      this.input.content = Object.assign({}, toolData.content);
      this.input.rules = Object.assign({}, toolData.rules);
      this.input.settings = Object.assign({}, toolData.settings);
      this.input.design_settings = Object.assign({}, toolData.design_settings);

      if (toolData.design_settings.main_image &&
          toolData.design_settings.main_image.value) {
        this.input.design_settings.main_image.value = toolData.design_settings.main_image.value;
      }
      this.input.chosen_design_id = toolData.chosen_design_id;

      this.designs = toolData.designs;
    }
  };

  this.adaptToolData = function(defaultData) {
    const settings = Object.assign({}, defaultData.settings);
    const content = Object.assign({}, defaultData.content);
    const rules = Object.assign({}, defaultData.rules);

    const defaultDesign = defaultData.designs.find((design) => design.default);
    const defaultDesignSettings = JSON.parse(JSON.stringify(defaultDesign.settings));

    this.designs = defaultData.designs;
    this.currentDesign = JSON.parse(JSON.stringify(defaultDesign));

    if (this.currentDesign) {
      this.input.chosen_design_id = this.currentDesign.id;
      this.input.design_settings = defaultDesignSettings;
      toolPreview.loadDesign(this.currentDesign);
    } else {
      console.log('Default design not found!!');
    }

    this.input.slug = defaultData.slug;
    this.input.name = defaultData.name;
    for (const field in content) {
      if (Object.prototype.hasOwnProperty.call(content, field)) {
        if (field === 'questions') {
          content.questions.forEach((question) => {
            this.translateQuestion(question);
          });
          this.input.content.questions = [...content.questions];
        } else {
          let localized;
          let tag = content[field].value_locale_tag;

          if (tag) {
            localized = I18n.t(tag);
          } else {
            localized = content[field]['value'];
          }

          this.input.content[field] = localized;
        }
      }
    }

    for (const type in settings) {
      if (Object.prototype.hasOwnProperty.call(settings, type)) {
        let setting = settings[type];
        this.input.settings[type] = {};

        if (type === 'fields') {
          let currentFields = this.input.settings['fields'];
          currentFields = {};
          currentFields.values = [];

          setting.values.forEach((value) => {
            if (value.checked) {
              currentFields.values.push(value);
            }
          });

          this.input.settings['fields'] = currentFields;
        } else if (type === 'next_step') {
          this.input.settings['next_step'] = setting;
        } else if (type === 'slices') {
          this.input.settings['slices'] = settings[type];
        } else {
          this.input.settings[type].value = setting.value;
          this.input.settings[type].options = setting.options;
        }
      }
    }

    let currentRules = this.input.rules;

    for (const type in rules) {
      let rule = rules[type];
      currentRules[type] = currentRules[type] || {};
      currentRules[type].value = rule.value;
      currentRules[type].options = rule.options;
    }
  };

  this.translateQuestion = function(question) {
    const that = this;
    const descLocaleTag = question.description_locale_tag;
    const textLocaleTag = question.text_locale_tag;
    const answers = question.answers;

    if (descLocaleTag) question.description = I18n.t(descLocaleTag);
    if (textLocaleTag) question.text = I18n.t(textLocaleTag);

    answers.forEach((answer) => {
      const answerTextLocaleTag = answer.text_locale_tag;
      if (answerTextLocaleTag) answer.text = I18n.t(answerTextLocaleTag);

      if (answer.questions) {
        answer.questions.forEach((question) => {
          that.translateQuestion(question);
        });
      }
    });
  };

  this.updatePreview = function() {
    if (quizForm) {
      quizForm.updatePreview();
    } else {
      toolPreview.update(this.input.content, this.input.design_settings);
    }
  };
};

export default LeadToolWizard;
