import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';
import Swal from 'sweetalert2'; 

export default class extends Controller {
  static values = {
    recordId: Number,
    publishUrl: String,
    unpublishUrl: String,
    statusUrl: String,
    showUrl: String,
    pageStatus: String,
  }

  static targets = ['status', 'spinner']

  connect() {
    if (this.pageStatusValue === 'uploading') this.checkStatus();
  }

  publish(e) {
    e.preventDefault();
    this.spinnerTarget.classList.remove('d-none');
    this.statusTarget.classList.add('d-none');

    fetch(this.publishUrlValue, {
      method: 'POST',
    })
      .then(response => response.json())
      .then(() => this.checkStatus())
      .catch(error => console.error(error));  
  }

  unpublish(e) {
    e.preventDefault();
    this.spinnerTarget.classList.remove('d-none');
    this.statusTarget.classList.add('d-none');
    const _this = this;

    fetch(this.unpublishUrlValue, {
      method: 'POST',
    })
      .then(response => response.json())
      .then((data) => {
        if (data && data.error_message) {
          const message = data.error_message || 'Unable to deactivate';
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: message,
          });
          _this.spinnerTarget.classList.add('d-none');
          _this.statusTarget.classList.remove('d-none');
        } else {
          this.checkStatus();
        }
      })
      .catch(error => console.error(error));
  }

  checkStatus() {
    const _this = this;

    fetch(this.statusUrlValue, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(data => {
        const record = data.data.attributes;
        if (record.status === 'published' || record.status === 'disabled' || record.status === 'locked') {
          _this.updateRecordView();
        } else {
          setTimeout(function () {
            return _this.checkStatus();
          }, 1000);
        }
      })
      .catch(error => console.error(error));
  }

  async updateRecordView() {
    const response = await get(this.showUrlValue, { responseKind: 'turbo-stream' });
    if (!response.ok) {
      this.spinnerTarget.classList.add('d-none');
      this.statusTarget.classList.remove('d-none');
      alert('Something went wrong. Please try again.');
    }
  }
}