import ImagePicker from '../../image_picker';
import requestUtils from '../../../utils/request';
import toolPreview from '../preview';

const DesignForm = function(data, mainForm) {
  this.id = 'tool-design-form';
  this.previewList = [];
  this.designs = data.designs;
  this.currentDesign = data.current_design;
  this.form = document.querySelector(`#${this.id}`);

  this.changeAction = (input) => {
    mainForm.input.design_settings[input.name].value = input.value;

    mainForm.updatePreview();
  };

  this.updateInputs = function() {
    document
      .querySelectorAll(`#${this.id} input, #${this.id} select`)
      .forEach((input) => {
        if (input.type === 'text' || input.tagName.toLowerCase() === 'select') {
          input.value = mainForm.input.design_settings[input.name].value;
        }
      });
  };

  this.updateFields = function() {
    let form = document.querySelector(`form#${this.id}`);

    form.querySelectorAll('.image-drop').forEach((picker) => {
      let siteId = document.body.dataset.siteId;
      let uploadURL = '/sites/' + siteId + '/upload_tool_image';
      let deleteURL = '/sites/' + siteId + '/remove_image';
      let name = picker.dataset.name;

      let imagePicker = new ImagePicker(picker, uploadURL, response => {
        this.currentDesign.settings[name].value = response.data.url;
        mainForm.input.design_settings[name].value = response.data.url;
        mainForm.input.design_settings[name].userImage = true;
        this.form.querySelector('.current-image').innerHTML = '';
        mainForm.updatePreview();
      }, (url) =>  {
        let body = {
          url: url,
        };

        requestUtils.postJson(deleteURL, body).then(() => {
          const design = this.designs.find(
            (design) => design.id === this.currentDesign.id,
          );
          mainForm.input.design_settings[name].value = design.settings[name].value;
          delete(mainForm.input.design_settings[name].userImage);
          mainForm.updatePreview();
        });
      });
    });

    this.form.querySelectorAll('.remove-image').forEach((btn) => {
      btn.addEventListener('click', () => {
        const url = btn.dataset.src;
        const name = btn.dataset.name;
        const design = this.designs.find((design) => design.id === this.currentDesign.id);
        mainForm.input.removed_assets.push(url);
        this.form.querySelector('.current-image').innerHTML = '';
        mainForm.input.design_settings[name].value = design.settings[name].value;
        delete(mainForm.input.design_settings[name].userImage);
        mainForm.updatePreview();
      });
    });

    mainForm.updatePreview();
  };

  this.loadMainImage = function() {
    const designSettings = mainForm.input.design_settings;
    const mainImage = designSettings.main_image;

    if (mainImage && mainImage.userImage) {
      return designSettings.main_image.value;
    } else {
      return null;
    }
  };

  this.loadPreview = function(previewEl) {
    const currentImage = this.loadMainImage();
    let designId = previewEl.dataset.designId;
    let designSettings;

    if (designId) {
      let designData = this
        .designs
        .find((design) => Number(design.id) === Number(designId));
      this.currentDesign = designData;

      if (data.chosen_design_id) {
        designSettings = data.design_settings;
      } else {
        designSettings = designData.settings;
      }

      if (currentImage) {
        mainForm.input.design_settings.main_image.value = currentImage;
      } else {
        if (designData.settings.main_image) {
          const newSettings = Object.assign({}, mainForm.input.design_settings);
          newSettings.main_image.value = designData.settings.main_image.value;
          mainForm.input.design_settings = newSettings;
        }
      }

      // for (let setting in designSettings) {
      //  mainForm.input.design_settings[setting] = designSettings[setting];
      // }

      mainForm.input.chosen_design_id = designId;

      this.previewList.forEach((el) => el.classList.remove('active'));
      previewEl.classList.add('active');

      this.updateInputs();
      mainForm.loadColorPickers();

      toolPreview.loadDesign(designData);

      mainForm.updatePreview();
    }
  };

  this.init = function() {
    this.updateFields();
    this.updateInputs();

    document.querySelectorAll(
      `#${this.id} input, #${this.id} select`,
    ).forEach((input) => {
      input.addEventListener('change', () => {
        this.changeAction(input);
      });
    });

    this.previewList = document.querySelectorAll(`#${this.id} .preview-styles`);

    this.previewList.forEach((preview) => {
      preview.addEventListener('click', () => {
        this.loadPreview(preview);
      });
    });
  };
};

export default DesignForm;
