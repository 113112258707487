import { Controller } from '@hotwired/stimulus';
import ApexCharts from 'apexcharts';
import theme from '../app/theme';

export default class extends Controller {

  connect() {
    const stats = JSON.parse(this.element.dataset.stats);
    const options = {
      series: [
        {
          name: '',
          data: stats.total,
          colors: [theme.primary],
        },
      ],
      chart: {
        toolbar: {
          show: false,
        },
        height: 400,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [4, 3, 3],
        curve: 'smooth',
        dashArray: [0, 5, 4],
      },
      legend: {
        show: false,
      },
      colors: [theme.primary],
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: stats.days,
        labels: {
          style: {
            fontSize: '12px',
            fontFamily: 'Inter',
            cssClass: 'apexcharts-xaxis-label',
            colors: theme.gray500,
          },
        },
        axisBorder: {
          show: true,
          color: theme.borderColor,
          height: 0,
          width: '100%',
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: theme.borderColor,
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: '12px',
            fontFamily: 'Inter',
            cssClass: 'apexcharts-xaxis-label',
          },
          offsetX: -12,
          offsetY: 0,
        },
      },
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      },
      grid: {
        borderColor: theme.borderColor,
      },
    };

    this.chart = new ApexCharts(this.element, options);
    this.chart.render();
  }

  disconnect() {
    this.chart.destroy();
  }

}