import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  connect() {
    window.addEventListener('scroll', () => this.updateSticky());
  }

  updateSticky() {
    if (window.scrollY > 400) {
      this.element.style.position = 'sticky';
      this.element.style.top = '0px';
    } else {
      this.element.style.position = 'relative';
      this.element.style.top = 'auto';
    }
  }
}