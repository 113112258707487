import I18n from './i18n';
import * as bootstrap from 'bootstrap';

class MenuEditor {
  constructor(idSelector, modalIdSelector, formId, iframeElId) {
    this.main = document.getElementById(idSelector);
    this.modalEl = document.getElementById(modalIdSelector);
    this.modal = new bootstrap.Modal(this.modalEl);
    this.form = document.getElementById(formId);
    this.iframeEl = document.getElementById(iframeElId);
    this.settings = {
      labelEdit: '<i class="bi bi-pencil-square clickable"></i>',
      labelRemove: '<i class="bi bi-trash clickable"></i>',
      textConfirmDelete: I18n.t('blog_settings.delete_menu_item_confirmation'),
    };
    this.itemEditing = null;
    this.updateButton = this.modalEl.querySelector('#btnUpdate');
    this.addButton = this.modalEl.querySelector('#btnAdd');

    this.form.addEventListener('change', (e) => {
      const target = e.target;
      if (target.id === 'link_type') {
        e.preventDefault();
        this.updateLinkFields(target.value);
      }
    });
  }

  handleRemoveItemClick(e) {
    e.preventDefault();
    if (confirm(this.settings.textConfirmDelete)) {
      e.target.closest('li').remove();
      this.updateButtons();
      this.updateIframeMenuItems();
    }
  }

  handleEditItemClick(e) {
    e.preventDefault();
    this.itemEditing = e.target.closest('li');
    this.editItem(this.itemEditing);
  }

  handleMoveUpClick(e) {
    e.preventDefault();
    const li = e.target.closest('li');
    li.previousElementSibling.before(li);
    this.updateButtons();
    this.updateIframeMenuItems();
  }

  handleMoveDownClick(e) {
    e.preventDefault();
    const li = e.target.closest('li');
    li.nextElementSibling.after(li);
    this.updateButtons();
    this.updateIframeMenuItems();
  }

  sortableListsToJson(element) {
    const arr = [];
    const items = element.querySelectorAll('li');
    for (let i = 0; i < items.length; i++) {
      const li = items[i];
      const object = li.dataset;
      arr.push(object);
    }
    return arr;
  }

  updateButtons() {
    const listItems = this.main.querySelectorAll('li');
    listItems.forEach((item, index) => {
      const btnUp = item.querySelector('.btnUp');
      const btnDown = item.querySelector('.btnDown');
      if (index === 0) {
        if (btnUp) btnUp.style.display = 'none';
      } else {
        if (btnUp) btnUp.style.display = 'block';
      }
      if (index === listItems.length - 1) {
        if (btnDown) btnDown.style.display = 'none';
      } else {
        if (btnDown) btnDown.style.display = 'block';
      }
    });
  }

  hideButtons(element, buttons) {
    for (let i = 0; i < buttons.length; i++) {
      element.querySelector('.btn-group').children[i].style.display = 'none';
    }
  }

  createMenu(arrayItem) {
    this.main.classList.add('pl-0');
    this.main.style.paddingTop = '10px';
    for (let i = 0; i < arrayItem.length; i++) {
      const v = arrayItem[i];
      const itemObject = {
        text: '',
        href: '',
        icon: 'empty',
        target: '_self',
        title: '',
      };
      const temp = Object.assign({}, v);
      const item = Object.assign(itemObject, temp);
      const li = document.createElement('li');
      li.classList.add('list-group-item');
      const div = document.createElement('div');
      div.classList.add('d-flex', 'justify-content-between', 'align-items-center');
      const span = document.createElement('span');
      span.classList.add('txt');
      span.textContent = v.text;
      span.style.marginRight = '5px';
      const divbtn = this.TButtonGroup();
      for (const prop in item) {
        li.dataset[prop] = item[prop];
      }
      div.appendChild(span);
      div.appendChild(divbtn);
      li.appendChild(div);
      this.main.appendChild(li);
    }
    return this.main;
  }

  TButton(attr) {
    const a = document.createElement('a');
    a.classList.add('clickable');
    attr.classCss.split(' ').forEach((c) => {
      a.classList.add(c);
    });
    a.href = '#';
    a.innerHTML = attr.text;
    return a;
  }

  TButtonGroup() {
    const divbtn = document.createElement('div');
    divbtn.classList.add('btn-group', 'float-right');
    const btnEdit = this.TButton({
      classCss: 'btn btn-primary btn-sm btnEdit',
      text: this.settings.labelEdit,
    });
    const btnRemv = this.TButton({
      classCss: 'btn btn-danger btn-sm btnRemove',
      text: this.settings.labelRemove,
    });
    const btnUp = this.TButton({
      classCss: 'btn btn-secondary btn-sm btnUp btnMove',
      text: '<i class="bi bi-caret-up clickable"></i>',
    });
    const btnDown = this.TButton({
      classCss: 'btn btn-secondary btn-sm btnDown btnMove',
      text: '<i class="bi bi-caret-down clickable"></i>',
    });
    btnEdit.addEventListener('click', (e) => this.handleEditItemClick(e));
    btnRemv.addEventListener('click', (e) => this.handleRemoveItemClick(e));
    btnUp.addEventListener('click', (e) => this.handleMoveUpClick(e));
    btnDown.addEventListener('click', (e) => this.handleMoveDownClick(e));
    divbtn.appendChild(btnUp);
    divbtn.appendChild(btnDown);
    divbtn.appendChild(btnEdit);
    divbtn.appendChild(btnRemv);
    return divbtn;
  }

  updateLinkFields(linkType) {
    const linkEl = document.getElementById('linkField');
    const pageEl = document.getElementById('sitePageSelect');
    if (linkType === 'internal') {
      linkEl.style.display = 'none';
      pageEl.style.display = 'block';
    } else {
      linkEl.style.display = 'block';
      pageEl.style.display = 'none';
    }
  }

  editItem(item) {
    const data = item.dataset;
    const inputs = this.form.querySelectorAll('.item-menu');
    inputs.forEach((input) => {
      input.value = data[input.name];
    });
    this.updateLinkFields(data.link_type);
    this.form.querySelector('.item-menu').focus();
    this.updateButton.removeAttribute('disabled');
    this.updateButton.style.display = 'block';
    this.addButton.style.display = 'none';
    this.modal.show();
  }

  resetForm() {
    this.form.reset();
    this.updateButton.setAttribute('disabled', true);
    this.updateButton.style.display = 'none';
    this.addButton.style.display = 'block';
    this.itemEditing = null;
  }

  stringToArray(str) {
    let obj;
    try {
      obj = JSON.parse(str);
    } catch (err) {
      console.log('The string is not a valid JSON.');
      return null;
    }
    return obj;
  }

  add() {
    const data = {};
    const inputs = this.form.querySelectorAll('.item-menu');
    inputs.forEach((input) => {
      data[input.name] = input.value;
    });
    const btnGroup = this.TButtonGroup();
    const textItem = document.createElement('span');
    textItem.classList.add('txt');
    textItem.textContent = data.text;
    const div = document.createElement('div');
    div.classList.add('d-flex', 'justify-content-between', 'align-items-center');
    div.appendChild(textItem);
    div.appendChild(btnGroup);
    const li = document.createElement('li');
    li.classList.add('list-group-item');
    for (const prop in data) {
      li.dataset[prop] = data[prop];
    }
    li.appendChild(div);
    this.main.appendChild(li);
    this.updateButtons();
    this.updateIframeMenuItems();
    this.resetForm();
    this.modal.hide();
  }

  update() {
    const cEl = this.itemEditing;
    if (!cEl) {
      return;
    }
    const inputs = this.form.querySelectorAll('.item-menu');
    inputs.forEach((input) => {
      cEl.dataset[input.name] = input.value;
    });
    cEl.querySelector('span.txt').textContent = cEl.dataset.text;
    this.resetForm();
    this.modal.hide();
    this.updateIframeMenuItems();
  }

  openNewItemForm() {
    this.updateButton.style.display = 'none';
    this.addButton.style.display = 'block';
    this.resetForm();
    this.updateLinkFields('internal');
    this.modal.show();
  }

  getString() {
    const obj = this.sortableListsToJson(this.main);
    return JSON.stringify(obj);
  }

  updateIframeMenuItems() {
    const menuItems = this.sortableListsToJson(this.main);
    const nav = this.iframeEl.contentDocument.querySelector('ul.navbar-nav');
    nav.innerHTML = '';

    menuItems.forEach((menuItem) => {
      const listItem = document.createElement('li');
      const link = document.createElement('a');
      listItem.classList = 'nav-item';
      link.classList = 'nav-link';
      link.href = menuItem.href;
      link.textContent = menuItem.text;
      link.target = menuItem.target;
      listItem.append(link);
      nav.append(listItem);
    });
  }

  setData(strJson) {
    const arrayItem = (Array.isArray(strJson)) ? strJson : this.stringToArray(strJson);
    if (arrayItem !== null) {
      this.main.innerHTML = '';
      this.createMenu(arrayItem);
      this.updateButtons();
    }
  }
}

export default MenuEditor;
