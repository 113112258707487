import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {

  async updateSitesSelect(e) {
    e.preventDefault();

    const pageId = e.target.dataset.pageId;
    const agencyClientId = e.target.value;
    const sitesSelectEl = document.querySelector(`#page-${pageId}-sites select`);

    sitesSelectEl.disabled = true;

    const url = `/en/agency_clients/${agencyClientId}/sites?page_id=${pageId}`;
    await get(url, { responseKind: 'turbo-stream' });
  }
}