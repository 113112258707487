import { Controller } from '@hotwired/stimulus';
import I18n from '../app/i18n';
import { post } from '@rails/request.js';
const countdown = require('../vendor/countdown.min.js');

export default class extends Controller {
  static values = { 
    siteId: Number,
    domain: String,
    creationStamp: Number 
  };

  connect() {
    const timerEl = this.element.querySelector('#timer');
    
    if (timerEl) {
      const timestampMs = parseInt(this.creationStampValue) * 1000;
      const days = 7;

      const endDate = new Date(timestampMs);
      endDate.setDate(endDate.getDate() + days);

      countdown(
        function(ts) {
          const timeText = I18n.t('strategy.time_remaining', { days: ts.days, hours: ts.hours, minutes: ts.minutes, seconds: ts.seconds });
          timerEl.textContent = timeText;
        },
        endDate,
        countdown.DAYS | countdown.HOURS | countdown.MINUTES | countdown.SECONDS
      );
    }
  }

  async completeShareTask() {
    const url = '/sites/' + this.siteIdValue + '/update_strategy';
    const response = await post(url, { responseKind: 'json' });

    if (response.ok) {
      const shareCardHeader = document.querySelector('#card-project-share .card-header');
      const iconEl = document.createElement('i');
      iconEl.classList.add('bi', 'bi-check-lg', 'text-success');
      shareCardHeader.appendChild(iconEl);
    }
  }

  shareProject(e) {
    e.preventDefault();
    const _this = this;

    FB.ui({
      method: 'share',
      href: this.domainValue,
      quote: I18n.t('facebook.shareSite'),
      display: 'popup',
    }, function(response) {
      _this.completeShareTask();
    });
  }
} 
