import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'publishLabel',
    'publishLater',
    'publishLaterCheckbox',
    'publishLaterLabel',
    'publishLaterTime'
  ]

  togglePublishOptions(e) {
    if (e.target.value === 'true') {
      this.publishLaterTarget.classList.remove("d-none")
    } else {
      this.publishLaterTarget.classList.add("d-none")
    }
  }

  togglePublishLater(e) {
    if (e.target.value === 'true') {
      this.publishLaterTimeTarget.classList.remove("d-none")
    } else {
      this.publishLaterTimeTarget.classList.add("d-none")
    }
  }
}
