import { Controller } from '@hotwired/stimulus';
import Coloris from '@melloware/coloris';

export default class extends Controller {
  static targets = [ 'fontSelect', 'fontPreview', 'fontTitle' ];

  connect() {
    console.log('SiteDesignFormController connected'); 
    Coloris.init();
    Coloris({
      el: '.color-input',
      theme: 'default',
    });

    this.updatePreview();
  }

  updatePreview() {
    this.fontPreviewTarget.style.fontFamily = this.fontSelectTarget.value;
    this.fontTitleTarget.style.fontFamily = this.fontSelectTarget.value;
    this.fontTitleTarget.textContent = this.fontSelectTarget.value;
  }
}
