import { Controller } from '@hotwired/stimulus';
import { get } from '@rails/request.js';
import I18n from '../app/i18n';

export default class extends Controller {
  static targets = [
    'tabs',
    'tabsContent',
    'iframe',
    'templateLink',
    'templateOptionsInput',
  ];

  connect() {
    const templateId = this.element.dataset.template;
    this.template = null;
    this.chosenOptions = {};
    this.properties = [];
    this.defaultOptions = [];

    this.buildTemplateInfo(templateId);
  }

  async buildTemplateInfo(templateId) {
    this.tabsTarget.innerHTML = '';
    this.tabsContentTarget.innerHTML = '';
    this.iframeTarget.src = '';

    const response = await get(`/templates/${templateId}.json`);

    if (response.ok) {
      const data = await response.json;
      this.template = data.data.attributes;
      this.template['id'] = data.data.id;
  
      this.templateLinkTarget.href = "https://" + this.template.domain;
      this.chosenOptions['template_id'] = this.template.id;
      this.saveChosenOptions();
      this.properties = this.template.properties;

      for(let i = 0; i < this.template.properties.length; i++) {
        const propertyEl = this.buildProperties(this.template.properties[i], i);
        if (i === 0) {
          propertyEl.querySelectorAll('.tab').forEach(tab => {
            tab.classList.add('active');
          });
          propertyEl.classList.add('active');
        }
        this.tabsContentTarget.append(propertyEl);
      }

      this.loadPreview('/de/examples?template_id=' + this.template.id);
      this.refreshTemplateEvents();
    } else {
      alert('Error loading template');
    }  
  }

  loadPreview(url) {
    const iframe = document.querySelector('iframe');
    const _this = this;
    
    iframe.addEventListener('load', function() {
      const previewDocument = iframe.contentDocument;

      const forms = previewDocument.querySelectorAll('form');
      forms.forEach(form => {
        form.addEventListener('submit', function(e) {
          e.preventDefault();
        });
      });

      _this.loadDefaultOptions();
    });

    iframe.src = url;
  }

  buildProperties(property, position) {
    const propertyTab = document.createElement('li');
    propertyTab.className = 'tab';
    propertyTab.dataset.target = property.id;

    if (position === 0) {
      propertyTab.classList.add('active');
    }

    const propertyName = document.createElement('a');
    propertyName.textContent = I18n.t(property.name.toLowerCase());
    propertyName.tabIndex = position;

    propertyTab.appendChild(propertyName);
    this.tabsTarget.append(propertyTab);

    const propertyContainer = document.createElement('div');
    propertyContainer.id = `tab-pane-${property.id}`;
    propertyContainer.className = 'tab-pane';

    for(let i=0; i < property.options.length; i++) {
      const optionElement = this.buildOptions(property, property.options[i], propertyContainer);
      if (i === 0) {
        optionElement.classList.add('active');
        this.defaultOptions.push(optionElement);
      }
      propertyContainer.appendChild(optionElement);
    }

    return propertyContainer;
  }

  buildOptions(property, option, container) {
    const optionElement = document.createElement('div');

    optionElement.dataset.value = option.value;
    optionElement.dataset.name = property.property_type;
    optionElement.dataset.optionId = option.id;
    optionElement.dataset.propertyId = property.id;

    let label = I18n.t(`colors.${option.value}`) || option.label;

    if (label) {
      label = label.charAt(0).toUpperCase() + label.slice(1);
    }

    if (property.property_type == 'color_scheme') {
      optionElement.className = "color-scheme template-option card card-body";

      const image = document.createElement('img');
      if (option.preview_image_url) {
        image.src = option.preview_image_url;
      } else {
        image.src = "/scheme-color-1.png";
      }

      const schemeGroup = document.createElement('div');
      schemeGroup.className = 'color-scheme-group';

      option.values.forEach((optionValue, i) => {
        const color = optionValue.value;
        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.setAttributeNS("http://www.w3.org/2000/xmlns/", "xmlns:xlink", "http://www.w3.org/1999/xlink");
        svg.setAttribute('viewbox', '0 0 45 45');
        svg.setAttribute('fill', color);
        svg.setAttribute('xmnls', 'http://www.w3.org/2000/svg');
        svg.setAttribute('xmnls:xlink', 'http://www.w3.org/1999/xlink');
        svg.setAttribute('width', '45');
        svg.setAttribute('height', '45');
        svg.setAttribute('class', 'group-single');

        const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
        circle.setAttribute('cx', '22.5');
        circle.setAttribute('cy', '22.5');
        circle.setAttribute('r', '22.5');

        const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
        g.style.opacity = 1;

        const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        rect.setAttribute('x', '13');
        rect.setAttribute('y', '13');
        rect.setAttribute('width', '19');
        rect.setAttribute('height', '19');
        rect.setAttribute('fill', 'url(#pattern' + i + option.id + ')');

        const defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');

        const pattern = document.createElementNS('http://www.w3.org/2000/svg', 'pattern');
        pattern.setAttribute('id', 'pattern' + i + option.id);
        pattern.setAttribute('patternContentUnits', 'objectBoundingBox');

        const use = document.createElementNS('http://www.w3.org/2000/svg', 'use');
        use.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#image' + i + option.id);
        use.setAttribute('transform', 'scale(0.015625)');

        pattern.appendChild(use);

        const image = document.createElementNS('http://www.w3.org/2000/svg', 'image');
        image.setAttribute('width', '64');
        image.setAttribute('height', '64');
        image.setAttribute('id', 'image' + i + option.id);
        image.setAttributeNS('http://www.w3.org/2000/xlink', 'xlink:href', 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfjBAkRCQQfx5zRAAABRklEQVRo3u2XPU5DMRAGNwLEz8UoaAISEqKm5wqcgAJRUEBFAw00NEkVEBJdDsAtkFIEUQxNChJiPzvetZHYr971jC37aZ+Ix+Px/K/Q44QxX0wZsVcfv8E9P3NWG//IYnbb4mHYFg8fbfEwaYuHl7Z4OGyLv26Lv2Pd8Y53/F/Cs8mOAv4hgr9lLdR4zBiAd06DRYb487nCJ7bq4vd/FQ/yFVbGi/C8pCFToQAvwmRp05BtJXzXwwsIJJ9C0e5FRBgFmxMUivEi9CMLdCgo4EVEuFhNQQkvQo/LyEKB61h49UoVVPH5Cur4PAUTfLqCGT5NwRQ/U7iKAAbRcSP94RWcguXuixR0Z71sBf1RM0vBZtJNVrAbtJMUbOf8TgXr34wOBXt8VKEOPqhQDz9TmJ+ablQ+upkSB7wy5ZM3jqrDPR6PxzLfVLMtfFNFjwEAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTktMDQtMDlUMTU6MDk6MDQrMDI6MDDCcosEAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE5LTA0LTA5VDE1OjA5OjA0KzAyOjAwsy8zuAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=');

        defs.appendChild(pattern);
        defs.appendChild(image);

        svg.appendChild(defs);
        svg.appendChild(circle);
        svg.appendChild(rect);

        schemeGroup.appendChild(svg);
      });

      const title = document.createElement('p');
      title.className = 'color-scheme-title';
      title.textContent = label;

      optionElement.appendChild(schemeGroup);
      optionElement.appendChild(title);
    } else if (property.property_type == 'font') {
      optionElement.className = 'font-heading template-option card card-body ' + option.value.toLowerCase().replace(' ', '');

      const title = document.createElement('p');
      title.className = 'font-heading-title';
      title.textContent = option.value;

      const description = document.createElement('p');
      description.className = 'font-heading-desc';
      description.textContent = I18n.t('font_example_text');
      optionElement.appendChild(title);
      optionElement.appendChild(description);
    }

    return optionElement;
  }

  refreshTemplateEvents() {
    const templateOptions = this.element.querySelectorAll('.template-option');
    const _this = this;

    templateOptions.forEach(option => {
      option.addEventListener('click', (e) => {
        templateOptions.forEach(opt => opt.classList.remove('active'));
        option.classList.add('active');
        _this.setPropertyOption(option);
      });
    });

    const navTabs = this.element.querySelectorAll('.nav-tabs li');
    navTabs.forEach(tab => {
      tab.addEventListener('click', function() {
        const tabId = this.dataset.target;
        _this.setActiveTab(tabId);
      });
    });
  }

  setPropertyOption(option) {
    const name = option.dataset.name;
    const value = option.dataset.value;
    let property, opt, options;
    this.chosenOptions[name] = value;

    for (let i=0; i < this.properties.length; i++) {
      if (Number(this.properties[i].id) === Number(option.dataset.propertyId)) {
        property = this.properties[i];
      }
    }

    if (property) {
      options = property.options;
      for (let i=0; i < options.length; i++) {
        if (Number(options[i].id) === Number(option.dataset.optionId)) {
          opt = options[i];
        }
      }

      this.setOptionsInIframe(this.template, property, opt.values);
      this.saveChosenOptions();
    }
  }

  saveChosenOptions () {
    this.templateOptionsInputTarget.value = JSON.stringify(this.chosenOptions);
  }

  loadDefaultOptions() {
    const iframe = document.querySelector('iframe');
    const iframeWindow = iframe.contentWindow;

    if (typeof(iframeWindow.loadStyle) == 'function') {
      for (let i = 0; i < this.defaultOptions.length; i++) {
        this.setPropertyOption(this.defaultOptions[i]);
      }
      return;
    }
  }

  setOptionsInIframe(template, property, values) {
    const iframe = this.element.querySelector('iframe');
    if (iframe) iframe.contentWindow.loadStyle(template, property, values);
  };

  setActiveTab(tabId) {
    const navTabs = this.element.querySelectorAll('.nav-tabs li');
    const tabPanes = this.element.querySelectorAll('.tab-pane');
    navTabs.forEach(tab => tab.classList.remove('active'));
    tabPanes.forEach(pane => pane.classList.remove('active'));
    const selectedTab = this.element.querySelector(`.tab[data-target='${tabId}']`);
    const selectedPane = this.element.querySelector(`#tab-pane-${tabId}`);
    selectedTab.classList.add('active');
    selectedPane.classList.add('active');
  }
}
