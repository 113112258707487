import { Controller } from '@hotwired/stimulus';
import { loadStripe } from '@stripe/stripe-js';
import Swal from 'sweetalert2';
import I18n from '../app/i18n';
import { get, post } from '@rails/request.js';

export default class extends Controller {
  static values = {
    stripeKey: String,
  }

  static targets = ['checkoutFormContainer', 'submitButton'];

  async connect() {
    this.stripe = await loadStripe(this.stripeKeyValue);
    const paymentEl = this.element.querySelector('#payment-element');
    
    // load payment form on new subscription page
    if (paymentEl) {
      const options = {
        mode: 'subscription',
        amount: 0,
        currency: 'eur',
        setupFutureUsage: 'off_session',
        paymentMethodCreation: 'manual',
      };
  
      this.elements = this.stripe.elements(options);
  
      const paymentElement = this.elements.create('payment', {
        fields: {
          billingDetails: 'never',
        },
      });
      paymentElement.mount('#payment-element'); 
    }
  }

  handleError(error) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: error.message,
    });
    this.enableSubmitInput();
  };

  enableSubmitInput() {
    this.submitButtonTarget.disabled = false;
    this.submitButtonTarget.innerText = I18n.t('complete_order');
  };

  async getSubscriptionDetails() {  
    const productId = this
      .element
      .querySelector('input[name="billing_plan_product_id"]:checked')
      .value;
    const coupon =  this.element.querySelector('#coupon').value;
    const country = this.element.querySelector('#country_of_residence').value;
    const orderAsBusinessCheckBox = this.element.querySelector('#is_business');
    const isBusiness = orderAsBusinessCheckBox?.checked;
  
    let url = `/subscription/billing_plan_details?billing_plan_product_id=${productId}&coupon=${coupon}&country=${country}`;
  
    if (isBusiness) {
      url = url.concat('&is_business=true');
    }
  
    const planDetailsEl = this.element.querySelector('#planDetails');
    const spinner = this.element.querySelector('#spinner');
  
    spinner.classList.remove('d-none');
    planDetailsEl.style.opacity = '0.2';

    const response  = await get(url, { responseKind: 'turbo-stream' });
  
    spinner.classList.add('d-none');
    planDetailsEl.style.opacity = '1';

    if (response.ok) {
      return true;
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: I18n.t('billing.unable_to_get_plan_details'),
      });
      return false;
    }
  };

  selectPlan(e) {
    this.getSubscriptionDetails()
      .then((success) => {
        const notCheckedBox = this.element.querySelector('input[name="billing_plan_product_id"]:not(:checked)');
        if (!success) notCheckedBox.checked = true;
      });
  }

  applyCoupon(e) {
    e.preventDefault();
    e.target.disabled = true;
    this.getSubscriptionDetails()
      .then(() => {
        e.target.disabled = false;
      });
  }

  toggleIsBusinessSwitch(e) {
    e.preventDefault();

    const businessDetailsBox = this.element.querySelector('#business-details');
    const companyNameInput = this.element.querySelector('#company_name');
    const cityInput = this.element.querySelector('#city');
    const postCodeInput = this.element.querySelector('#post_code');
    const streetInput = this.element.querySelector('#address_1');
    const houseInput = this.element.querySelector('#address_2');

    if (e.target.checked) {
      businessDetailsBox.style.display = 'block';
      companyNameInput.required = 'required';
      cityInput.required = 'required';
      postCodeInput.required = 'required';
      streetInput.required = 'required';
      houseInput.required = 'required';
    } else {
      businessDetailsBox.style.display = 'none';
      companyNameInput.required = '';
      cityInput.required = '';
      postCodeInput.required = '';
      streetInput.required = '';
      houseInput.required = '';
    }

    this.getSubscriptionDetails();
  }

  async createSubscription(e) {
    e.preventDefault();
    const elements = this.elements;

    if (this.submitButtonTarget.disabled) return;

    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.innerHTML = this.submitButtonTarget.dataset.loadingText;

    const email = this.element.querySelector('#email');
    const name = this.element.querySelector('#name');
    const address1 = this.element.querySelector('#address_1');
    const address2 = this.element.querySelector('#address_2');
    const city = this.element.querySelector('#city');
    const zip = this.element.querySelector('#post_code');
    const country = this.element.querySelector('#country_of_residence');
    const taxId = this.element.querySelector('#tax_id');
    const companyName = this.element.querySelector('#company_name');
    const billingDetails = {
      name: name.value,
      email: email.value,
      phone: '',
      address: {
        line1: address1.value,
        line2: address2.value,
        city: city.value,
        postal_code: zip.value,
        country: country.value,
        state: '',
      },
    };

    const submitResult = await elements.submit();
    if (submitResult.error) {
      this.handleError(submitResult.error);
      return;
    }

    const { paymentMethod, error: paymentMethodError } = await this.stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: billingDetails,
      },
    });

    if (paymentMethodError) {
      this.handleError(paymentMethodError);
      return;
    }

    const billingPlanProductId = this
      .element
      .querySelector('[name="billing_plan_product_id"]')
      .value;
    const coupon = this.element.querySelector('#coupon').value;

    const res = await post(
      '/subscription/create',
      { 
        responseKind: 'json',
        body: JSON.stringify({
          billing_plan_product_id: billingPlanProductId,
          coupon: coupon,
          name: name.value,
          payment_method_id: paymentMethod.id,
          user: {
            country_of_residence: billingDetails.address.country,
            city: billingDetails.address.city,
            post_code: billingDetails.address.postal_code,
            address_1: billingDetails.address.line1,
            address_2: billingDetails.address.line2,
            tax_id: taxId.value,
            company_name: companyName.value,
          },
        }), 
      }
    );

    if (!res.ok) {
      const { error: subscriptionError } = await res.json;
      this.handleError(subscriptionError);
      return;
    }

    const { type, clientSecret, status, error: subscriptionError } = await res.json;

    if (subscriptionError) {
      this.handleError(subscriptionError);
      return;
    }

    const confirmIntent = type === 'setup' ? this.stripe.confirmSetup : this.stripe.confirmPayment;
    const { protocol, host } = window.location;

    // Confirm the Intent using the details collected by the Payment Element
    const { error } = await confirmIntent({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${protocol}//${host}/${I18n.locale()}/subscription/success`,
        payment_method_data: {
          billing_details: billingDetails,
        },
      },
    });

    // redirect to success page even if payment setup failed
    // if the subscription is trialing or active
    if (status === 'trialing' || status === 'active') {
      window.location.href = `/${I18n.locale()}/subscription/success`;
    } else if (error) {
      window.location.href = `/${I18n.locale()}/subscription/payment_failed`;
    }
  }

  async updateSubscription(e) {
    e.preventDefault();

    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.innerHTML = this.submitButtonTarget.dataset.loadingText;

    const billingPlanProductId = this
      .element
      .querySelector('[name="billing_plan_product_id"]')
      .value;
    const paymentMethodId = this.element.querySelector('#payment_method_id').value;
    const couponInput = this.element.querySelector('#coupon');
    const coupon = couponInput ? couponInput.value : '';
    const { protocol, host } = window.location;

    const res = await post(
      '/subscription/update',
      { 
        responseKind: 'json',
        body: JSON.stringify({
          billing_plan_product_id: billingPlanProductId,
          coupon: coupon,
          payment_method_id: paymentMethodId,
        }),
      }
    );

    if (!res.ok) {
      const { error: subscriptionError } = await res.json;
      this.handleError(subscriptionError);
      return;
    }

    const { subscription, free_plan_assigned, error: subscriptionError } = await res.json;

    if (free_plan_assigned) {
      window.location.href = `/${I18n.locale()}/subscription/success`;
      return;
    }

    if (subscription.status === 'active') {
      window.location.href = `/${I18n.locale()}/subscription/success`;
    } else if (subscription.status === 'past_due') {
      const { client_secret } = subscription.latest_invoice.payment_intent;
      const { error } = await this.stripe.confirmPayment({
        clientSecret: client_secret,
        confirmParams: {
          return_url: `${protocol}//${host}/${I18n.locale()}/subscription/success`,
          payment_method: subscription.default_payment_method,
        },
      });

      if (error) window.location.href = `/${I18n.locale()}/subscription/payment_failed`;
    } else {
      this.handleError({ message: 'Unable to update subscription. Please try again later.' });
    }
  }
}