import Answer from './answer';
import helpers from '../../../utils/helpers';
import Mustache from 'mustache';

const InputAnswer = function (quiz, answer) {
  Answer.call(this, quiz, answer);

  const mainForm = quiz.mainForm;
  const container = document.querySelector('.question-inputs .text-input-answer');
  const answerTemplate = document.getElementById('input-answer-template').innerHTML;

  answer.result = answer.result || {};

  this.nodeId = answer.nodeId;

  this.build = function() {
    const tmp = document.createElement('div');
    const templateOpts = {
      nodeId: this.nodeId,
    };
    const answerBlock = Mustache.render(answerTemplate, templateOpts);
    tmp.innerHTML = answerBlock;

    return tmp.firstChild;
  };

  this.append = function(el) {
    helpers.empty(container);
    container.appendChild(el);
    this.element = el;
  };

  this.add = function() {
    const el = this.build();
    this.append(el);
    this.loadValues();
    this.attachEvents();
    this.initResult();
  };

  this.loadValues = function() {
    const el = this.element;
    const question = quiz.getCurrentQuestion();
    const inputType = el.querySelector('.answer-input-type');
    inputType.value = question.input_type || '';
  };

  this.attachEvents = function() {
    const el = this.element;
    const inputType = el.querySelector('.answer-input-type');
    const question = quiz.getCurrentQuestion();

    inputType.addEventListener('change', (e) => {
      question.input_type = e.target.value;
      mainForm.updatePreview();
    });
  };
};

export default InputAnswer;
