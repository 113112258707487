import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';
import I18n from '../app/i18n';
import { German } from 'flatpickr/dist/l10n/de.js';
import { Slovak } from 'flatpickr/dist/l10n/sk.js';
import { Czech } from 'flatpickr/dist/l10n/cs.js';
import { english } from 'flatpickr/dist/l10n/default.js';

export default class extends Controller {
  connect() {
    flatpickr(this.element, {
      locale: this.locale(),
      minDate: 'today',
    });
  }

  locale() {
    const locale = I18n.locale();

    if (locale === 'de') {
      return German;
    } else if (locale === 'sk') {
      return Slovak;
    } else if (locale === 'cs') {
      return Czech;
    } else {
      return english;
    }
  }
}