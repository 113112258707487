import { Controller } from '@hotwired/stimulus';
import jsVectorMap from 'jsvectormap';
import 'jsvectormap/dist/maps/world';
import theme from '../app/theme';

export default class extends Controller {
  connect() {
    let countries = this.element.dataset.countries;
    countries = countries.replace(/&quot;/g, '"');
    let countryCodes = JSON.parse(countries);

    if (Object.keys(countryCodes).length === 0) {
      countryCodes = [];
    }

    this.map = new jsVectorMap({
      map: 'world',
      selector: '#locationmap',
      zoomOnScroll: false,
      zoomButtons: true,
      selectedRegions: countryCodes,
      regionsSelectable: false,
      regionStyle: {
        selected: { fill: theme.primary },
      },
    });
  }

  disconnect() {
    this.map.destroy();
  }
}