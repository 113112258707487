import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { content: String, copiedText: String };

  async copy(e) {
    e.preventDefault();

    const originalText = e.target.textContent;

    try {
      await navigator.clipboard.writeText(this.contentValue);
      e.target.textContent = this.copiedTextValue;
    } catch (err) {
      alert('unable to copy');
    }

    setTimeout(() => e.target.textContent = originalText, 1000);
  }

}