/* eslint-disable max-len */
const RulesForm = function(data, mainForm) {
  this.id = 'tool-rules-form';

  this.setValues = function(input) {
    switch (input.name) {
      case 'page_display':
        this.setPageDisplay(input);
        break;
      case 'blog_display':
        this.setBlogDisplay(input);
        break;
      case 'pages[]':
      case 'articles[]':
        this.updateChosenOptions(input);
        break;
      case 'show_when':
      case 'delay_seconds':
        this.setShowWhen(input);
        break;
      case 'display_frequency':
      case 'show_after_days':
        this.setDisplayFrequency(input);
        break;
      default:
        break;
    }
  };

  this.changeAction = function (input) {
    this.setValues(input);
  };

  this.setDisplayFrequency = function(input) {
    mainForm.input.rules['display_frequency'] = mainForm.input.rules['display_frequency'] || {};
    mainForm.input.rules['display_frequency'].options = {};

    if (input.name === 'show_after_days') {
      if (mainForm.input.rules.display_frequency.value === 'after') {
        mainForm.input.rules['display_frequency']['options']['show_after_days'] = input.value || '';
      }
    } else if (input.value === 'after') {
      let afterValue = document.querySelector(`#${this.id} input[name="show_after_days"]`).value;

      mainForm.input.rules['display_frequency'].value = input.value;
      mainForm.input.rules['display_frequency']['options']['show_after_days'] = afterValue;
    } else {
      mainForm.input.rules['display_frequency'].value = input.value;
    }
  };

  this.setShowWhen = function(input) {
    mainForm.input.rules['show_when'] = mainForm.input.rules['show_when'] || {};
    mainForm.input.rules['show_when'].options = {};

    if (input.name === 'delay_seconds') {
      if (mainForm.input.rules.show_when.value === 'after') {
        mainForm.input.rules['show_when']['options']['delay_seconds'] = input.value || '';
      }
    } else if (input.value === 'after') {
      let afterValue = document.querySelector(`#${this.id} input[name="delay_seconds"]`).value;

      mainForm.input.rules['show_when'].value = input.value;
      mainForm.input.rules['show_when']['options']['delay_seconds'] = afterValue;

    } else {
      mainForm.input.rules['show_when'].value = input.value;
    }
  };

  this.setBlogDisplay = function(input) {
    this.setDisplayRulesFor('blog', 'articles[]', input);
  };

  this.setPageDisplay = function(input) {
    this.setDisplayRulesFor('page', 'pages[]', input);
  };

  this.updateChosenOptions = function(input) {
    let checkedInputs = document.querySelectorAll(`#${this.id} input[name="${input.name}"]:checked`);
    let checkedValues = [];

    checkedInputs.forEach((element) => {
      checkedValues.push(element.value);
    });

    switch (input.name) {
      case 'pages[]':
        mainForm.input.rules['page_display'] = mainForm.input.rules['page_display'] || {};
        mainForm.input.rules['page_display'].options = {};
        mainForm.input.rules['page_display'].options.values = checkedValues;
        break;
      case 'articles[]':
        mainForm.input.rules['blog_display'] = mainForm.input.rules['blog_display'] || {};
        mainForm.input.rules['blog_display'].options = {};
        mainForm.input.rules['blog_display'].options.values = checkedValues;
        break;
      default:
        console.log('Input name not found');
    }
  };

  this.setDisplayRulesFor = function(view, checkboxName, input) {
    let displaySettings = mainForm.input.rules[`${view}_display`];
    mainForm.input.rules[`${view}_display`] = mainForm.input.rules[`${view}_display`] || {};
    let checkedInputs = document.querySelectorAll(
      `#${this.id} input[name="${checkboxName}"]:checked`,
    );
    let checkedValues = [];

    switch (input.value) {
      case 'all':
        displaySettings.value = 'all';
        displaySettings.options = {};
        break;
      case 'specific':
        displaySettings.value = 'specific';

        checkedInputs.forEach((element) => {
          checkedValues.push(element.value);
        });

        displaySettings.options = {};
        displaySettings.options.values = checkedValues;
        break;
      case 'none':
        displaySettings.value = 'none';
        displaySettings.options = {};
        break;
    }
  };

  this.toggleList = function(listType, value) {
    let list = this.getList(listType);

    switch (value.toLowerCase()) {
      case 'all':
      case 'none':
        list.classList.add('d-none');
        break;
      case 'specific':
        list.classList.remove('d-none');
        break;
    }
  };

  this.getList = function(listType) {
    let list;

    switch (listType) {
      case 'pages':
        list = document.querySelector('.page-list');
        break;
      case 'articles':
        list = document.querySelector('.article-list');
        break;
      default:
        break;
    }

    return list;
  };

  this.updateFields = function() {
    document.querySelectorAll(`#${this.id} input, #${this.id} select`).forEach((input) => {
      if (input.tagName.toLowerCase() === 'select') {
        input.value = mainForm.input.rules[input.name].value;
      }

      if (input.type === 'number' || input.type === 'text') {
        if (input.name === 'show_after_days') {
          input.value = mainForm.input.rules['display_frequency'].options['show_after_days'];
        }

        if (input.name === 'delay_seconds') {
          input.value = mainForm.input.rules['show_when'].options['delay_seconds'];
        }
      }

      if (input.type === 'checkbox') {
        let rule = input.name === 'pages[]' ? 'page_display' : 'blog_display';
        let options = mainForm.input.rules[rule].options || {};
        let checkedValues = options.values || [];
        input.checked = checkedValues.indexOf(input.value) > -1;
      }

      if (input.type === 'radio') {
        if (mainForm.input.rules[input.name].value) {
          input.checked = mainForm.input.rules[input.name].value.indexOf(input.value) > -1;
        } else {
          input.checked = false;
        }
      }
    });
  };

  document.querySelectorAll(`#${this.id} input, #${this.id} select`).forEach((input) => {
    input.addEventListener('change', () => {
      this.changeAction(input);
    });
  });

  document.querySelectorAll(`#${this.id} select`).forEach((input) => {
    input.addEventListener('change', () => {
      let listType = input.name === 'blog_display' ? 'articles' : 'pages';
      this.toggleList(listType, input.value);
    });
  });

  this.init = function() {
    this.updateFields();
    const rules = mainForm.input.rules;

    if (rules.blog_display) {
      this.toggleList('articles', rules.blog_display.value);
    }

    if (rules.page_display) {
      this.toggleList('pages', rules.page_display.value);
    }
  };
};

export default RulesForm;
