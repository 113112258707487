import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';
import I18n from '../app/i18n';

export default class extends Controller {
  static targets = [ 'generateBtn', 'certificateStatus', 'errorMessage' ]
  static values = { url: String, successUrl: String }
  
  connect() {}

  async generate(e) {
    e.preventDefault();
    this.generateBtnTarget.disabled = true;
    this.certificateStatusTargets.forEach(element => {
      element.innerHTML = `
       <div class="spinner-border spinner-border-sm text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      `
    });
    this.errorMessageTarget.innerHTML = '';

    const response  = await post(this.urlValue, { responseKind: 'json' });

    this.generateBtnTarget.disabled = false;

    if (response.ok) {
      const {success, certificates} = await response.json;

      this.certificateStatusTargets.forEach(element => {
        const certId = element.dataset.certificateId;
        const cert = certificates.find(c => c.id === certId);
        
        if (cert) {
          if (cert.status === 'published') {
            element.innerHTML = `
              <i class="fas fa-check-circle text-success"></i>
            `
          } else {
            element.innerHTML = `
              <i class="fas fa-times text-danger"></i>
            `
          }
        } else {
          element.innerHTML = '';
        }
      });

      if (success) {
        Turbo.visit(this.successUrlValue);
      } else {
        this.appendErrorMessage();
      }  
    } else {
      this.appendErrorMessage();
      this.certificateStatusTargets.forEach(element => element.innerHTML = '<i class="fas fa-times text-danger"></i>');
    }
  }

  appendErrorMessage() {
    this.errorMessageTarget.innerHTML = `
      <div class="alert alert-warning" role="alert">
        ${I18n.t('custom_domain_ssl_controller.dns_warning')}
      </div>
    `
  }
}
