import { Controller } from '@hotwired/stimulus';
import LeadToolWizard from '../app/lead_tools/wizard';

export default class extends Controller {

  connect() {
    const toolId = this.element.dataset.leadToolId;
    const siteId = this.element.dataset.siteId;
    const action = this.element.dataset.action;
    const siteToolId = this.element.dataset.siteLeadToolId;
    const optinProcesses = JSON.parse(this.element.dataset.optinProcesses);
    const options = {
      optinProcesses: optinProcesses,
    };

    let url;
    if (action === 'new') {
      url = `/lead_tools/${toolId}.json`;
    } else {
      url = `/sites/${siteId}/lead_tools/${siteToolId}.json`;
    }

    const toolUI = new LeadToolWizard(url, toolId, siteToolId, options);
    toolUI.start();
  }

}