import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'button', 'input' ];
  static values = { copiedText: String };

  async copy(event) {
    event.preventDefault();
    const originalHtml = this.buttonTarget.innerHTML;

    try {
      await navigator.clipboard.writeText(this.inputTarget.value);
      this.buttonTarget.innerHTML = this.copiedTextValue;
      this.buttonTarget.classList.add('btn-outline-success');
    } catch (err) {
      alert('Unable to copy');
    }

    this.buttonTarget.blur();

    setTimeout(() => {
      this.buttonTarget.innerHTML = originalHtml;
      this.buttonTarget.classList.remove('btn-outline-success');
    }, 1000);
  }

}