import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'passwordInput',
  ]

  connect() {}

  togglePasswordVisibility(e) {
    const input = this.passwordInputTarget;
    if (input.type === 'password') {
      input.type = 'text';
      e.target.classList.remove('bi-eye-slash');
      e.target.classList.add('bi-eye');
    } else {
      input.type = 'password';
      e.target.classList.remove('bi-eye');
      e.target.classList.add('bi-eye-slash');
    }
  }
}