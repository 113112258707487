/* eslint-disable max-len */

import helpers from './utils/helpers';
import textMerger from './utils/text_merger';

export default {
  i18n: {
    de: {
      ckeditor: {
        placeholder: 'Schreibe deinen Text hier.',
      },
      traffic: {
        direkter_traffic: 'Direkter Traffic',
        referrer_seiten: 'Referrer Seiten',
        mail_referrer: 'Mail Referrer',
        suchmaschinen: 'Suchmaschinen',
        soziale_netzwerke: 'Soziale Netzwerke',
        werbeanzeigen: 'Werbeanzeigen',
      },
      intercom: {
        pre_message: 'Liebes Support Team, ich benötige zusätzliche Informationen zu:',
      },
      time: {
        days: 'Tage',
        hours: 'Stunde',
        minutes: 'Minuten',
      },
      strategy: {
        time_remaining: 'Dir verbleiben noch: %{days} Tage %{hours} Stunden %{minutes} Minuten %{seconds} Sekunden',
      },
      facebook: {
        shareSite: 'Ich habe gerade meine erste Webseite mit masterpages.com erstellt. Bitte klicke kurz auf den Link, sieh sie dir an und gib mir Feedback dazu',
      },
      page_settings: {
        unable_to_deactivate: 'Du kannst die Startseite nicht deaktivieren. Gehe zu den Einstellungen einer anderen Seite und wähle diese als Startseite aus.',
      },
      blog_settings: {
        delete_menu_item_confirmation: 'Dieser Artikel wird gelöscht. Sind Sie sicher?',
      },
      publisher: {
        enabled: 'aktiviert',
        disabled: 'deaktiviert',
        page_activated: ' Seite wird angezeigt ',
        page_deactivated: ' Seite deaktiviert ',
      },
      leads: {
        select_leads_to_destroy: 'Wählen Sie die Leads aus, die Sie vernichten möchten',
        destroy_confirm: 'Sind Sie sicher, dass Sie %{count} Leads löschen wollen?',
      },
      lead_tools: {
        third_party_form: {
          matched_inputs_description: 'Ordne hier die Felder von deinem Formular mit Masterpages zu:',
        },
        input_names: {
          email: 'E-mail',
          first_name: 'Vorname',
          last_name: 'Nachname',
          phone: 'Telefonnummer',
          city: 'Stadt',
          country: 'Land',
          street: 'Straße',
          street_number: 'Hausnummer',
          post_code: 'Postleitzahl',
          voucher_code: 'Coupon Code',
          custom: 'Custom',
        },
        names: {
          optin_popup: 'Intelligentes Popup',
          quiz: 'Quiz',
          top_bar: 'Header Leiste',
          wheel_popup: 'Glücksrad Popup',
          blog_article: 'Blog-Artikel Banner',
          slide_down: 'Herunterrutschen',
          sweepstake: 'Gewinnspiel',
          full_page_description: 'Full page Beschreibung',
          optin_popup_description: 'Intelligentes Popup Beschreibung',
          top_bar_description: 'Header Leiste Beschreibung',
          wheel_popup_description: 'Glücksrad Popup  Beschreibung',
          blog_article_description: 'Blog-Artikel Banner Beschreibung',
          slide_down_description: 'Herunterrutschen Beschreibung',
          sweepstake_description: 'Gewinnspiel Beschreibung',
        },
        common_labels: {
          headline: 'Überschrift',
          subheadline: 'Text',
          button_text: 'Button Text',
          email_text: 'E-Mail Platzhalter',
          main_image: 'Hauptbild',
        },
        sweepstake: {
          headline: {
            value: 'JETZT GEWINNEN',
          },
          subheadline: {
            value: 'Trag dich jetzt ein und nimm am Gewinnspiel teil:',
          },
          button_text: {
            value: 'TEILNEHMEN',
          },
        },
        email_text: {
          value: 'E-Mail Adresse',
        },
        slide_down: {
          headline: {
            value: 'GRATIS GUTSCHEIN: 10% RABATT AUF ALLES',
          },
          subheadline: {
            value: 'Trag dich ein und sichere dir den Gutschein',
          },
          button_text: {
            value: 'GUTSCHEIN SICHERN',
          },
        },
        blog_article: {
          headline: {
            value: 'Kostenloser Videokurs',
          },
          subheadline: {
            value: 'Trag dich jetzt ein und erhalte 5 Strategien bequem per E-Mail.',
          },
          button_text: {
            value: 'ANFORDERN',
          },
          email_text: {
            value: 'E-Mail Adresse',
          },
        },
        optin_popup: {
          headline: {
            value: 'AKTUELLER RABATTCODE: 10% AUF ALLES',
          },
          subheadline: {
            value: 'Trag dich ein und erhalten den aktuellen Rabattcode auf alle Produkte.',
          },
          button_text: {
            value: 'RABATT ERHALTEN',
          },
          email_text: {
            value: 'E-Mail Adresse',
          },
        },
        top_bar: {
          headline: {
            value: 'GUTSCHEIN DER WOCHE: ZAHL 1 - BEKOMM 2',
          },
          subheadline: {
            value: 'Trag deine E-Mail ein um den Sondergutschein zu bekommen.',
          },
          button_text: {
            value: 'TEILNEHMEN',
          },
          email_text: {
            value: 'E-Mail Adresse',
          },
        },
        wheel_of_fortune: {
          form_button_text: {
            value: 'Einreichen',
          },
          form_title: {
            value: 'Reichen Sie das Formular ein und fordern Sie Ihren Preis an',
          },
        },
        wheel_popup: {
          headline: {
            value: 'DREH AM GLÜCKSRAD UND GEWINNE EINEN PREIS',
          },
          subheadline: {
            value: 'Dreh am Glücksrad, trage dich ein und du bekommst den Preis per E-Mail von mir zugesendet!.',
          },
          button_text: {
            value: 'DREHEN',
          },
          email_text: {
            value: 'E-Mail Adresse',
          },
          type: 'Art',
          value: 'Wert',
          message: 'Text im Glücksrad',
          chance: 'Gewinnwahrscheinlichkeit (%)',
          remove: 'Entfernen',
          add_slice: 'Zusätzliches Feld hinzufügen',
          link: 'Link',
          discount: 'DER RABATT',
          discount_value: 'Ein Rabattcode',
          free_ebook: 'Kostenloses Ebook',
          discount_code: 'Ein Rabattcode',
        },
        full_page: {
          headline: {
            value: 'GRATIS BUCH SICHERN',
          },
          subheadline: {
            value: 'Trag dich kostenfrei ein und du bekommst die Leseprobe unseres neuen Buchs',
          },
          button_text: {
            value: 'BUCH ANFORDERN',
          },
          email_text: {
            value: 'E-Mail Adresse',
          },
        },
        quiz: {
          input: {
            continue: 'FORTFAHREN',
          },
          question_path: {
            create: 'Folgefrage erstellen',
            follow: 'Gehe zur Folgefrage',
          },
          new_question: {
            title: 'Hier steht die Frage.',
            description: 'Hier steht eine zusätzliche Beschreibung zur Frage',
            answer: {
              text: 'Antwort 1',
              value: 'answer1',
            },
          },
          search_icon: 'Suche',
          select_icon: 'Icon auswählen',
          no_results_found: 'Keine Ergebnisse gefunden...',
          delete: 'Löschen',
          headline: {
            value: 'Vereinbare jetzt dein Strategie-Gespräch:',
          },
          subheadline: {
            value: 'Im unverbindlichen gespräch klärt ein experte aus unserem team mit dir, wie du selbst marketing quizzes am einfachsten einsetzen kannst.',
          },
          confirm_text: {
            label: 'Ergebnis Button-Text',
            value: 'NÄCHSTER SCHRITT',
          },
          back_button_text: {
            label: 'Frage-Zurück Button-Text',
            value: 'FRAGE ZURÜCK',
          },
          result: {
            default_text: 'Das ist ein neues Ergebnis. Gib einen Ergebnistext ein.',
            optin: {
              thank_you: 'Vielen Dank für deine Teilnahme!',
              url: 'https://masterpages.com',
            },
            button: {
              text: 'Nächster Schritt',
              url: 'https://masterpages.com',
            },
            default_sample_result: 'Sichere dir dein persönliches Analyse-Gespräch mit einem unserer Marketing Experten. Wir analysieren deine Webseite und schlagen dir Möglichkeiten vor, damit mehr Geld zu verdienen.',
            result_3: 'Starte mit deiner eigenen marketing-optimierten Webseite in wenigen Minuten. Alles fertig!',
          },
          answer: {
            header: 'Antwort %{index}',
            text: 'Das ist eine neue Antwort. Gib einen Antworttext ein.',
            answ_1: 'Produkte',
            answ_1_1_1: '1 Produkt',
            answ_1_1_2: 'Mehrere Produkte',
            answ_1_2_1: 'Zielgruppe finden',
            answ_1_2_2: 'Die Technik einrichten',
            answ_1_2_3: 'Kampagne erstellen',
            answ_1_2_4: 'Sonstige',
            answ_2: 'Dienstleistungen',
            answ_2_1_1: 'B2B',
            answ_2_1_2: 'B2C',
            answ_2_2_1: '<500€',
            answ_2_2_2: '500€ - 2.000€',
            answ_2_2_3: '2.000€ - 8.000€',
            answ_2_2_4: '>8.000€',
          },
          question: {
            text_1: 'Verkaufst du Produkte oder Dienstleistungen?',
            desc_1: 'Wähle eine der Antwortmöglichkeiten:',
            text_1_1: 'Verkaufst du ein Produkt oder mehrere?',
            desc_1_1: 'Hier steht eine zusätzliche Beschreibung zur Frage',
            text_1_2: 'Was ist aktuell deine größte Herausforderung im Online Marketing?',
            desc_1_2: 'Entscheide dich für eine der Antworten.',
            text_2_1: 'Verkaufst du deine Dienstleistungen an Unternehmer (B2B) oder Privatpersonen (B2C)?',
            desc_2_1: 'Wähle einen Bereich aus, mit dem du Starten möchtest.',
            text_2_2: 'In welcher Preisklasse sind deine Dienstleistungen?',
            desc_2_2: 'Wähle bitte aus, wie hoch das Investment für deine Hauptdienstleistung ist, die du verkaufen möchtest.',
          },
        },
      },
      plans: {
        period: {
          year: '/ Monat',
          month: '/ Monat',
        },
        free: {
          amount: {
            month: '0',
            year: '0',
          },
        },
        kickstart: {
          amount: {
            month: '9',
            year: '8',
          },
        },
        premium: {
          amount: {
            month: '29',
            year: '24',
          },
        },
        enterprise: {
          amount: {
            month: '99',
            year: '83',
          },
        },
      },
      errorFormNotPresent: 'Form nicht vorhanden',
      errorParsingForm: 'Fehler beim Parsen des Formulars',
      errorFormSubmit: 'Das Formular muss vor dem Absenden analysiert und vorgelegt werden',
      errorsomethingWrong: 'Entschuldigung, etwas ist schief gelaufen',
      formIntegrated: 'Formular integriert!',
      formRemoved: 'Formular entfernt!',
      save: 'Speichern',
      winningSlots: 'Sie können nicht mehr als 12 Gewinnslots hinzufügen',
      maintainPrize: 'Sie müssen mindestens einen Preis behalten',
      characters_remaining: 'Zeichen übrig',
      complete_order: 'Bestellung anbschließen',
      schriftart: 'Schriftart',
      farbschemata: 'Farbschemata',
      font_example_text: 'So werden Text-Absätze angezeigt',
      billing: {
        unable_to_get_plan_details: 'Plan Details können nicht abgerufen werden',
        unable_to_confirm_card: 'Kann nicht bestätigt werden. Bitte fügen Sie eine weitere Karte hinzu.',
        invalid_card: 'Ungültige Karte. Versuchen Sie, eine andere Karte zu verwenden.',
      },
      graph: {
        views: 'Ansichten',
      },
      image_picker: {
        cancel: 'Abbrechen',
        insert_picture: 'Bild einsetzen',
        default_text: 'Ziehe die Dateien hier hin, um sie hochzuladen',
        remove_file: 'Datei entfernen',
        upload_canceled: 'Der Upload wurde abgebrochen.',
        cancel_upload: 'Upload abbrechen',
        file_too_big: 'Datei ist zu groß ({{filesize}}MiB). Maximale Dateigröße: {{maxFilesize}}MiB.',
        cancel_upload_confirmation: 'Sind Sie sicher, dass Sie diesen Upload abbrechen möchten?',
      },
      colors: {
        green: 'Grün',
        red: 'Rot',
        purple: 'Purpur',
        'light blue': 'Hellblau',
        ocre: 'Ocker',
        'Dark blue': 'Dunkelblau',
        'Redish purple': 'Rötliches Purpur',
        'Light green': 'Hellgrün',
        'Dark red': 'Dunkelrot',
        'Pink': 'Pink',
        blau: 'Blau',
        blue: 'Blau',
        'Bluish green': 'Bläuliches Grün',
        yellow: 'Gelb',
        orange: 'Orange',
      },
      custom_domain_ssl_controller: {
        error_message: 'ssl-Zertifikat kann nicht generiert werden. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.',
        dns_warning: 'DNS ist noch nicht veröffentlicht - bitte versuchen Sie es in 15 Minuten erneut.',
      },
      domain_dns_verification_controller: {
        unable_to_verify: 'Die Domäne kann nicht überprüft werden. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.',
      },
    },
    en: {
      ckeditor: {
        placeholder: 'Type the content here.',
      },
      traffic: {
        direkter_traffic: 'Direct traffic',
        referrer_seiten: 'Referrer pages',
        mail_referrer: 'Mail referrer',
        suchmaschinen: 'Search engines',
        soziale_netzwerke: 'Social networks',
        werbeanzeigen: 'Advertisements',
      },
      intercom: {
        pre_message: 'Liebes Support Team, ich benötige zusätzliche Informationen zu:',
      },
      strategy: {
        time_remaining: 'Time remaining: %{days} days %{hours} hours %{minutes} minutes %{seconds} seconds',
      },
      facebook: {
        shareSite: 'I have just finished creating my first website in masterpages.com. Please have a look and give me feedback: <link> to website. What do you think about it?',
      },
      page_settings: {
        unable_to_deactivate: 'You cannot deactivate the start page. Go to the settings of another page and select it as a start page.',
      },
      blog_settings: {
        delete_menu_item_confirmation: 'This item will be deleted. Are you sure?',
      },
      publisher: {
        enabled: 'enabled',
        disabled: 'disabled',
        page_activated: ' Page activated ',
        page_deactivated: ' Page deactivated ',
      },
      characters_remaining: 'characters left',
      complete_order: 'Complete Order',
      schriftart: 'Primary Font',
      farbschemata: 'Primary Color',
      font_example_text: 'This is how text paragraphs are displayed',
      liteAmountMonth: '47',
      businessAmountMonth: '97',
      premiumAmountMonth: '297',
      liteAmountYear: '41',
      businessAmountYear: '83',
      premiumAmountYear: '249',
      billing: {
        unable_to_get_plan_details: 'Unable to get plan details',
        unable_to_confirm_card: 'Unable to confirm. Please add another card.',
        invalid_card: 'Invalid card. Try to use another card.',
      },
      plans: {
        period: {
          year: '/ Month',
          month: '/ Month',
        },
        free: {
          amount: {
            month: '0',
            year: '0',
          },
        },
        kickstart: {
          amount: {
            month: '9',
            year: '8',
          },
        },
        premium: {
          amount: {
            month: '29',
            year: '24',
          },
        },
        enterprise: {
          amount: {
            month: '99',
            year: '83',
          },
        },
      },
      errorFormNotPresent: 'Form not present',
      errorParsingForm: 'Error parsing form',
      errorFormSubmit: 'Form must be parsed and present before submit',
      errorsomethingWrong: 'Sorry, something went wrong',
      formIntegrated: 'Form Integrated!',
      formRemoved: 'Form Removed!',
      save: 'Save',
      winningSlots: 'You can\'t add more than 12 winning slots',
      maintainPrize: 'You have to maintain at least one prize',
      graph: {
        views: 'Views',
      },
      image_picker: {
        cancel: 'Cancel',
        insert_picture: 'Insert Picture',
        default_text: 'Drag files here to upload them',
        remove_file: 'Remove file',
        upload_canceled: 'Upload is canceled.',
        cancel_upload: 'Cancel upload',
        file_too_big: 'File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.',
        cancel_upload_confirmation: 'Are you sure you want to cancel this upload?',
      },
      colors: {
        green: 'Green',
        red: 'Red',
        purple: 'Purple',
        'light blue': 'Light blue',
        ocre: 'Ocre',
        'Dark blue': 'Dark blue',
        'Redish purple': 'Redish purple',
        'Light green': 'Light green',
        'Dark red': 'Dark red',
        'Pink': 'Pink',
        blau: 'Blue',
        blue: 'Blue',
        'Bluish green': 'Bluish green',
        yellow: 'Yellow',
        orange: 'Orange',
      },
      custom_domain_ssl_controller: {
        error_message: 'Unable to generate ssl certificate. Please try again or contact support.',
        dns_warning: 'DNS is not yet published - please try again in 15 minutes.',
      },
      domain_dns_verification_controller: {
        unable_to_verify: 'Unable to verify this domain. Please try again or contact support.',
      },
      leads: {
        select_leads_to_destroy: "Select leads you'd like to destroy",
        destroy_confirm: 'Are you sure you want to delete %{count} leads?',
      },
      lead_tools: {
        third_party_form: {
          matched_inputs_description: 'Here you can assign the entries of your processed form:',
        },
        input_names: {
          email: 'E-mail',
          first_name: 'First Name',
          last_name: 'Last Name',
          phone: 'Phone',
          city: 'City',
          country: 'Country',
          street: 'Street',
          street_number: 'Street Number',
          post_code: 'Post Code',
          voucher_code: 'Voucher Code',
          custom: 'Custom',
        },
        names: {
          optin_popup: 'Smart popup',
          quiz: 'Quiz',
          top_bar: 'Header bar',
          wheel_popup: 'Wheel of Fortune popup',
          blog_article: 'Blog Article Banner',
          slide_down: 'Slide down',
          sweepstake: 'Contest',
          full_page_description: 'Full page description',
          optin_popup_description: 'Intelligent popup description',
          top_bar_description: 'Header bar description',
          wheel_popup_description: 'Wheel of Fortune popup description',
          blog_article_description: 'Blog Article Banner Description',
          slide_down_description: 'Sliding down description',
          sweepstake_description: 'Sweepstakes Description',
        },
        common_labels: {
          headline: 'Headline',
          subheadline: 'Text',
          button_text: 'Button Text',
          email_text: 'Email placeholder',
          main_image: 'Main image',
        },
        sweepstake: {
          headline: {
            value: 'WIN NOW',
          },
          subheadline: {
            value: 'Register now and take part in the competition:',
          },
          button_text: {
            value: 'TAKE PART',
          },
        },
        email_text: {
          value: 'E-Mail Address',
        },
        slide_down: {
          headline: {
            value: 'FREE COUPON: 10% DISCOUNT ON EVERYTHING',
          },
          subheadline: {
            value: 'Sign up and get the voucher',
          },
          button_text: {
            value: 'COUPON SECURE',
          },
        },
        blog_article: {
          headline: {
            value: 'Free video course',
          },
          subheadline: {
            value: 'Sign up now and receive 5 strategies conveniently by email.',
          },
          button_text: {
            value: 'REQUEST',
          },
          email_text: {
            value: 'E-Mail Address',
          },
        },
        optin_popup: {
          headline: {
            value: 'CURRENT DISCOUNT CODE: 10% OFF EVERYTHING',
          },
          subheadline: {
            value: 'Sign up and receive the current discount code on all products.',
          },
          button_text: {
            value: 'GET A DISCOUNT',
          },
          email_text: {
            value: 'E-Mail Address',
          },
        },
        top_bar: {
          headline: {
            value: 'COUPON OF THE WEEK: PAY 1 - GET 2',
          },
          subheadline: {
            value: 'Enter your email to receive the special voucher.',
          },
          button_text: {
            value: 'TAKE PART',
          },
          email_text: {
            value: 'E-Mail Address',
          },
        },
        wheel_of_fortune: {
          form_title: {
            value: 'Submit the form to claim your prize',
          },
          form_button_text: {
            value: 'Submit',
          },
        },
        wheel_popup: {
          headline: {
            value: 'SPIN THE WHEEL OF FORTUNE AND WIN A PRIZE',
          },
          subheadline: {
            value: "Spin the wheel of fortune, sign up and I'll send you the prize by email!",
          },
          button_text: {
            value: 'ROTATE',
          },
          email_text: {
            value: 'E-Mail Address',
          },
          type: 'Type',
          value: 'Value',
          message: 'Text in the wheel',
          chance: 'Probability of winning (%)',
          remove: 'Remove',
          link: 'Link',
          discount: 'THE DISCOUNT',
          discount_value: 'Discount code',
          free_ebook: 'Free ebook',
          discount_code: 'Discount code',
        },
        full_page: {
          headline: {
            value: 'GET YOUR FREE BOOK',
          },
          subheadline: {
            value: "Sign up for free and you'll get a copy of our new book",
          },
          button_text: {
            value: 'REQUEST BOOK',
          },
          email_text: {
            value: 'E-Mail Address',
          },
        },
        quiz: {
          input: {
            continue: 'Continue',
          },
          question_path: {
            create: 'Create follow-up question',
            follow: 'Go to follow-up question',
          },
          new_question: {
            title: 'Here is the question',
            description: 'Here is an additional description to the question',
            answer: {
              text: 'Answer 1',
              value: 'answer1',
            },
          },
          search_icon: 'Search Icon',
          select_icon: 'Select Icon',
          no_results_found: 'No results found...',
          delete: 'Delete',
          headline: {
            value: 'Arrange your strategy talk now:',
          },
          subheadline: {
            value: 'In a non-binding discussion, an expert from our team will clarify with you how you can most easily use marketing quizzes yourself.',
          },
          confirm_text: {
            label: 'Result button text',
            value: 'NEXT STEP',
          },
          back_button_text: {
            label: 'Question back button text',
            value: 'QUESTION BACK',
          },
          result: {
            default_text: 'This is a new result. Enter a result text.',
            optin: {
              thank_you: 'Thank you for the participation!',
              url: 'https://masterpages.com',
            },
            button: {
              text: 'Next Step',
              url: 'https://masterpages.com',
            },
            default_sample_result: 'Secure your personal analysis discussion with one of our marketing experts. We analyze your website and suggest ways to earn more money with it.',
            result_3: 'Get started with your own marketing-optimized website in minutes. Everything ready!',
          },
          answer: {
            header: 'Answer %{index}',
            text: 'This is a new answer. Enter an answer text',
            answ_1: 'Products',
            answ_1_1_1: '1 Product',
            answ_1_1_2: 'Multiple products',
            answ_1_2_1: 'Find Target Audience',
            answ_1_2_2: 'Set up a technology',
            answ_1_2_3: 'Create a campaign',
            answ_1_2_4: 'Other',
            answ_2: 'Services',
            answ_2_1_1: 'B2B',
            answ_2_1_2: 'B2C',
            answ_2_2_1: '<500€',
            answ_2_2_2: '500€ - 2.000€',
            answ_2_2_3: '2.000€ - 8.000€',
            answ_2_2_4: '>8.000€',
          },
          question: {
            text_1: 'Do you sell products or services?',
            desc_1: 'Choose an answer:',
            text_1_1: 'Are you selling one product or several?',
            desc_1_1: 'Choose an answer:',
            text_1_2: 'What is currently your biggest challenge in online marketing?',
            desc_1_2: 'Choose an answer:',
            text_2_1: 'Do you sell your services to entrepreneurs (B2B) or private individuals (B2C)?',
            desc_2_1: 'Choose an answer:',
            text_2_2: 'In what price range are your services?',
            desc_2_2: 'Choose an answer:',
          },
        },
      },
    },
    sk: {
      ckeditor: {
        placeholder: 'Tu zadaj obsah.',
      },
      traffic: {
        direkter_traffic: 'Priama návštevnosť',
        referrer_seiten: 'Odkazujúce stránky',
        mail_referrer: 'Odosielateľ e-mailu',
        suchmaschinen: 'Vyhľadávače',
        soziale_netzwerke: 'Sociálne siete',
        werbeanzeigen: 'Reklamy',
      },
      billing: {
        unable_to_get_plan_details: 'Nie je možné získať podrobnosti o pláne',
        unable_to_confirm_card: 'Nemožno potvrdiť. Prosím, pridajte inú kartu.',
        invalid_card: 'Neplatná karta. Skúste použiť inú kartu.',
      },
      businessAmountMonth: '97',
      businessAmountYear: '83',
      characters_remaining: 'zostávajúce znaky',
      complete_order: 'Kompletná objednávka',
      errorFormNotPresent: 'Formulár nie je prítomný',
      errorFormSubmit: 'Formulár musí byť pred odoslaním analyzovaný a prezentovaný',
      errorParsingForm: 'Formulár na analýzu chýb',
      errorsomethingWrong: 'Ospravedlňujeme sa, niečo sa pokazilo',
      facebook: {
        shareSite: 'Práve som dokončil vytváranie svojej prvej webovej stránky v službe masterpages.com. Prosím, pozrite sa a dajte mi spätnú väzbu na webové stránky. Čo si o nej myslíte?',
      },
      farbschemata: 'Primárna farba',
      font_example_text: 'Takto sa zobrazujú textové odseky',
      formIntegrated: 'Integrovaný formulár!',
      formRemoved: 'Formulár odstránený!',
      graph: {
        views: 'Zobrazenia',
      },
      image_picker: {
        cancel: 'Zrušiť',
        default_text: 'Sem presuň súbor alebo klikni na tlačítko pre výber priamo z počítača',
        insert_picture: 'Vložiť obrázok',
        remove_file: 'Odstrániť súbor',
        upload_canceled: 'Odosielanie je zrušené.',
        cancel_upload: 'Zrušiť nahrávanie',
        file_too_big: 'Súbor je príliš veľký ({{filesize}}MiB). Maximálna veľkosť súboru: {{maxFilesize}}MiB.',
        cancel_upload_confirmation: 'Ste si istí, že chcete zrušiť toto nahrávanie?',
      },
      colors: {
        green: 'Zelená',
        red: 'Červená',
        purple: 'Fialová',
        'light blue': 'Svetlomodrá',
        ocre: 'Okrová',
        'Dark blue': 'Tmavomodrá',
        'Redish purple': 'Purpurová',
        'Light green': 'Svetlozelená',
        'Dark red': 'Tmavočervená',
        'Pink': 'Ružová',
        blau: 'Modrá',
        blue: 'Modrá',
        'Bluish green': 'Tyrkysová',
        yellow: 'Žltá',
        orange: 'Oranžová',
      },
      custom_domain_ssl_controller: {
        error_message: 'Nie je možné vygenerovať certifikát ssl. Skúste to prosím znova alebo kontaktujte podporu.',
        dns_warning: 'DNS ešte nie je zverejnené - skúste to znova o 15 minút.',
      },
      domain_dns_verification_controller: {
        unable_to_verify: 'Túto doménu sa nepodarilo overiť. Skúste to prosím znova alebo kontaktujte podporu.',
      },
      intercom: {
        pre_message: 'Liebes Support Team, ich benötige zusätzliche Informationen zu:',
      },
      lead_tools: {
        third_party_form: {
          matched_inputs_description: 'Tu môžete priradiť položky spracovaného formulára:',
        },
        input_names: {
          email: 'E-mail',
          first_name: 'Krstné meno',
          last_name: 'Priezvisko',
          phone: 'Telefón',
          city: 'Mesto',
          country: 'Krajina',
          street: 'Street',
          street_number: 'Číslo ulice',
          post_code: 'Poštový kód',
          voucher_code: 'Kód kupónu',
          custom: 'Vlastné',
        },
        blog_article: {
          button_text: {
            value: 'POŽIADAŤ',
          },
          email_text: {
            value: 'E-mailová adresa',
          },
          headline: {
            value: 'Bezplatný video kurz',
          },
          subheadline: {
            value: 'Zaregistrujte sa teraz a dostávajte 5 stratégií pohodlne e-mailom.',
          },
        },
        common_labels: {
          button_text: 'Text tlačidla',
          email_text: 'Zástupca e-mailu',
          headline: 'Titulok',
          main_image: 'Hlavná snímka',
          subheadline: 'Text',
        },
        email_text: {
          value: 'E-mailová adresa',
        },
        full_page: {
          button_text: {
            value: 'VYŽIADAŤ SI KNIHU',
          },
          email_text: {
            value: 'E-mailová adresa',
          },
          headline: {
            value: 'ZÍSKAJTE KNIHU ZADARMO',
          },
          subheadline: {
            value: 'Zaregistrujte sa zadarmo a dostanete kópiu našej novej knihy',
          },
        },
        names: {
          blog_article: 'Banner článku blogu',
          blog_article_description: 'Blog Článok Banner Popis',
          full_page_description: 'Popis celej stránky',
          optin_popup: 'Smart popup',
          optin_popup_description: 'Inteligentný popis vyskakovacieho okna',
          quiz: 'Kvíz',
          slide_down: 'Posuňte sa nadol',
          slide_down_description: 'Posuvný popis',
          sweepstake: 'Súťaž',
          sweepstake_description: 'Popis lotérie',
          top_bar: 'Horný panel',
          top_bar_description: 'Popis lišty záhlavia',
          wheel_popup: 'Vyskakovacie okno Kola šťastia',
          wheel_popup_description: 'Popis vyskakovacieho okna Kola šťastia',
        },
        optin_popup: {
          button_text: {
            value: 'ZÍSKAJTE ZĽAVU',
          },
          email_text: {
            value: 'E-mailová adresa',
          },
          headline: {
            value: 'AKTUÁLNY ZĽAVOVÝ KÓD: 10% ZO VŠETKÉHO',
          },
          subheadline: {
            value: 'Zaregistrujte sa a získajte aktuálny zľavový kód na všetky produkty.',
          },
        },
        quiz: {
          answer: {
            answ_1: 'Produkty',
            answ_1_1_1: '1 výrobok',
            answ_1_1_2: 'Viacero produktov',
            answ_1_2_1: 'Vyhľadanie cieľového publika',
            answ_1_2_2: 'Nastavenie technológie',
            answ_1_2_3: 'Vytvorenie kampane',
            answ_1_2_4: 'Iné',
            answ_2: 'Služby',
            answ_2_1_1: 'B2B',
            answ_2_1_2: 'B2C',
            answ_2_2_1: '&lt;500€',
            answ_2_2_2: '500€ - 2.000€',
            answ_2_2_3: '2.000€ - 8.000€',
            answ_2_2_4: '&gt;8.000€',
            header: 'Odpoveď %{index}',
            text: 'Toto je nová odpoveď. Zadaj text odpovede.',
          },
          back_button_text: {
            label: 'Text tlačidla Späť',
            value: 'OTÁZKA ZPÄT',
          },
          confirm_text: {
            label: 'Text tlačidla Výsledok',
            value: 'ĎALŠÍ KROK',
          },
          headline: {
            value: 'Dohodnite si rozhovor o stratégii už teraz:',
          },
          input: {
            continue: 'Pokračovať',
          },
          new_question: {
            answer: {
              text: 'Odpoveď 1',
              value: 'odpoveď1',
            },
            description: 'Tu je doplňujúci opis k otázke',
            title: 'Tu je otázka',
          },
          question: {
            desc_1: 'Vyberte odpoveď:',
            desc_1_1: 'Vyberte odpoveď:',
            desc_1_2: 'Vyberte odpoveď:',
            desc_2_1: 'Vyberte odpoveď:',
            desc_2_2: 'Vyberte odpoveď:',
            text_1: 'Predávate produkty alebo služby?',
            text_1_1: 'Predávate jeden produkt alebo viacero?',
            text_1_2: 'Čo je v súčasnosti vašou najväčšou výzvou v online marketingu?',
            text_2_1: 'Predávate svoje služby podnikateľom (B2B) alebo súkromným osobám (B2C)?',
            text_2_2: 'V akom cenovom rozpätí sú vaše služby?',
          },
          question_path: {
            create: 'Vytvorenie následnej otázky',
            follow: 'Prejsť na doplňujúcu otázku',
          },
          result: {
            button: {
              text: 'Ďalší krok',
              url: 'https://masterpages.com',
            },
            default_sample_result: 'Zabezpečte si osobnú analýzu s jedným z našich marketingových expertov. Analyzujeme vašu webovú stránku a navrhneme spôsoby, ako na nej zarobiť viac peňazí.',
            default_text: 'Toto je nový výsledok. Zadaj text výsledku.',
            optin: {
              thank_you: 'Ďakujeme za účasť!',
              url: 'https://masterpages.com',
            },
            result_3: 'Začnite s vlastnou marketingovo optimalizovanou webovou stránkou za pár minút. Všetko je pripravené!',
          },
          search_icon: 'Ikona vyhľadávania',
          select_icon: 'Vyberte ikonu',
          no_results_found: 'Nenašli sa žiadne výsledky...',
          delete: 'Vymazať',
          subheadline: {
            value: 'V nezáväznej diskusii vám odborník z nášho tímu vysvetlí, ako môžete marketingové kvízy čo najjednoduchšie používať sami.',
          },
        },
        slide_down: {
          button_text: {
            value: 'ULOŽIŤ KUPÓN',
          },
          headline: {
            value: 'BEZPLATNÝ KUPÓN: 10% ZĽAVA NA VŠETKO',
          },
          subheadline: {
            value: 'Zaregistrujte sa a získajte poukaz',
          },
        },
        sweepstake: {
          button_text: {
            value: 'ZÚČASTNIŤ SA',
          },
          headline: {
            value: 'VYHRAJTE TERAZ',
          },
          subheadline: {
            value: 'Zaregistrujte sa a zúčastnite sa súťaže:',
          },
        },
        top_bar: {
          button_text: {
            value: 'ZÚČASTNIŤ SA',
          },
          email_text: {
            value: 'E-mailová adresa',
          },
          headline: {
            value: 'KUPÓN TÝŽDŇA: ZAPLAŤTE ZA 1 - ZÍSKAJTE 2',
          },
          subheadline: {
            value: 'Zadajte svoj e-mail, aby ste dostali špeciálnu poukážku.',
          },
        },
        wheel_of_fortune: {
          form_button_text: {
            value: 'Odoslať',
          },
          form_title: {
            value: 'Odošlite formulár a požiadajte o výhru',
          },
        },
        wheel_popup: {
          button_text: {
            value: 'ROZTOČIŤ',
          },
          chance: 'Pravdepodobnosť výhry (%)',
          discount: 'ZĽAVA',
          discount_code: 'Text',
          discount_value: 'Zľavový kód',
          email_text: {
            value: 'E-mailová adresa',
          },
          headline: {
            value: 'ROZTOČTE KOLESO ŠŤASTIA A VYHRAJTE CENU',
          },
          link: 'Odkaz',
          message: 'Text v kolese',
          remove: 'Odstránenie stránky',
          subheadline: {
            value: 'Roztočte koleso šťastia, zaregistrujte sa a ja vám pošlem výhru e-mailom!',
          },
          type: 'Typ',
          value: 'Hodnota',
          free_ebook: 'E-knižka zadarmo',
        },
      },
      leads: {
        destroy_confirm: 'Ste si istí, že chcete odstrániť %{count} stopy?',
        select_leads_to_destroy: 'Vyberte stopy, ktoré chcete zničiť',
      },
      liteAmountMonth: '47',
      liteAmountYear: '41',
      maintainPrize: 'Musíte získať aspoň jednu cenu',
      page_settings: {
        unable_to_deactivate: 'Úvodnú stránku nemožno deaktivovať. Prejdite do nastavení inej stránky a vyberte ju ako úvodnú stránku.',
      },
      blog_settings: {
        delete_menu_item_confirmation: 'Táto položka bude odstránená. Chceš odstrániť túto položku?',
      },
      publisher: {
        enabled: 'aktívny',
        disabled: 'neaktívny',
        page_activated: ' Stránka je aktivovaná ',
        page_deactivated: ' Stránka je deaktivovaná ',
      },
      plans: {
        enterprise: {
          amount: {
            month: '99',
            year: '83',
          },
        },
        free: {
          amount: {
            month: '0',
            year: '0',
          },
        },
        kickstart: {
          amount: {
            month: '9',
            year: '8',
          },
        },
        period: {
          month: '/ mesiac',
          year: '/ mesiac',
        },
        premium: {
          amount: {
            month: '29',
            year: '24',
          },
        },
      },
      premiumAmountMonth: '297',
      premiumAmountYear: '249',
      save: 'Uložiť',
      schriftart: 'Primárne písmo',
      strategy: {
        time_remaining: 'Zostávajúci čas: %{days} dní %{hours} hodín %{minutes} minút %{seconds} sekúnd',
      },
      winningSlots: 'Nemôžete pridať viac ako 12 výherných slotov',
    },
    cs: {
      ckeditor: {
        placeholder: 'Zde zadej obsah.',
      },
      traffic: {
        direkter_traffic: 'Přímý provoz',
        referrer_seiten: 'Referenční stránky',
        mail_referrer: 'Odesílatel e-mailu',
        suchmaschinen: 'Vyhledávače',
        soziale_netzwerke: 'Sociální sítě',
        werbeanzeigen: 'Reklamy',
      },
      billing: {
        unable_to_get_plan_details: 'Nelze získat podrobnosti plánu',
        unable_to_confirm_card: 'Nelze potvrdit. Přidejte prosím jinou kartu.',
        invalid_card: 'Neplatná karta. Zkuste použít jinou kartu.',
      },
      businessAmountMonth: '97',
      businessAmountYear: '83',
      characters_remaining: 'zbývající znaky',
      complete_order: 'Kompletní objednávka',
      errorFormNotPresent: 'Formulář není přítomen',
      errorFormSubmit: 'Formulář musí být před odesláním analyzován a prezentován',
      errorParsingForm: 'Chybné zpracování formuláře',
      errorsomethingWrong: 'Omlouváme se, něco se pokazilo',
      facebook: {
        shareSite: 'Právě jsem dokončil tvorbu svých prvních webových stránek v masterpages.com. Prosím, podívejte se a dejte mi zpětnou vazbu na webové stránky. Co si o tom myslíte?',
      },
      farbschemata: 'Základní barva',
      font_example_text: 'Takto se zobrazují textové odstavce',
      formIntegrated: 'Integrovaný formulář!',
      formRemoved: 'Formulář odstraněn!',
      graph: {
        views: 'Zobrazení',
      },
      image_picker: {
        cancel: 'Zrušit',
        default_text: 'Přesuň sem soubor nebo klikni na tlačítko pro výběr přímo z počítače',
        insert_picture: 'Vložit obrázek',
        remove_file: 'Odstranit soubor',
        upload_canceled: 'Nahrávání je zrušeno.',
        cancel_upload: 'Zrušit nahrávání',
        file_too_big: 'Soubor je příliš velký ({{filesize}}MiB). Maximální velikost souboru: {{maxFilesize}}MiB.',
        cancel_upload_confirmation: 'Opravdu chcete zrušit toto nahrávání?',
      },
      colors: {
        green: 'Zelená',
        red: 'Červená',
        purple: 'Fialová',
        'light blue': 'Světle modrá',
        ocre: 'Okrová',
        'Dark blue': 'Tmavě modrá',
        'Redish purple': 'Magenta',
        'Light green': 'Světle zelená',
        'Dark red': 'Tmavě oranžová',
        'Pink': 'Růžová',
        blau: 'Modrá',
        blue: 'Modrá',
        'Bluish green': 'Tyrkysová',
        yellow: 'Žlutá',
        orange: 'Oranžová',
      },
      custom_domain_ssl_controller: {
        error_message: 'Nelze vygenerovat certifikát ssl. Zkuste to prosím znovu nebo kontaktujte podporu.',
        dns_warning: 'DNS ještě není publikováno - zkuste to znovu za 15 minut.',
      },
      domain_dns_verification_controller: {
        unable_to_verify: 'Tuto doménu nelze ověřit. Zkuste to prosím znovu nebo kontaktujte podporu.',
      },
      intercom: {
        pre_message: 'Liebes Support Team, ich benötige zusätzliche Informationen zu:',
      },
      lead_tools: {
        third_party_form: {
          matched_inputs_description: 'Zde můžete přiřadit položky zpracovávaného formuláře:',
        },
        input_names: {
          email: 'E-mail',
          first_name: 'Křestní jméno',
          last_name: 'Příjmení',
          phone: 'Telefon',
          city: 'Město',
          country: 'Země',
          street: 'Street',
          street_number: 'Číslo ulice',
          post_code: 'Poštovní směrovací číslo',
          voucher_code: 'Kód voucheru',
          custom: 'Vlastní',
        },
        blog_article: {
          button_text: {
            value: 'ŽÁDOST',
          },
          email_text: {
            value: 'E-mailová adresa',
          },
          headline: {
            value: 'Bezplatný videokurz',
          },
          subheadline: {
            value: 'Zaregistrujte se a získejte 5 strategií pohodlně e-mailem.',
          },
        },
        common_labels: {
          button_text: 'Text tlačítka',
          email_text: 'Zástupce e-mailu',
          headline: 'Titulek',
          main_image: 'Hlavní obrázek',
          subheadline: 'Text',
        },
        email_text: {
          value: 'E-mailová adresa',
        },
        full_page: {
          button_text: {
            value: 'ZÍSKAT KNIHU',
          },
          email_text: {
            value: 'E-mailová adresa',
          },
          headline: {
            value: 'ZÍSKEJTE KNIHU ZDARMA',
          },
          subheadline: {
            value: 'Zaregistrujte se zdarma a získáte kopii naší nové knihy',
          },
        },
        names: {
          blog_article: 'Banner s článkom',
          blog_article_description: 'Popis banneru článku na blogu',
          full_page_description: 'Popis celé stránky',
          optin_popup: 'Chytré vyskakovací okno',
          optin_popup_description: 'Inteligentní popis vyskakovacího okna',
          quiz: 'Kvíz',
          slide_down: 'Sklouzněte dolů',
          slide_down_description: 'Posuvný popis',
          sweepstake: 'Soutěž',
          sweepstake_description: 'Popis loterie',
          top_bar: 'Panel záhlaví',
          top_bar_description: 'Popis panelu záhlaví',
          wheel_popup: 'Vyskakovací okno kola štěstí',
          wheel_popup_description: 'Popis vyskakovacího okna kola štěstí',
        },
        optin_popup: {
          button_text: {
            value: 'ZÍSKAT SLEVU',
          },
          email_text: {
            value: 'E-mailová adresa',
          },
          headline: {
            value: 'AKTUÁLNÍ SLEVOVÝ KÓD: 10% SLEVA NA VŠE',
          },
          subheadline: {
            value: 'Zaregistrujte se a získejte aktuální slevový kód na všechny produkty.',
          },
        },
        quiz: {
          answer: {
            answ_1: 'Produkty',
            answ_1_1_1: '1 výrobek',
            answ_1_1_2: 'Více produktů',
            answ_1_2_1: 'Vyhledání cílové skupiny',
            answ_1_2_2: 'Nastavení technologie',
            answ_1_2_3: 'Vytvoření kampaně',
            answ_1_2_4: 'Další',
            answ_2: 'Služby',
            answ_2_1_1: 'B2B',
            answ_2_1_2: 'B2C',
            answ_2_2_1: '&lt;500€',
            answ_2_2_2: '500€ - 2.000€',
            answ_2_2_3: '2.000€ - 8.000€',
            answ_2_2_4: '&gt;8.000€',
            header: 'Odpověď %{index}',
            text: 'Toto je nová odpověď. Zadej text odpovědi',
          },
          back_button_text: {
            label: 'Text tlačítka zpět',
            value: 'OTÁZKA ZPĚT',
          },
          confirm_text: {
            label: 'Text tlačítka Výsledek',
            value: 'DALŠÍ KROK',
          },
          headline: {
            value: 'Domluvte si strategický rozhovor:',
          },
          input: {
            continue: 'Pokračovat',
          },
          new_question: {
            answer: {
              text: 'Odpověď 1',
              value: 'odpověď1',
            },
            description: 'Zde je doplňující popis k otázce',
            title: 'Zde je otázka',
          },
          question: {
            desc_1: 'Vyberte odpověď:',
            desc_1_1: 'Vyberte odpověď:',
            desc_1_2: 'Vyberte odpověď:',
            desc_2_1: 'Vyberte odpověď:',
            desc_2_2: 'Vyberte odpověď:',
            text_1: 'Prodáváte produkty nebo služby?',
            text_1_1: 'Prodáváte jeden produkt nebo více?',
            text_1_2: 'Co je pro vás v současné době největší výzvou v online marketingu?',
            text_2_1: 'Prodáváte své služby podnikatelům (B2B) nebo soukromým osobám (B2C)?',
            text_2_2: 'V jakém cenovém rozpětí jsou vaše služby?',
          },
          question_path: {
            create: 'Vytvořit doplňující otázku',
            follow: 'Vytvoření následující otázky',
          },
          result: {
            button: {
              text: 'Další krok',
              url: 'https://masterpages.com',
            },
            default_sample_result: 'Zajistěte si osobní diskuzi o analýze s jedním z našich marketingových odborníků. Provedeme analýzu vašich webových stránek a navrhneme způsoby, jak na nich vydělat více peněz.',
            default_text: 'Jedná se o nový výsledek. Zadejte text výsledku.',
            optin: {
              thank_you: 'Děkujeme za účast!',
              url: 'https://masterpages.com',
            },
            result_3: 'Začněte s vlastními marketingově optimalizovanými webovými stránkami během několika minut. Vše je připraveno!',
          },
          search_icon: 'Ikona vyhledávání',
          select_icon: 'Vyberte ikonu',
          no_results_found: 'Nebyly nalezeny žádné výsledky...',
          delete: 'Vymazat',
          subheadline: {
            value: 'V nezávazné diskusi vám odborník z našeho týmu objasní, jak můžete marketingové kvízy nejsnáze používat sami.',
          },
        },
        slide_down: {
          button_text: {
            value: 'COUPON SECURE',
          },
          headline: {
            value: 'KUPON ZDARMA: 10% SLEVA NA VŠE',
          },
          subheadline: {
            value: 'Zaregistrujte se a získejte voucher',
          },
        },
        sweepstake: {
          button_text: {
            value: 'ZŮČASTNIT SE',
          },
          headline: {
            value: 'VYHRÁT HNED',
          },
          subheadline: {
            value: 'Zaregistrujte se a zúčastněte se soutěže:',
          },
        },
        top_bar: {
          button_text: {
            value: 'ZŮČASTNIT SE',
          },
          email_text: {
            value: 'E-mailová adresa',
          },
          headline: {
            value: 'KUPÓN TÝDNE: ZAPLAŤTE ZA 1 - ZÍSKEJTE 2',
          },
          subheadline: {
            value: 'Zadejte svůj e-mail, abyste obdrželi speciální voucher.',
          },
        },
        wheel_of_fortune: {
          form_button_text: {
            value: 'Odeslat',
          },
          form_title: {
            value: 'Odešlete formulář a požádejte o výhru',
          },
        },
        wheel_popup: {
          button_text: {
            value: 'ROTATE',
          },
          chance: 'Pravděpodobnost výhry (%)',
          discount: 'SLEVA',
          discount_value: 'Kód slevy',
          email_text: {
            value: 'E-mailová adresa',
          },
          headline: {
            value: 'ROZTOČTE KOLO ŠTĚSTÍ A VYHRAJTE CENU',
          },
          link: 'Odkaz',
          message: 'Text v kolečku',
          remove: 'Odstranění adresy',
          subheadline: {
            value: 'Roztočte kolo štěstí, zaregistrujte se a já vám pošlu výhru e-mailem!',
          },
          type: 'Typ',
          value: 'Hodnota',
          free_ebook: 'E-kniha zdarma',
          discount_code: 'Kód slevy',
        },
      },
      leads: {
        destroy_confirm: 'Jste si jisti, že chcete odstranit stopy %{count}?',
        select_leads_to_destroy: 'Vyberte stopy, které chcete zničit',
      },
      liteAmountMonth: '47',
      liteAmountYear: '41',
      maintainPrize: 'Musíte si ponechat alespoň jednu cenu',
      page_settings: {
        unable_to_deactivate: 'Úvodní stránku nelze deaktivovat. Přejděte do nastavení jiné stránky a vyberte ji jako úvodní stránku.',
      },
      blog_settings: {
        delete_menu_item_confirmation: 'Tato položka bude odstraněna. Opravdu chceš odstranit tuto položku?',
      },
      publisher: {
        enabled: 'povoleno',
        disabled: 'zakázané',
        page_activated: ' Stránka je aktivována ',
        page_deactivated: ' Stránka je deaktivována ',
      },
      plans: {
        enterprise: {
          amount: {
            month: '99',
            year: '83',
          },
        },
        free: {
          amount: {
            month: '0',
            year: '0',
          },
        },
        kickstart: {
          amount: {
            month: '9',
            year: '8',
          },
        },
        period: {
          month: '/ měsíc',
          year: '/ měsíc',
        },
        premium: {
          amount: {
            month: '29',
            year: '24',
          },
        },
      },
      premiumAmountMonth: '297',
      premiumAmountYear: '249',
      save: 'Uložit',
      schriftart: 'Primární písmo',
      strategy: {
        time_remaining: 'Zbývající čas: %{days} dny %{hours} hodiny %{minutes} minuty %{seconds} sekundách',
      },
      winningSlots: 'Nelze přidat více než 12 výherních slotů.',
    },
  },
  t: function(tag, variables = {}) {
    const tags = tag.split('.');
    const localeAttrs = this.i18n[this.locale()];
    let value = helpers.dig(localeAttrs, tags);
    value = textMerger.merge(value, variables, '%');
    return value || '';
  },
  locale: function() {
    return document.body.dataset.locale || 'de';
  },
};