import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'afterConfirmationContainer',
    'nextStepEmailContainer',
    'optinTypeSingleCheck',
    'optinTypeDoubleCheck',
    'nextStepEmailSubject',
    'afterConfirmationUrl',
    'afterConfirmationUrlCheckbox',
    'afterConfirmationPageCheckbox',
    'thankYouCheckContainer',
    'thankYouEmailCheck',
    'thankYouEmailSubject',
  ];

  connect() {
    this.showOptinEmails();

    this.thankYouEmailCheckTarget.addEventListener('change', () => {
      this.thankYouEmailSubjectTarget.required = this.thankYouEmailCheckTarget.checked;
    });
  }

  showOptinEmails() {
    const optinType = this.getOptinType();

    switch(optinType) {
      case 'double':
        this.thankYouCheckContainerTarget.classList.remove('d-none');
        this.nextStepEmailContainerTarget.classList.remove('d-none');
        this.nextStepEmailSubjectTarget.required = true;
        this.thankYouEmailSubjectTarget.required = this.thankYouEmailCheckTarget.checked;
        this.showConfirmationFields();
        break;
      case 'single':
        this.thankYouCheckContainerTarget.classList.add('d-none');
        this.nextStepEmailContainerTarget.classList.add('d-none');
        this.nextStepEmailSubjectTarget.required = false;
        this.hideAfterConfirmationFields();
      break;
      default:
    }
  }

  showConfirmationFields() {
    const optinType = this.getOptinType();
    const afterConfirmation = this.getAfterConfirmation();

    if (optinType === 'single') return;

    this.afterConfirmationContainerTarget.classList.remove('d-none');
    this
      .element
      .querySelectorAll('.confirmation-action')
      .forEach((container) => container.classList.add('d-none'));

    switch(afterConfirmation) {
      case 'url':
        this.element.querySelector('.confirmation-action.url').classList.remove('d-none');
        this.afterConfirmationUrlTarget.required = true;
      break;
      case 'internal_page':
        this.element.querySelector('.confirmation-action.internal_page').classList.remove('d-none');
        this.afterConfirmationUrlTarget.required = false;
      break;
      default:
    }
  }

  hideAfterConfirmationFields() {
    this.afterConfirmationContainerTarget.classList.add('d-none');
    this.afterConfirmationUrlTarget.required = false;
  }

  getOptinType() {
    if (this.optinTypeSingleCheckTarget.checked) {
      return 'single';
    } else {
      return 'double';
    }
  }

  getAfterConfirmation() {
    if (this.afterConfirmationUrlCheckboxTarget.checked) {
      return 'url';
    } else {
      return 'internal_page';
    }
  }

}