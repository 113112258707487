import I18n from './i18n';
import Dropzone from 'dropzone';
import Croppie from 'croppie';

const ImagePicker = function(
  selector, uploadURL, onSuccess, onRemove, currentImage, viewportDimensions = '300x300',
) {
  this.url = '';
  const that = this;

  const appendOverlay = function() {
    let container = document.createElement('div');
    let editor = document.createElement('div');

    container.style.position = 'fixed';
    container.style.left = 0;
    container.style.right = 0;
    container.style.right = 0;
    container.style.top = 0;
    container.style.bottom = 0;
    container.style.zIndex = 9999;
    container.style.backgroundColor = '#FFF';
    container.appendChild(editor);
    document.body.appendChild(container);

    return container;
  };

  const appendConfirmButton = function(overlay) {
    let confirmButton = document.createElement('button');
    confirmButton.style.position = 'absolute';
    confirmButton.style.right = '20px';
    confirmButton.style.bottom = '20px';
    confirmButton.style.zIndex = 9999;
    confirmButton.textContent = I18n.t('image_picker.insert_picture');
    confirmButton.className = 'btn btn-primary';
    overlay.appendChild(confirmButton);

    return confirmButton;
  };

  const appendCloseButton = function(overlay) {
    const closeButton = document.createElement('button');
    closeButton.href = 'javascript:undefined;';
    closeButton.style.position = 'absolute';
    closeButton.style.left = '20px';
    closeButton.style.bottom = '20px';
    closeButton.style.zIndex = 9999;
    closeButton.style.backgroundColor = 'transparent';
    closeButton.style.color = '#0F6AF2';
    closeButton.style.border = '2px solid #0F6AF2';
    closeButton.textContent = I18n.t('image_picker.cancel');
    closeButton.className = 'btn btn-primary';
    overlay.appendChild(closeButton);

    return closeButton;
  };

  let myDropzone = new Dropzone(selector, {
    url: uploadURL,
    paramName: 'file',
    maxFiles: 1,
    maxFilesize: 2,
    acceptedFiles: 'image/jpeg,image/png',
    addRemoveLinks: true,
    dictDefaultMessage: I18n.t('image_picker.default_text'),
    dictFileTooBig: I18n.t('image_picker.file_too_big'),
    dictCancelUpload: I18n.t('image_picker.cancel_upload'),
    dictUploadCanceled: I18n.t('image_picker.upload_canceled'),
    dictRemoveFile: I18n.t('image_picker.remove_file'),
    dictCancelUploadConfirmation: I18n.t('image_picker.cancel_upload_confirmation'),
    init: function() {
      if (currentImage) {
        const element = this.element;
        const dzMessage = element.querySelector('.dz-message');
        const dzPreview = document.createElement('div');
        const dzImage = document.createElement('div');
        const image = document.createElement('img');
        const removeLink = document.createElement('a');

        dzPreview.className = 'dz-preview';
        this.customPreview = dzPreview;

        dzImage.className = 'dz-image';

        image.src = currentImage;
        image.dataset.dzThumbnail = '';

        removeLink.className = 'dz-remove';
        removeLink.href = 'javascript:undefined;';
        removeLink.dataset.dzRemove = '';
        removeLink.textContent = 'Remove file';
        removeLink.addEventListener('click', (e) => {
          if (typeof(onRemove) === 'function') {
            onRemove(currentImage);
          }
          dzMessage.style.display = '';
          dzPreview.remove();
        });

        dzMessage.style.display = 'none';
        dzImage.appendChild(image);
        dzPreview.appendChild(dzImage);
        dzPreview.appendChild(removeLink);
        element.appendChild(dzPreview);
      }
    },
    transformFile: function(file, done) {
      let overlay = appendOverlay();
      let editor = overlay.firstChild;
      let confirmButton = appendConfirmButton(overlay);
      let closeButton = appendCloseButton(overlay);
      const viewportWidth = viewportDimensions.split('x')[0] || '300';
      const viewportHeight = viewportDimensions.split('x')[1] || '300';
      const that = this;
      let croppie = new Croppie(editor, {
        enableResize: true,
        viewport: {
          height: parseInt(viewportHeight),
          width: parseInt(viewportWidth),
        },
        showZoomer: true,
      });

      croppie.bind({
        url: URL.createObjectURL(file),
      });

      confirmButton.addEventListener('click', function() {
        croppie.result({
          type: 'blob',
          size: 'original',
        }).then(function(blob) {
          myDropzone.createThumbnail(
            blob,
            myDropzone.options.thumbnailWidth,
            myDropzone.options.thumbnailHeight,
            myDropzone.options.thumbnailMethod,
            false,
            function(dataURL) {
              myDropzone.emit('thumbnail', file, dataURL);
              done(blob);
            },
          );
        });

        document.body.removeChild(overlay);
      });

      closeButton.addEventListener('click', function() {
        that.removeFile(file);
        document.body.removeChild(overlay);
      });
    },
  });

  myDropzone.on('success', function(file, response) {
    that.url = response.data.url;
    this.customPreview && this.customPreview.remove();
    myDropzone.emit('thumbnail', file, that.url);
    onSuccess(response);
  });

  myDropzone.on('removedfile', () => {
    if (typeof(onRemove) === 'function' && this.url.length > 0) {
      onRemove(this.url);
    }
  });

  return myDropzone;
};

export default ImagePicker;
