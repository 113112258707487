import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  connect() {
    const inputs = this.element.querySelectorAll('input');
    const checkBox = this.element.querySelector('#user_accepted_terms');
    inputs.forEach((input) => {
      input.addEventListener('keypress', function() {
        if (this.classList.contains('is-invalid')) {
          this.classList.remove('is-invalid');
        }
      });
    });

    if (checkBox) {
      checkBox.addEventListener('change', function() {
        if (this.classList.contains('is-invalid')) {
          this.classList.remove('is-invalid');
        }
      });
    }
  }
}