import { Application } from '@hotwired/stimulus';
import LocalTime from 'local-time';

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus   = application;

LocalTime.start();

export { application };
