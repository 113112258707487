import { Controller } from '@hotwired/stimulus';
import { loadStripe } from '@stripe/stripe-js';

export default class extends Controller {
  static values = {
    stripeKey: String,
    clientSecret: String,
  }

  async connect() {
    const stripe = await loadStripe(this.stripeKeyValue);
    stripe
      .initEmbeddedCheckout({ clientSecret: this.clientSecretValue })
      .then((checkout) => {
        checkout.mount(this.element);
      });
  }
}