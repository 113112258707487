import I18n from '../../../app/i18n';
import { ruleSets } from '../validator';
import rangeSlider from 'range-slider-input';

const ContentForm = function(toolData, mainForm) {
  const mainInput = mainForm.input;

  this.id = 'tool-content-form';
  this.form = document.querySelector(`#${this.id}`);

  this.changeAction = (input) => {
    if (input.name === 'losing_slice') {
      mainInput.settings.slices.options = mainInput.settings.slices.options || {};
      mainInput.settings.slices.options.losing_slice = mainInput.settings.slices.options.losing_slice || {};
      mainInput.settings.slices.options.losing_slice.message = input.value;
    } else if (input.name.indexOf('slices') > -1) {
      let slices = mainInput.settings.slices.values;
      let fields = input.name.match(/\[(.+?)\]/g);
      let index = fields[0].replace(/\[|\]/g, '');
      let name = fields[1].replace(/\[|\]/g, '');

      let slice = slices.find((slice) => slice.number === index);
      if (!slice) {
        throw 'Slice not found';
      }

      slice[name] = input.value;

      if (name === 'type') {
        let group = document.querySelector('.slice-group-' + index);
        let prizeInputs = group.querySelectorAll('.prize-options');

        if (input.value === 'no_prize') {
          prizeInputs.forEach((input) => input.classList.add('d-none'));
        } else {
          prizeInputs.forEach((input) => input.classList.remove('d-none'));
        }
      }
    } else if (input.name.length > 0) {
      mainInput.content[input.name] = input.value;
      mainForm.updatePreview();
    }
  };

  this.updateFields = function() {
    let validatorRules = mainForm.validator.rules.settings;

    document.querySelectorAll(
      `#${this.id} input, #${this.id} select, #${this.id} textarea`,
    ).forEach((input) => {
      if (input.name.indexOf('slices') > -1) {
        if (input.name.indexOf('chance') > -1) {
          input.addEventListener('change', () => {
            this.updateTotalChance();
          });
        }

        mainForm.validator.rules.settings = ruleSets.update(validatorRules, 'forSlices');

        let slices = mainInput.settings.slices.values;
        let fields = input.name.match(/\[(.+?)\]/g);
        let index = fields[0].replace(/\[|\]/g, '');
        let name = fields[1].replace(/\[|\]/g, '');
        let slice = slices.find((sl) => Number(sl.number) === Number(index));

        if (!slice) {
          throw Error('Slice not found');
        }

        if (name === 'type') {
          input.value = slice.type;
        }

        if (name === 'text') {
          input.value = slice.text;
        }

        if (name === 'value') {
          input.value = slice.value;
        }

        if (name === 'chance') {
          input.value = slice.chance;
        }
      } else if (input.name === 'winning-rate') {
        let winningRate = this.form.querySelector('.bet-rate .winning-rate');
        let losingRate = this.form.querySelector('.bet-rate .losing-rate');
        let currentWinningRate = mainInput.settings.slices.options.winning_rate;

        rangeSlider(document.querySelector('#winning-rate-slider'), {
          min: 0,
          max: 100,
          value: [0, currentWinningRate],
          thumbsDisabled: [true, false],
          rangeSlideDisabled: true,
          onInput: (values, userInteraction) => {
            const value = values[1];

            if (userInteraction) {
              winningRate.textContent = `${value}%`;
              losingRate.textContent = `${100 - value}%`;
              mainInput.settings.slices.options.winning_rate = value;
            }
          },
        });
      } else if (input.type === 'text' || input.type === 'textarea') {
        if (mainInput.content[input.name]) {
          input.value = mainInput.content[input.name];
        }
      }
    });
  };

  this.updateTotalChance = function() {
    let chanceInputs = [];
    let totalChanceEl = document.getElementById('total-chance');
    let inputs = this.form.querySelectorAll('input[type="number"]');
    let totalChance = 0;

    inputs.forEach((input) => {
      if (input.name.indexOf('chance') > -1) {
        chanceInputs.push(input);
      }
    });

    totalChance = chanceInputs.reduce((acc, current) => {
      return acc + parseInt(current.value);
    }, 0);

    totalChanceEl.textContent = totalChance + '%';

    if (totalChance === 100) {
      totalChanceEl.style.color = 'green';
    } else if (totalChance < 100 || totalChance > 100) {
      totalChanceEl.style.color = 'red';
    }
  };

  this.addSlice = function() {
    let sliceTable = this.form.querySelector('#slice-table tbody');

    if (sliceTable.children.length === 12) {
      mainForm.validator.addChanceMessage(I18n.t('winningSlots'));
      alert(I18n.t('winningSlots'));
    }

    let row = document.createElement('tr');
    let slices = mainInput.settings.slices.values;
    let lastSliceIndex = slices[slices.length - 1].number;
    let newSliceIndex = lastSliceIndex + 1;

    row.className = 'slice-group-' + newSliceIndex;

    let rowHTML = `
      <td>
        <a class="remove-slice" data-index="${newSliceIndex}">
          ${I18n.t('lead_tools.wheel_popup.remove')}
        </a>
        <div class="form-group mt-5">
          <label class="form-label">${I18n.t('lead_tools.wheel_popup.type')}</label>
          <select class="form-control" name="slices[${newSliceIndex}][type]">
            <option selected="selected" value="code">
              ${I18n.t('lead_tools.wheel_popup.discount_code')}
            </option>
            <option value="link">
              ${I18n.t('lead_tools.wheel_popup.link')}
            </option>
          </select>
        </div>
        <div class="mb-2 prize-options">
          <label class="form-label">${I18n.t('lead_tools.wheel_popup.value')}</label>
          <input class="form-control" name="slices[${newSliceIndex}][value]" type="text" value="${I18n.t('lead_tools.wheel_popup.discount')}">
        </div>
        <div class="mb-2">
          <label class="form-label">${I18n.t('lead_tools.wheel_popup.message')}</label>
          <input class="form-control" name="slices[${newSliceIndex}][text]" type="text" value="${I18n.t('lead_tools.wheel_popup.discount_value')}">
        </div>
        <div class="mb-2 prize-options">
          <label class="form-label">${I18n.t('lead_tools.wheel_popup.chance')}</label>
          <input class="form-control" name="slices[${newSliceIndex}][chance]" type="number" value="0">
        </div>
      </td>
    `;

    row.innerHTML = rowHTML;

    let removeBtn = row.querySelector('.remove-slice');
    removeBtn.addEventListener('click', (e) => {
      e.preventDefault();
      this.removeSlice(e.target);
      this.updateTotalChance();
    });

    row.querySelectorAll('input, select').forEach((input) => {
      input.addEventListener('change', () => {
        this.changeAction(input);
      });

      if (input.name.indexOf('chance') > -1) {
        input.addEventListener('change', () => {
          this.updateTotalChance();
        });
      }
    });

    sliceTable.appendChild(row);

    slices.push({
      type: 'code',
      text: 'Discount Code',
      value: 'THECODE',
      chance: 0,
      number: newSliceIndex,
    });
  };

  this.removeSlice = function(el) {
    let slices = mainInput.settings.slices.values;
    let sliceIndex = el.dataset.index;
    let sliceTable = this.form.querySelector('#slice-table tbody');

    if (sliceTable.children.length > 1) {
      let index = el.dataset.index;
      let row = this.form.querySelector(`.slice-group-${index}`);

      if (row) {
        row.remove();
        mainInput.settings.slices.values = slices.filter(slice => slice.number != sliceIndex);
      }
    } else {
      // TODO: show a better error
      alert(I18n.t('maintainPrize'));
    }
  };

  this.init = function() {
    this.updateFields();

    document.querySelectorAll(
      `#${this.id} input, #${this.id} select, #${this.id} textarea`,
    ).forEach((input) => {
      input.addEventListener('change', () => {
        this.changeAction(input);
      });
    });

    let addSliceBtn = this.form.querySelector('#add-slice');
    if (addSliceBtn) {
      addSliceBtn.addEventListener('click', (e) => {
        e.preventDefault();
        this.addSlice();
        this.updateTotalChance();
      });
    }

    this.form.querySelectorAll('.remove-slice').forEach((btn) =>{
      btn.addEventListener('click', (e) => {
        e.preventDefault();
        this.removeSlice(e.target);
        this.updateTotalChance();
      });
    });
  };
};

export default ContentForm;
