const validate = require('../../vendor/validate.js');
import helpers from '../utils/helpers';
import * as bootstrap from 'bootstrap';

validate.validators.slices = function(value) {
  let slices = value;
  let sliceErrors = [];
  let totalChance = slices.reduce((acc, current) => {
    return acc + parseInt(current.chance);
  }, 0);

  for (let i = 0; i < slices.length; i++) {
    let errors = [];
    let slice = slices[i];

    if (!slice.text) {
      errors.push('text-Message can\'t be blank');
    }

    if ((slice.type === 'link' || slice.type === 'code') && !slice.value) {
      errors.push('value-Value can\'t be blank');
    }

    if (errors.length > 0) {
      errors.unshift(slice.number);
      sliceErrors.push(errors.join(','));
    }

    if (Number(totalChance) !== 100) {
      sliceErrors.push('total-chance, total_chance-Total Chance Must be 100%');
    }
  }

  return sliceErrors;
};

const ruleSets = {
  forSingleOptin: {
    thank_you_email_subject: {
      presence: true,
    },
    thank_you_email: {
      presence: true,
    },
    next_step_email_subject: {},
    next_step_email: {},
  },
  forAfterConfirmUrl: {
    after_confirmation_url: {
      url: true,
    },
  },
  forAfterConfirmPage: {
    after_confirmation_url: {},
  },
  forDoubleOptin: {
    thank_you_email_subject: {
    },
    thank_you_email: {
      presence: true,
    },
    next_step_email_subject: {
      presence: true,
    },
    next_step_email: {
      presence: true,
    },
  },
  forNextStepURL: {
    url: {
      url: {
        message: '%{value} is not a valid URL',
      },
    },
    text_message: {},
  },
  forThankYouMessage: {
    url: {},
    text_message: {
      presence: true,
    },
  },
  forSlices: {
    slices: {
      slices: true,
    },
  },
  update: function(rules, ruleSet) {
    return Object.assign({}, rules, this[ruleSet]);
  },
};

const Validator = {
  rules: {
    settings: {
      url: {
        url: {
          message: '${value} is not a valid URL',
        },
      },
      text_message: {
        presence: true,
      },
    },
    content: {
      headline: {
        presence: true,
      },
      subheadline: {
        presence: true,
      },
      confirm_text: {
        presence: true,
      },
    },
    rules: {},
  },
  prepareForValidation: function(currentData) {
    let toValidate = {
      content: {},
      settings: {},
      optin: {},
      rules: {},
      design: {},
    };

    for (let label in currentData.content) {
      toValidate.content[label] = currentData.content[label];
    }

    for (let label in currentData.settings) {
      if (label === 'next_step') {
        let nextStep = helpers.dig(currentData, ['settings', 'next_step', 'value']);

        if (nextStep === 'go_to_url') {
          let currentURL = helpers.dig(
            currentData, ['settings', 'next_step', 'options', 'url'],
          );
          toValidate.settings['url'] = currentURL;
        }

        if (currentData.settings.next_step.value === 'thank_you_text') {
          let message = helpers.dig(
            currentData, ['settings', 'next_step', 'options', 'text_message'],
          );
          toValidate.settings['text_message'] = message;
        }
      }

      if (label === 'slices') {
        let slices = helpers.dig(currentData, ['settings', 'slices', 'values']);
        toValidate.settings['slices'] = slices;
      }
    }


    return toValidate;
  },
  validateSettings: function(settings) {
    this.deleteErrors();
    let fieldsToValidate = this.prepareForValidation(settings);
    let errorsFound = false;

    for (let ruleSet in this.rules) {
      if (Object.prototype.hasOwnProperty.call(this.rules, ruleSet)) {
        let errors = validate(
          fieldsToValidate[ruleSet], this.rules[ruleSet], { fullMessages: false },
        );

        if (errors) {
          errorsFound = true;
        }

        this.showInputErrors(ruleSet, errors);
      }
    }

    if (errorsFound) {
      this.focusError();
    }

    return errorsFound;
  },
  focusError: function() {
    let inputError = document.querySelector('.input-error');
    if (inputError) {
      let parentForm = helpers.parent(inputError, 'form');
      let tabId = parentForm.dataset.tab + '-tab';

      const tabEl = document.querySelector(`#${tabId}`);
      bootstrap.Tab.getInstance(tabEl).show();

      setTimeout(function() {
        let formGroup = helpers.parent(inputError, '.form-group');
        formGroup.parentNode.scrollIntoView();
      }, 200);
    }
  },
  addChanceMessage: function(message) {
    let totalChanceContainer = document.querySelector('.wheel-chance');
    let errorSpan = totalChanceContainer.querySelector('span.input-error');
    if (!errorSpan) {
      errorSpan = document.createElement('span');
      totalChanceContainer.insertBefore(errorSpan, totalChanceContainer.firstChild);
    }

    errorSpan.className = 'input-error alert alert-danger';
    errorSpan.textContent = message;
  },
  showSlicesErrors: function(sliceErrors) {
    sliceErrors.forEach((errors) => {
      let inputErrors = errors.split(',');
      let sliceIndex = inputErrors[0];

      if (sliceIndex === 'total-chance') {
        this.addChanceMessage('Total Chance must be equal to 100%');
      } else {
        for (let i = 1; i < inputErrors.length; i++) {
          let messages = [];
          let errorDetails = inputErrors[i].split('-');
          let sliceSection = errorDetails[0];
          let errorMessage = errorDetails[1];

          messages.push(errorMessage);

          let inputName = 'slices[' + sliceIndex + '][' + sliceSection + ']';
          this.appendErrors('settings', inputName, messages);
        }
      }
    });
  },
  showInputErrors: function(section, errors) {
    for(let inputName in errors) {
      if (Object.prototype.hasOwnProperty.call(errors, inputName)) {
        if (inputName === 'slices') {
          this.showSlicesErrors(errors[inputName]);
        } else {
          this.appendErrors(section, inputName, errors[inputName]);
        }
      }
    }
  },
  appendErrors: function(section, inputName, errors) {
    let input = document.querySelector(
      `#tool-${section}-form input[name="${inputName}"]`,
    );
    if (!input) {
      section = 'optin';
      input = document.querySelector(`#tool-${section}-form input[name="${inputName}"]`);
    }

    if (input) {
      const inputContainer = input.parentNode;

      let errorContainer = document.createElement('div');
      errorContainer.className = 'errors';

      errors.forEach((detail) => {
        let errorSpan = document.createElement('span');
        errorSpan.className = 'input-error';
        errorSpan.textContent = detail;
        errorContainer.appendChild(errorSpan);
      });

      inputContainer.appendChild(errorContainer);
    } else {
      console.log('input ' + inputName + ' not found');
    }
  },

  deleteErrors: function() {
    document
      .querySelectorAll('.tool-form .errors')
      .forEach((errorContainer) => {
        errorContainer.remove();
      });
  },
};

const urlRule = {
  isValid: function(str) {
    if (!str) {
      return true;
    }

    let res = str.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return res !== null;
  },
};

const requiredRule = {
  isValid: function(str) {
    return str && str.length > 0;
  },
};

export { Validator, ruleSets, urlRule, requiredRule };