import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'image', 'input', 'fileInfo' ]
  
  showImagePreview(event) {
    const file = this.inputTarget.files[0];
    if (!file) return;
  
    if (this.fileInfoTarget) this.fileInfoTarget.innerHTML = file.name;

    if (this.hasImageTarget) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.imageTarget.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  }  
}