import { Controller } from '@hotwired/stimulus';
import I18n from '../app/i18n';
import Cropper from 'cropperjs';

export default class extends Controller {
  static targets = [
    'summary',
    'summaryCounter',
    'image',
    'imageField',
  ];
 
  static values = {
    domain: String,
    siteId: Number,
  }

  connect() {
    this.refreshSummaryCount();

    if (this.imageFieldTarget.value != '') readURL(this.imageFieldTarget);
  }

  async readURL(input) {
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const overlay = this.appendCropperOverlay(file);
      const imageEl = overlay.querySelector('img');
      const closeCropperButton = this.appendCropperCloseButton(overlay);
      const confirmCropperButton = this.appendCropperConfirmButton(overlay);
      const that = this;

      const reader = new FileReader();
      reader.onload = (e) => { 
        imageEl.setAttribute('src', e.target.result);

        const cropper = new Cropper(imageEl, {
          aspectRatio: 21 / 9,
          autoCropArea: 0.9,
        });

        confirmCropperButton.addEventListener('click', function() {
          cropper.getCroppedCanvas().toBlob((blob) => {
            const url = URL.createObjectURL(blob);
            that.imageTarget.setAttribute('src', url);
  
            let croppedFile = new File(
              [blob],
              file.name, 
              { 
                type: file.type,
                lastModifiedDate: file.lastModifiedDate
              },
            );
            let container = new DataTransfer();
            container.items.add(croppedFile);
            that.imageFieldTarget.files = container.files;
          });
  
          document.body.removeChild(overlay);
        });
      };
      reader.readAsDataURL(file);

      closeCropperButton.addEventListener('click', function() {
        that.imageFieldTarget.files = null;
        that.imageFieldTarget.value = '';
        document.body.removeChild(overlay);
      });
    }  
  }   

  loadImage() {
    this.readURL(this.imageFieldTarget);
  }

  refreshSummaryCount() {
    const limit = 100;
    const wordCount = this.summaryTarget.value.length;
    let remaining = limit - wordCount;
    remaining = remaining < 0 ? 0 : remaining;
    if (this.summaryCounterTarget) {
      this.summaryCounterTarget.textContent = `${remaining}  ${I18n.t('characters_remaining')}`;
    }
  }

  appendCropperOverlay(file) {
    const container = document.createElement('div');
    const editor = document.createElement('div');
    editor.style.height = 'calc(100vh - 80px)';

    let buttonsContainer = document.createElement('div');
    buttonsContainer.id  = 'buttons-container';
    buttonsContainer.style.height = '80px';
    buttonsContainer.className = 'd-flex justify-content-end align-items-center';

    let image = document.createElement('img');
    image.style.maxWidth = '100%';

    container.style.position = 'fixed';
    container.style.left = 0;
    container.style.right = 0;
    container.style.right = 0;
    container.style.top = 0;
    container.style.bottom = 0;
    container.style.zIndex = 9999;
    container.style.backgroundColor = '#FFF';
    editor.appendChild(image);
    container.appendChild(editor);
    container.appendChild(buttonsContainer);
    document.body.appendChild(container);

    return container;
  };

  appendCropperConfirmButton(overlay) {
    const confirmButton = document.createElement('button');
    const buttonsContainer = overlay.querySelector('#buttons-container');
    confirmButton.textContent = I18n.t('image_picker.insert_picture');
    confirmButton.className = 'btn btn-primary ms-3 me-3';
    buttonsContainer.appendChild(confirmButton);

    return confirmButton;
  };

  appendCropperCloseButton(overlay) {
    const closeButton = document.createElement('button');
    const buttonsContainer = overlay.querySelector('#buttons-container');
    closeButton.href = 'javascript:undefined;';
    closeButton.className = 'btn btn-outline-primary'
    closeButton.textContent = I18n.t('image_picker.cancel');
    buttonsContainer.appendChild(closeButton);

    return closeButton;
  };
}