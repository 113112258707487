const canvasHelper = {
  findParent: function(model, matcher) {
    let parent = model.parent && model.parent();

    if (!parent) {
      return false;
    }

    while (parent) {
      if (matcher(parent)) {
        return parent;
      }
      parent = parent.parent();
    }

    return false;
  },
  findClosest: function(model, tagName) {
    let parent = model.parent && model.parent();

    if (!parent) {
      return false;
    }

    while (parent) {
      const components = parent.find(tagName);
      if (components.length > 0) return components[0];

      parent = parent.parent();
    }

    return false;
  },
  capitalize: function(s) {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  },
};

export default canvasHelper;
