import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  connect() {}

  submit() {
    clearTimeout(this.timeout);

    const frameEl = document.querySelector('turbo-frame#page-results');

    if (frameEl) {
      frameEl.innerHTML = `
        <div class="row">
          <div class="text-center my-3">
            <div class="spinner-grow text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      `;
    }

    this.timeout = setTimeout(() => {
      this.element.requestSubmit();
    }, 500);
  }
}