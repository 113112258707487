/* eslint-disable max-len */

import * as bootstrap from 'bootstrap';
import I18n from '../../i18n';
import ThirdPartyForm from './third_party_form';
import { ruleSets } from '../validator';

const OptinForm = function(data, mainForm) {
  this.id = 'tool-optin-form';
  this.form = document.getElementById(this.id);
  this.formParserUrl = '/forms/parse';

  this.changeAction = function(input) {
    let validatorRules = mainForm.validator.rules.settings;

    if (input.name === 'url' || input.name === 'text_message') {
      mainForm.input.settings['next_step'] = mainForm.input.settings['next_step'] || {};
      mainForm.input.settings['next_step'].options = {};
      mainForm.input.settings['next_step'].options[input.name] = input.value;
    }

    if (input.name === 'next_step') {
      mainForm.input.settings['next_step'] = mainForm.input.settings['next_step'] || {};
      mainForm.input.settings['next_step'].value = input.value;

      if (input.value === 'thank_you_text') {
        mainForm.validator.rules.settings = ruleSets.update(validatorRules, 'forThankYouMessage');
      }

      if (input.value === 'go_to_url') {
        mainForm.validator.rules.settings = ruleSets.update(validatorRules, 'forNextStepURL');
      }
    }

    if (input.name === 'privacy_url') {
      mainForm.input.settings.privacy_policy = mainForm.input.settings.privacy_policy || {};
      mainForm.input.settings.privacy_policy.options = mainForm.input.settings.privacy_policy.options || {};
      mainForm.input.settings.privacy_policy.options.url = input.value;
    }

    if (input.name === 'privacy_policy') {
      mainForm.input.settings.privacy_policy = mainForm.input.settings.privacy_policy || {};
      mainForm.input.settings.privacy_policy.value = input.value;
    }

    if (input.name === 'optin_process_id') {
      mainForm.input.settings.optin_process.options = {};
      mainForm.input.settings.optin_process.options.optin_process_id = input.value;
    }

    if (input.name === 'conversion_event') {
      mainForm.input.settings.conversion_event = {};
      mainForm.input.settings.conversion_event.value = input.value;
    }

    if (input.name === 'wheel_embed_form_prize_input_name') {
      mainForm.input.settings.third_party = mainForm.input.settings.third_party || {};
      mainForm.input.settings.third_party.prize_input_name = input.value;
    }

    if (input.name === 'fields' || input.name === 'fields-required') {
      this.updateInputFields();
    }
  };

  this.updateInputFields = function() {
    mainForm.input.settings['fields'] = {};
    mainForm.input.settings['fields'].values = [];
    let checkedFields = document.querySelectorAll(`#${this.id} input[name="fields"]:checked`);

    const fields = mainForm.input.settings['fields'].values;
    checkedFields.forEach(function(input) {
      const isRequired = document.querySelector(`input#${input.value}-required:checked`);
      const field = {
        required: isRequired ? true : false,
        type: input.dataset.type,
        label: input.dataset.label,
        placeholder: input.dataset.placeholder,
        name: input.value,
        checked: input.checked,
        order: input.dataset.order,
      };

      fields.push(field);
    });

    return fields;
  };

  this.updateFields = function() {
    let configSettings = mainForm.input.settings;
    let nextStep = configSettings.next_step;

    document.querySelectorAll(
      `#${this.id} input, #${this.id} select, #${this.id} textarea`,
    ).forEach((input) => {
      let settings = mainForm.input.settings[input.name];
      let options = (settings && settings.options) || {};
      let validatorRules = mainForm.validator.rules.settings;

      if (input.type === 'text') {
        if (input.name === 'url' && nextStep.value === 'go_to_url') {
          options = nextStep.options || {};
          input.value = options.url || '';
        } else if (input.name === 'text_message' && nextStep.value === 'thank_you_text') {
          options = nextStep.options || {};
          input.value = options.text_message || '';
        }
      }

      if (input.type === 'radio') {
        let currentInput = mainForm.input.settings[input.name];
        input.checked = currentInput ? currentInput.value.indexOf(input.value) > -1 : false;

        if (input.name === 'next_step') {
          if (input.value === 'thank_you_text' && input.checked) {
            mainForm.validator.rules.settings = ruleSets.update(validatorRules, 'forThankYouMessage');
          }

          if (input.value === 'go_to_url' && input.checked) {
            mainForm.validator.rules.settings = ruleSets.update(validatorRules, 'forNextStepURL');
          }
        }
      }

      if (input.name === 'optin_process_id') {
        mainForm.input.settings.optin_process.options = {};
        mainForm.input.settings.optin_process.options.optin_process_id = input.value;
      }

      if (input.name === 'privacy_url') {
        mainForm.input.settings.privacy_policy = mainForm.input.settings.privacy_policy || {};
        mainForm.input.settings.privacy_policy.options = mainForm.input.settings.privacy_policy.options || {};

        if (mainForm.input.settings.privacy_policy.options.url) {
          input.value = mainForm.input.settings.privacy_policy.options.url;
        }
      }

      if (input.name === 'privacy_policy') {
        mainForm.input.settings.privacy_policy = mainForm.input.settings.privacy_policy || {};
        let value = mainForm.input.settings.privacy_policy.value;
        if (value) {
          input.checked = input.value.indexOf(value) === 0;
        }
      }

      if (input.name === 'conversion_event') {
        mainForm.input.settings.conversion_event = mainForm.input.settings.conversion_event || {};
        let value = mainForm.input.settings.conversion_event.value;

        if (value) {
          input.value = value;
        }
      }

      if (input.name === 'wheel_embed_form_prize_input_name') {
        mainForm.input.settings.third_party = mainForm.input.settings.third_party || {};
        mainForm.input.settings.third_party.prize_input_name = input.value;
      }
    });
  };

  this.toggleNextStepInputs = function(value) {
    switch (value) {
      case 'go_to_url':
        this.showNextStep('url-text');
        break;
      case 'thank_you_text':
        this.showNextStep('thank-you-text');
        break;
      default:
    }
  };

  this.showNextStep = function(value) {
    let input;

    if (value === 'url-text') {
      input = this.form.querySelector('input[name="url"]');
      mainForm.input.settings.next_step.options = {};
      mainForm.input.settings.next_step.options.url = input.value;
    } else {
      input = this.form.querySelector('input[name="text_message"]');
      mainForm.input.settings.next_step.options = {};
      mainForm.input.settings.next_step.options.text_message = input.value;
    }

    document
      .querySelectorAll('.next-step-action')
      .forEach((element) => element.classList.add('d-none'));

    let inputContainer = this.form.querySelector('.' + value);
    inputContainer.classList.remove('d-none');
  };

  this.initializeEditors = function() {
    // let that = this;

    /*
    tinymce.init({
      selector: '.email-editors',
      absolute_urls: true ,
      remove_script_host: true,
      width: '100%',
      height: '450',
      relative_urls: false,
      plugins: "image link",
      init_instance_callback: function(editor) {
        let name = editor.id;
        let settings = mainForm.input.settings;
        let optinType = settings.optin_type;
        optinType.options = optinType.options || {};
        optinType.options[editor.id] = editor.getContent();

        editor.on('Change', function() {
          let name = editor.id;
          let settings = mainForm.input.settings;
          let optinType = settings.optin_type;
          optinType.options = optinType.options || {};
          optinType.options[editor.id] = editor.getContent();
        });
      },
    });
    */
  };

  this.showOptinMethod = function(name) {
    let validatorRules = mainForm.validator.rules.settings;
    const formFieldsContainer = document.querySelector('.form-fields-container');
    const htmlFormTab = document.querySelector('#html-form-tab');
    const optinListTab = document.querySelector('#optin-list-tab');

    switch(name) {
      case 'embedded-form':
        if (htmlFormTab) bootstrap.Tab.getInstance(htmlFormTab).show();
        if (formFieldsContainer) formFieldsContainer.classList.add('d-none');
        mainForm.input.settings.optin_process.value = 'embedded-form';
        mainForm.validator.rules.settings = ruleSets.update(validatorRules, 'forEmbeddedForm');
        this.buildMatchedInputs();
        if (mainForm.input.slug === 'wheel-of-fortune') {
          this.showWheelEmbedFormPrizeInput();
        }
        break;
      case 'optin-list':
        if (optinListTab) bootstrap.Tab.getInstance(optinListTab).show();
        if (formFieldsContainer) formFieldsContainer.classList.remove('d-none');
        mainForm.input.settings.optin_process.value = 'optin-list';
        this.clearMatchedInputs();
        if (mainForm.input.slug === 'wheel-of-fortune') {
          this.hideWheelEmbedFormPrizeInput();
        }
        break;
      default:
        console.log(`Optin method ${name} not found`);
    }
  };

  this.showWheelEmbedFormPrizeInput = function() {
    const prizeInputContainer = document.querySelector('#wheel-embed-form-prize-input');
    if (prizeInputContainer) prizeInputContainer.classList.remove('d-none');
  };

  this.hideWheelEmbedFormPrizeInput = function() {
    const prizeInputContainer = document.querySelector('#wheel-embed-form-prize-input');
    if (prizeInputContainer) prizeInputContainer.classList.add('d-none');
  };

  this.buildMatchedInputs = function() {
    const originalInputs = this.originalFormInputs();
    const matchedInputsContainer = document.querySelector(
      '.embedded-form-matched-inputs',
    );
    let embeddedFormInputs = [];

    if (mainForm.input.settings.third_party) {
      embeddedFormInputs = mainForm.input.settings.third_party.inputs.normal;
    }

    if (mainForm.input.slug === 'top-bar') return;
    if (embeddedFormInputs.length === 0) return;

    if (matchedInputsContainer.children.length > 0) {
      this.clearMatchedInputs();
    }

    matchedInputsContainer.innerHTML = `
      <p class="font-weight-bold mb-2">
        ${I18n.t('lead_tools.third_party_form.matched_inputs_description')}
      </p>
    `;

    for (let i = 0; i < embeddedFormInputs.length; i++) {
      const input = embeddedFormInputs[i];
      const label = document.createElement('label');
      label.classList.add('form-label');
      label.textContent = input.name;

      const inputDropdown = document.createElement('select');
      inputDropdown.classList.add('form-control', 'mb-2');
      inputDropdown.name = input.name;
      inputDropdown.addEventListener('change', (e) => {
        input.original_name = e.target.value;
      });

      for (let j = 0; j < originalInputs.length; j++) {
        const origInput = originalInputs[j];
        const option = document.createElement('option');
        option.value = origInput.value;
        option.textContent = origInput.name;

        inputDropdown.appendChild(option);

        if (origInput.value.indexOf('email') > -1 &&
            inputDropdown.name.indexOf('mail') > -1) {
          option.selected = true;
          inputDropdown.dispatchEvent(new CustomEvent('change'));
        }
      }

      inputDropdown.value = input.original_name || null;

      let selectContainer = document.createElement('div');
      selectContainer.appendChild(label);
      selectContainer.appendChild(inputDropdown);

      matchedInputsContainer.appendChild(selectContainer);
    }
  };

  this.clearMatchedInputs = function() {
    const matchedInputsContainer = document.querySelector(
      '.embedded-form-matched-inputs',
    );
    matchedInputsContainer.innerHTML = '';
  };

  this.originalFormInputs = function() {
    return [
      { value: 'email', name: I18n.t('lead_tools.input_names.email') },
      { value: 'first_name', name: I18n.t('lead_tools.input_names.first_name') },
      { value: 'last_name', name: I18n.t('lead_tools.input_names.last_name') },
      { value: 'phone', name: I18n.t('lead_tools.input_names.phone') },
      { value: 'city', name: I18n.t('lead_tools.input_names.city') },
      { value: 'country', name: I18n.t('lead_tools.input_names.country') },
      { value: 'street', name: I18n.t('lead_tools.input_names.street') },
      { value: 'street_number', name: I18n.t('lead_tools.input_names.street_number') },
      { value: 'post_code', name: I18n.t('lead_tools.input_names.post_code') },
      { value: 'voucher_code', name: I18n.t('lead_tools.input_names.voucher_code') },
      { value: 'custom', name: I18n.t('lead_tools.input_names.custom') },
    ];
  };

  this.init = function() {
    let method;
    if (mainForm.input.settings.optin_process) {
      method = mainForm.input.settings.optin_process.value || 'optin-list';
      mainForm.input.settings.optin_process.value = method;
      this.showOptinMethod(method);
    } else {
      method = 'optin-list';
      mainForm.input.settings.optin_process = {};
      mainForm.input.settings.optin_process.value = method;
      mainForm.input.settings.optin_process.options = {};
      this.showOptinMethod(method);
    }

    this.updateFields();
    this.initializeEditors();

    const nextStepInputs = [
      ...document.querySelectorAll(`#${this.id} input[name="next_step"]`),
    ];
    nextStepInputs.forEach((input) => {
      input.addEventListener('change', (e) => {
        this.toggleNextStepInputs(e.target.value);
      });
    });

    if (nextStepInputs.length > 0) {
      let checkedInput = nextStepInputs.find((input) => {
        return input.checked;
      });
      this.toggleNextStepInputs(checkedInput.value);
    }

    document
      .querySelectorAll(`#${this.id} input, #${this.id} select`)
      .forEach((input) => {
        input.addEventListener('change', () => {
          this.changeAction(input);
        });
      });

    const thirdParty = mainForm.input.settings.third_party;
    const formHtml = thirdParty && thirdParty.form;
    const formConfig = {
      selector: `#${this.id} .embedded-form`,
      html: formHtml,
      currentResponse: thirdParty,
      callbacks: {
        successCb: (response, html) => {
          if (response.action) {
            mainForm.input.settings.third_party = response;
            mainForm.input.settings.third_party.form = html;
            this.buildMatchedInputs();
            if (mainForm.input.slug === 'wheel-of-fortune') {
              this.showWheelEmbedFormPrizeInput();
            }
          }
        },
        errorCb: null,
        onReset: () => {
          delete (mainForm.input.settings.third_party);
          this.clearMatchedInputs();
          if (mainForm.input.slug === 'wheel-of-fortune') {
            this.hideWheelEmbedFormPrizeInput();
          }
        },
      },
    };

    const thirdPartyForm = new ThirdPartyForm(formConfig);
    thirdPartyForm.load();

    this.form.querySelectorAll('.optin-button').forEach((button) => {
      button.addEventListener('click', (e) => {
        let optinMethod = e.target.dataset.optinMethod;
        this.showOptinMethod(optinMethod);
      });
    });
  };
};

export default OptinForm;