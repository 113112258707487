import { Controller } from '@hotwired/stimulus';
import Swal from 'sweetalert2';
import I18n from '../app/i18n';

export default class extends Controller {

  submit(e) {
    e.preventDefault();

    const checkedBoxes = this
      .element
      .querySelectorAll('input[name="lead_ids[]"]:checked');

    if (checkedBoxes.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: I18n.t('leads.select_leads_to_destroy'),
      });
    } else {
      const confirmMessage = I18n.t(
        'leads.destroy_confirm', { count: checkedBoxes.length },
      );
      if (confirm(confirmMessage) === true) {
        this.element.requestSubmit();
      }
    }
  }
}

