import { Controller } from '@hotwired/stimulus';
import Swal from 'sweetalert2';
import I18n from '../app/i18n';

export default class extends Controller {

  toggleActive(e) {
    const isHomepage = this.element.dataset.homepage;

    if (isHomepage === 'true') {
      e.preventDefault();
      e.target.checked = true;
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: I18n.t('page_settings.unable_to_deactivate'),
      });
    }
  }
}