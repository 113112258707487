import Mustache from 'mustache';
import I18n from '../../../i18n';
import Result from './result';
import ImagePicker from '../../../image_picker';
import requestUtils from '../../../../utils/request';
import IconPicker from 'vanilla-icon-picker/src/js/IconPicker';

const Answer = function(quiz, answer) {
  this.nodeId = answer.nodeId;

  const answerTemplate = document.getElementById('answer-template').innerHTML;
  const container = document.querySelector('.question-inputs .answers');
  const mainForm = quiz.mainForm;
  const answerSet = quiz.answerSet;

  let siteId = document.body.dataset.siteId;
  let uploadURL = '/sites/' + siteId + '/upload_tool_image';
  let deleteURL = '/sites/' + siteId + '/remove_image';
  let text = quiz.fieldHelpers.getValue(answer, 'text');

  answer.result = answer.result || {};
  answerSet.objs.push(this);

  this.text = text;

  this.getQuiz = function() {
    return quiz;
  };

  this.append = function(el) {
    container.appendChild(el);
  };

  this.build = function(opts) {
    opts = opts || {};
    const tmp = document.createElement('div');
    const templateOpts = {
      nodeId: this.nodeId,
      show: opts.show ? 'show' : '',
    };

    const answerBlock = Mustache.render(answerTemplate, templateOpts);
    const nextIndex = container.children.length + 1;

    tmp.innerHTML = answerBlock;

    const el = tmp.firstChild;
    const textInput = el.querySelector('.answer-text');
    const textHeader = el.querySelector('.accordion-header');

    this.element = el;
    textInput.value = text;
    textHeader.innerHTML = I18n.t('lead_tools.quiz.answer.header', { index: nextIndex });

    this.updateHeader(text);

    return el;
  };

  this.updateHeader = function(text) {
    const element = this.element;
    const textHeader = element.querySelector('.accordion-header');

    if (text.length > 40) {
      textHeader.textContent = `${text.substr(0, 40)}...`;
    } else {
      textHeader.textContent = text;
    }
  };

  this.triggerEvents = function() {};

  this.hideAnswerActions = function() {
    const el = this.element;
    el.querySelector('.question-actions').classList.add('d-none');
    el.querySelector('.result-actions').classList.add('d-none');
    el.querySelector('.optin-actions').classList.add('d-none');
    el.querySelector('.result-button-actions').classList.add('d-none');
  };

  this.showQuestionPath = function() {
    const el = this.element;
    el.querySelector('.question-actions').classList.remove('d-none');
    el.querySelector('.result-actions').classList.add('d-none');
  };

  this.showResultActions = function() {
    const el = this.element;
    el.querySelector('.question-actions').classList.add('d-none');
    el.querySelector('.result-actions').classList.remove('d-none');
  };

  this.doNoResultAction = function() {
    const el = this.element;
    el.querySelector('.optin-actions').classList.add('d-none');
    el.querySelector('.result-button-actions').classList.add('d-none');
  };

  this.attachEvents = function(el) {
    const answerText = el.querySelector('.answer-text');
    const actionBar = el.querySelector('.actions');
    const moveUpBtn = actionBar.querySelector('.move-answer-up');
    const moveDownBtn = actionBar.querySelector('.move-answer-down');
    const deleteAnswerBtn = actionBar.querySelector('.delete');

    answerText.addEventListener('change', (e) =>  {
      answer.text = e.target.value;
      this.text = answer.text;
      this.updateHeader(answer.text);

      quiz.updatePreview();
    });

    moveUpBtn.addEventListener('click', (e) => {
      e.preventDefault();
      this.moveUp();
    });

    moveDownBtn.addEventListener('click', (e) => {
      e.preventDefault();
      this.moveDown();
    });

    deleteAnswerBtn.addEventListener('click', (e) => {
      e.preventDefault();
      this.destroy();
    });
  };

  this.add = function(opts) {
    opts = opts || {};
    const el = this.build(opts.templateOpts);
    this.append(el);
    this.initResult();
    this.attachEvents(el);
    this.triggerEvents(el);
    this.loadImagePicker();
    this.loadIconPicker();
  };

  this.initResult = function() {
    this.resultComponent = new Result(this, this.element, answer.result, answer);
    this.resultComponent.load();
  };

  this.moveUp = function() {
    const question = answerSet.question;
    const set = answerSet.set;
    const objs = answerSet.objs;
    const index = answerSet.index(answer);

    if (index > 0) {
      const prevAnswer = set[index - 1];
      const referenceNode = document.querySelector(`.card[data-node-id="${prevAnswer.nodeId}"`);
      const prevAnswerNodeId = prevAnswer.nodeId;
      const currentAnswerNodeId = this.nodeId;

      set[index] = prevAnswer;
      set[index - 1] = answer;

      const prevObj = objs[index - 1];
      objs[index] = prevObj;
      objs[index - 1] = this;

      objs[index - 1].nodeId = prevAnswerNodeId;
      objs[index].nodeId = currentAnswerNodeId;

      quiz.reloadTree();
      this.reloadIds();

      container.insertBefore(this.element, referenceNode);
      quiz.highlightNode(question.nodeId);
      mainForm.updatePreview();
    }
  };

  this.moveDown = function() {
    const question = answerSet.question;
    const objs = answerSet.objs;
    const set = answerSet.set;
    const index = answerSet.index(answer);

    if (index !== set.length - 1) {
      const nextAnswer = set[index + 1];
      const nextAnswerNodeId = nextAnswer.nodeId;
      const currentAnswerNodeId = answer.nodeId;
      const referenceAnswer = set[index + 2];
      const referenceNode = referenceAnswer
        ? document.querySelector(`.card[data-node-id=${referenceAnswer.nodeId}]`)
        : null;

      set[index] = nextAnswer;
      set[index + 1] = answer;

      const nextObj = objs[index + 1];
      objs[index] = nextObj;
      objs[index + 1] = this;

      objs[index].nodeId = currentAnswerNodeId;
      objs[index + 1].nodeId = nextAnswerNodeId;

      quiz.reloadTree();

      container.insertBefore(this.element, referenceNode);
      this.reloadIds();
      quiz.highlightNode(question.nodeId);
      mainForm.updatePreview();
    }
  };

  // Updating the id that is inside the tree
  this.reloadIds = function() {
    const answerObjs = answerSet.objs;

    answerObjs.forEach((answer, i) => {
      const nodeId = answer.nodeId;

      const element = answer.element;

      const optinTab = element.querySelector('.optin-tab');
      const optinTabPane = element.querySelector('.optin');
      const htmlFormTab = element.querySelector('.html-form-tab');
      const htmlFormTabPane = element.querySelector('.html-form');
      const textHeader = element.querySelector('.accordion-header');

      textHeader.textContent = answer.text
        || I18n.t('lead_tools.quiz.answer.header', { index: i + 1 });

      element.dataset.nodeId = answer.nodeId;
      const newOptinTabId = `optin-tab-${nodeId}`;
      const newHtmlFormTabId = `html-form-tab-${nodeId}`;
      const newOptinTabPaneId = `optin-${nodeId}`;
      const newHtmlFormTabPaneId = `html-form-${nodeId}`;

      optinTab.id = newOptinTabId;
      optinTab.setAttribute('href', `#${newOptinTabPaneId}`);
      optinTab.setAttribute('aria-controls', newOptinTabId);

      optinTabPane.id = newOptinTabPaneId;
      optinTabPane.setAttribute('arial-labelledby', newOptinTabId);

      htmlFormTab.id = newHtmlFormTabId;
      htmlFormTab.setAttribute('href', `#${newHtmlFormTabPaneId}`);
      htmlFormTab.setAttribute('aria-controls', newHtmlFormTabId);

      htmlFormTabPane.id = newHtmlFormTabPaneId;
      htmlFormTabPane.setAttribute('arial-labelledby', newHtmlFormTabId);
    });
  };

  this.loadImagePicker = function() {
    const el = this.element;
    const pickerEl = el.querySelector('.image-drop');
    const currentImage = answer.image && answer.image.url;

    new ImagePicker(
      pickerEl,
      uploadURL,
      (response) => {
        answer.image = {};
        answer.image.url = response.data.url;
        quiz.updatePreview();
      },
      (url) => {
        let body = {
          url: url,
        };

        requestUtils.postJson(deleteURL, body).then(() => {
          answer.image = {};
          mainForm.updatePreview();
        });
      },
      currentImage,
      '300x200',
    );
  };

  this.loadIconPicker = function() {
    const el = this.element;
    const pickerEl = el.querySelector('.icon-picker');

    pickerEl.innerHTML = answer.icon
      ? `<i class='${answer.icon}'></i>`
      : I18n.t('lead_tools.quiz.select_icon');

    const iconPicker = new IconPicker(pickerEl, {
      theme: 'bootstrap-5',
      iconSource: [
        {
          key: 'font-awesome-5-regular',
          prefix: 'far fa-',
          url: 'https://scripts.masterpages.com/fonts/fa-regular-iconset.json',
        },
        {
          key: 'font-awesome-5-brands',
          prefix: 'fab fa-',
          url: 'https://scripts.masterpages.com/fonts/fa-brands-iconset.json',
        },
        {
          key: 'font-awesome-5-solid',
          prefix: 'fas fa-',
          url: 'https://scripts.masterpages.com/fonts/fa-solid-iconset.json',
        },
      ],
      closeOnSelect: true,
      defaultValue: answer.icon || null,
      i18n: {
        'input:placeholder': I18n.t('lead_tools.quiz.search_icon'),
        'text:title': I18n.t('lead_tools.quiz.select_icon'),
        'text:empty': I18n.t('lead_tools.quiz.no_results_found'),
      },
    });

    if (answer.icon) this.showDeleteIconBtn();

    iconPicker.on('select', (icon) => {
      answer.icon = icon.value;
      pickerEl.innerHTML = `<i class='${icon.value}'></i>`;
      this.showDeleteIconBtn();
      quiz.updatePreview();
    });
  };

  this.showDeleteIconBtn = function() {
    const el = this.element;
    const iconFormGroup = el.querySelector('.answer-icon-form-group');
    const pickerEl = el.querySelector('.icon-picker');

    if (iconFormGroup.querySelector('.delete-icon-btn')) {
      return;
    }

    const deleteIconBtn = document.createElement('button');
    deleteIconBtn.className = 'btn btn-link btn-sm ms-2 text-danger text-decoration-none delete-icon-btn';
    deleteIconBtn.textContent = I18n.t('lead_tools.quiz.delete');
    deleteIconBtn.type = 'button';
    iconFormGroup.appendChild(deleteIconBtn);

    deleteIconBtn.addEventListener('click', (e) => {
      e.preventDefault();
      answer.icon = null;
      pickerEl.innerHTML = I18n.t('lead_tools.quiz.select_icon');
      this.hideDeleteIconBtn();
      quiz.updatePreview();
    });
  };

  this.hideDeleteIconBtn = function() {
    const el = this.element;
    const iconFormGroup = el.querySelector('.answer-icon-form-group');
    const deleteIconBtn = iconFormGroup.querySelector('.delete-icon-btn');

    if (deleteIconBtn) {
      deleteIconBtn.remove();
    }
  };

  this.destroy = function() {
    const question = answerSet.question;

    answerSet.remove(answer);

    this.element.remove();

    quiz.reloadTree();
    quiz.highlightNode(question.nodeId);
    mainForm.updatePreview();

    this.reloadIds();
  };
};

export default Answer;
