import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'keywordsArea',
    'title'
  ]

  copy(event) {
    let text = event.target.firstChild.textContent

    if (this.keywordsAreaTarget.value.trim() == '') {
      this.keywordsAreaTarget.value += text;
    } else {
      this.keywordsAreaTarget.value += ', ' + text;
    }
  }

  copyTitle(event) {
    this.titleTarget.value = event.currentTarget.text
  }

  clearArea() {
    this.keywordsAreaTarget.value = ''
  }
}
