import I18n from '../../i18n';
import messageHelper from '../../../utils/message_helper';
import request from '../../../utils/request';
import canvasHelper from '../../../utils/canvas_helper';

const ThirdPartyForm = function(conf) {
  const config = conf || {};
  const parserUrl = '/forms/parse';
  const selector = config.selector;
  const element = config.element;

  const formContainer = element || document.querySelector(selector);
  const callbacks = config.callbacks || {};

  if (!formContainer) {
    throw `Form ${selector} not found`;
  }

  const currentSettings = conf.currentResponse;
  const formMessage = formContainer.querySelector('.message');
  const formInput = formContainer.querySelector('textarea.form-control');
  const submitBtn = formContainer.querySelector('.submit');
  const resetBtn = formContainer.querySelector('.reset');
  let integrationTitle = formContainer.querySelector('.integration-title');
  let integrationName = formContainer.querySelector('.integration-name');
  const successCb = callbacks.successCb;
  const errorCb = callbacks.errorCb;
  const onReset = callbacks.onReset;

  formInput.value = config.html || '';

  this.showSuccess = function() {
    formContainer.querySelectorAll('.custom-alert').forEach((alert) => alert.remove());
    messageHelper.createSuccessMessage(I18n.t('formIntegrated'), formMessage);
    resetBtn.classList.remove('d-none');
  };

  this.showError = function() {
    formContainer.querySelectorAll('.custom-alert').forEach((alert) => alert.remove());
    messageHelper.createErrorMessage(I18n.t('errorParsingForm'), formMessage);
    resetBtn.classList.add('d-none');
  };

  this.submit = function() {
    const input = formContainer.querySelector('textarea.form-control');
    const html = input.value;
    const authenticityToken = document.body.dataset.authenticityToken;
    const params = {
      authenticity_token: authenticityToken,
    };

    if (html) {
      params.form = html;

      request.postJson(parserUrl, params)
        .then((response) => {
          let formData = JSON.parse(response);
          this.showIntegrationName(formData);
          this.showSuccess();
          if (typeof successCb === 'function') {
            successCb(formData, html);
          }
        }).catch((error) => {
          this.showError();

          if (typeof errorCb === 'function') {
            errorCb(error);
          } else {
            console.log('Error parsing form', error);
          }
        });
    } else {
      messageHelper.createErrorMessage(I18n.t('errorFormNotPresent'), formMessage);
    }
  };

  this.reset = function() {
    formInput.value = '';
    if (typeof onReset === 'function') {
      onReset();
    }

    formContainer.querySelectorAll('.custom-alert').forEach((alert) => alert.remove());
    resetBtn.classList.add('d-none');
    this.removeIntegrationName();
  };

  this.load = function() {
    if(!config.html) {
      this.reset();
    }

    submitBtn.addEventListener('click', (e) => {
      e.preventDefault();
      this.submit();
    });

    resetBtn.addEventListener('click', (e) => {
      e.preventDefault();
      this.reset();
    });

    if (currentSettings) {
      this.showIntegrationName(currentSettings);
    }
  };

  this.showIntegrationName = function(formData) {
    const integration = formData.integration || {};
    const name = integration.name || '';
    if (name) {
      let title = ' ' + canvasHelper.capitalize(name.split('_').join(' '));
      integrationName.textContent = title;
      integrationTitle.classList.remove('d-none');
    }
  };

  this.removeIntegrationName = function() {
    integrationTitle.classList.add('d-none');
    integrationName.textContent = '';
  };
};

export default ThirdPartyForm;