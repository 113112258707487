import { Controller } from '@hotwired/stimulus';
import { post } from '@rails/request.js';

export default class extends Controller {
  static targets = [ 'submitBtn', 'errorMessage' ]
  static values = { url: String, successUrl: String }

  async verify(e) {
    e.preventDefault();
    this.submitBtnTarget.disabled = true;
    this.errorMessageTarget.innerHTML = '';
    const submitBtnText = this.submitBtnTarget.innerHTML;

    this.submitBtnTarget.innerHTML = `
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <span class="visually-hidden">${submitBtnText}</span>
    `;  

    const response  = await post(this.urlValue, { responseKind: 'json' });

    this.submitBtnTarget.disabled = false;
    this.submitBtnTarget.innerHTML = submitBtnText;

    if (response.ok) {
      const {success, error_message} = await response.json;

      if (success) {
        Turbo.visit(this.successUrlValue);
      } else {
        this.appendErrorMessage(error_message);
      }  
    } else {
      this.appendErrorMessage(window.I18n.t('domain_dns_verification_controller.unable_to_verify'));
    }
  }

  appendErrorMessage(errorMessage) {
    this.errorMessageTarget.innerHTML = `
      <div class="alert alert-danger" role="alert">
        ${errorMessage}
      </div>
    `
  }
}