import I18n from '../../i18n';
import helpers from '../../utils/helpers';
import Result from './quiz/result';
import Answer from './quiz/answer';
import InputAnswer from './quiz/input_answer';
import toolPreview from '../preview';
import TextMerger from '../../utils/text_merger';
import Raphael from 'raphael';
import Treant from 'treantjs/Treant';

window.Raphael = Raphael;

const QuestionSet = function(question, questions, treeNode) {
  this.current = function() {
    const index = this.index();
    return this.set[index] || null;
  };

  this.question = question;
  this.treeNode = treeNode;

  this.add = function(question) {
    this.set.push(question);
    this.question = question;
  };

  this.set = questions;
  this.index = function() {
    return this.set.indexOf(this.question);
  };

  this.remove = function() {
    const index = this.index();
    this.set.splice(index, 1);
  };
};

const AnswerSet = function(question, answers) {
  this.question = question;
  this.set = answers;
  this.objs = [];

  this.index = function(answer) {
    return this.set.indexOf(answer);
  };

  this.remove = function(answer) {
    const index = this.index(answer);
    this.set.splice(index, 1);
    this.objs.splice(index, 1);
  };
};

const Quiz = function(mainForm) {
  const input = mainForm.input;
  const questions = input.content.questions;
  const quizTreeEl = document.getElementById('quiz-tree');
  const mainContainer = document.querySelector('.quiz');
  const questionBar = document.querySelector('.quiz .actions.question-bar');
  const nodeModels = document.querySelector('.quiz-legend');
  const newAnswerCont = document.querySelector('.question-inputs .node-actions');
  const action = mainForm.action;

  let questionDb = {};
  let answerDb = {};
  let resultDb = {};

  this.fieldHelpers =  {
    getValue: function(field, name) {
      if (action !== 'new') {
        return field[name];
      }

      const tag = field[`${name}_locale_tag`];
      const locale = tag && I18n.t(tag);
      return locale || field[name];
    },
  };

  this.mainForm = mainForm;
  this.rootContainer = mainForm.rootContainer;
  this.processedQuestions = 0;
  this.answerTextareaEl = `
    <div class="answer-textarea">
      <textarea col="10", row="10"></textarea>
      <a href="#">${I18n.t('lead_tools.quiz.input.continue')}</a>
    </div>
  `;
  this.answerInputLineEl = `
    <div class="answer-input">
      <input type="text">
      <a href="#">${I18n.t('lead_tools.quiz.input.continue')}</a>
    </div>
  `;

  if (!questions) {
    throw 'No questions found';
  }

  const treeConfig =  {
    chart: {
      container: '#quiz-tree',
      callback: {
        onCreateNode: (node, nodeEl) => {
          const type = nodeEl.id.split('-')[0];

          if (type === 'q') {
            questionDb[nodeEl.id].treeNode = node;
          }

          nodeEl.addEventListener('click', () => {
            let clickedNode;
            let relatedQuestion;
            const that = this;

            switch(type) {
              case 'a':
                clickedNode = answerDb[nodeEl.id];
                relatedQuestion = clickedNode.parentQuestion;
                break;
              case 'q':
                clickedNode = questionDb[nodeEl.id].question;
                relatedQuestion = clickedNode;
                break;
              case 'r':
                clickedNode = resultDb[nodeEl.id];
                relatedQuestion = clickedNode.parentQuestion;
                break;
              default:
                throw `Node in tree not found for: ${nodeEl.id}`;
            }

            const loadQuestion = function() {
              that.loadQuestion(relatedQuestion.nodeId);
            };

            mainForm.validate(loadQuestion(), loadQuestion());
          });
        },
      },
      hideRootNode: false,
      levelSeparation: 20,
      siblingSeparation: 10,
      rootOrientation: 'WEST',
      nodeAlign: 'BOTTOM',
      connectors: {
        type: 'step',
        style: {
          'stroke-width': 2,
          stroke: '#ccc',
          'stroke-dasharray': '-',
          'arrow-end': 'classic-wide-long',
        },
      },
    },
    nodeStructure: {
      HTMLid: 'tree-start',
      children: [],
    },
  };

  this.getQuiz = function() {
    return this;
  };

  this.getCurrentQuestion = function() {
    const row = questionDb[this.currentNodeId];
    const question = row && row.question;

    return question;
  };

  this.getAlphabeticLabel = function(index) {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
    const cycle = parseInt(index / alphabet.length);
    let id = alphabet[index % alphabet.length];

    if (cycle > 0) {
      id = id + cycle;
    }

    return id.toUpperCase();
  };

  this.buildNodeIds = function(questions, baseId, level) {
    level = !level ? 0 : level;

    questions.forEach((question, i) => {
      const questionId = !baseId ? i : `${baseId}-${i}`;
      const htmlId = `q-${questionId}`;
      const answers = question.answers || [];

      question.nodeId = htmlId;
      question.answer_type = question.answer_type || 'choice';

      questionDb[htmlId] = {
        set: questions,
        question: question,
      };

      if (!helpers.isObjectEmpty(question.result)) {
        const result = question.result;
        result.uuid = result.uuid || helpers.uuidv4();

        resultDb[htmlId] = {
          result: result,
          set: result,
          parentQuestion: question,
        };
      }

      answers.forEach((answer, j) => {
        const answerId =  `${questionId}-${j}`;
        const htmlId = `a-${answerId}`;

        answer.nodeId = htmlId;

        answerDb[htmlId] = {
          answer: answer,
          set: answers,
          parentQuestion: question,
        };

        if (answer.result) {
          const result = answer.result;
          const resultId = `${answerId}`;
          const htmlId = `r-${resultId}`;

          result.nodeId = htmlId;
          result.uuid = result.uuid || helpers.uuidv4();

          resultDb[htmlId] = {
            result: result,
            set: result,
            parentQuestion: question,
          };
        }

        if (answer.questions) {
          this.buildNodeIds(answer.questions, answerId, level + 1);
        }
      });
    });
  };

  this.translateToTreeFormat = function(node, questions, level) {
    node.children = [];
    level = !level ? 0 : level;

    questions.forEach((question, i) => {
      const answers = question.answers;
      const isStart = level === 0 && i === 0;

      node.children[i] = {
        innerHTML: this.buildNode('question', question, i + 1, isStart),
        HTMLid: question.nodeId,
      };

      this.processedQuestions += 1;

      const questionNode = node.children[i];
      questionNode.children = [];

      answers && answers.length && answers.forEach((answer, j) => {
        const label = this.getAlphabeticLabel(j);

        questionNode.children[j] = {
          innerHTML: this.buildNode('answer', answer, label),
          HTMLid: answer.nodeId,
          text: answer.text,
        };

        const answerNode = questionNode.children[j];
        answerNode.children = [];

        if (question.action === 'show-result') {
          const result = question.result || {};
          const resultId = result.nodeId;

          answerNode.children = [{
            innerHTML: this.buildNode('result', result, ''),
            HTMLid: resultId,
            text: '',
          }];
        } else {
          const result = answer.result || {};
          const questions = answer.questions || [];
          switch (answer.action) {
            case 'show-result':
              answerNode.children = [{
                innerHTML: this.buildNode('result', result, ''),
                HTMLid: result.nodeId,
                text: result.text,
              }];
              break;
            case 'show-question-path':
              this.translateToTreeFormat(answerNode, questions, level + 1);
              break;
            default:
          }
        }
      });
    });
  };

  this.buildNode = function(type, nodeInfo, text, start) {
    const tmp = document.createElement('div');
    const node = nodeModels.querySelector(`.quiz-legend .${type}-model`).cloneNode(true);
    const textNode = document.createElement('p');
    const iconNode = document.createElement('i');

    switch(type) {
      case 'question':
        iconNode.className = start ? 'bi bi-play-circle' : 'bi bi-question';
        break;
      case 'answer':
        iconNode.className = 'bi bi-check-square';
        break;
      case 'result':
        iconNode.className = 'bi bi-check';
        break;
      default:
        iconNode.className = '';
    }

    textNode.textContent = ' ' + text;

    node.appendChild(iconNode);
    node.appendChild(textNode);

    tmp.appendChild(node);
    return tmp.innerHTML;
  };

  this.resetHighlight = function() {
    const highlighted = quizTreeEl.querySelectorAll('.highlight');
    highlighted.forEach((el) => el.classList.remove('highlight'));
  };

  this.highlightNode = function(nodeId) {
    let root;

    if (nodeId === -1) {
      root = quizTreeEl.querySelector('#tree-start');
      root.classList.add('highlight');
      return;
    }

    const qNode = quizTreeEl.querySelector(`#${nodeId}`);
    const levels = nodeId.split('-');
    const level = levels.slice(1).join('-');
    let count = 0;
    let baseId, id, node;

    this.resetHighlight();

    qNode && qNode.classList.add('highlight');

    // and its children
    while(true) {
      baseId = `#a-${level}`;
      id = `${baseId}-${count}`;
      node = quizTreeEl.querySelector(id);

      if (!node) break;

      node && node.classList.add('highlight');
      count += 1;
    }
  };

  this.moveQuestionUp = function() {
    const questionSet = this.questionSet;
    const index = questionSet.index();
    const set = questionSet.set;
    const question = questionSet.current();

    if (index > 0) {
      let prevQuestion = set[index - 1];
      set[index] = prevQuestion;
      set[index - 1] = question;

      this.reloadTree();

      this.highlightNode(question.nodeId);
    }
  };

  this.moveQuestionDown = function() {
    const questionSet = this.questionSet;
    const index = questionSet.index();
    const set = questionSet.set;
    const question = questionSet.current();

    if (index !== set.length - 1) {
      let nextQuestion = set[index + 1];
      set[index] = nextQuestion;
      set[index + 1] = question;

      this.reloadTree();

      this.highlightNode(question.nodeId);
    }
  };

  this.openChoiceAnswer = function(reloadTree) {
    const openChoiceBtn = mainContainer.querySelector('.open-choice');
    const openInputBtn = mainContainer.querySelector('.open-multi');
    const question = this.getCurrentQuestion();
    const answerType = question.answer_type;

    openChoiceBtn.classList.add('active');
    openInputBtn.classList.remove('active');

    if (answerType === 'input') {
      question.input_answer = question.answers[0] || {};
      question.answers = question.choice_answers || [];
    }

    const answerTypes = document.querySelectorAll('.answer-types');
    answerTypes.forEach((answerType) => {
      answerType.classList.add('d-none');
    });

    const multiChoicesAnswers = document.querySelectorAll(
      '.answer-types.multi-choices-answer',
    );
    multiChoicesAnswers.forEach((multiChoicesAnswerEl) => {
      multiChoicesAnswerEl.classList.remove('d-none');
    });

    question.answer_type = 'choice';

    if (reloadTree) {
      this.loadAnswers(question.answers);
      this.reloadTree();
    }

    mainForm.updatePreview();
  };

  this.openInputAnswer = function(reloadTree) {
    const openChoiceBtn = mainContainer.querySelector('.open-choice');
    const openInputBtn = mainContainer.querySelector('.open-multi');
    const question = this.getCurrentQuestion();
    const answerType = question.answer_type;

    openInputBtn.classList.add('active');
    openChoiceBtn.classList.remove('active');

    if (answerType === 'choice') {
      question.choice_answers = question.answers;
      question.answers = [question.input_answer || {}];
    }

    const answerTypes = document.querySelectorAll('.answer-types');
    answerTypes.forEach((answerType) => {
      answerType.classList.add('d-none');
    });

    const textInputAnswers = document.querySelectorAll('.answer-types.text-input-answer');
    textInputAnswers.forEach((textInputAnswerEl) => {
      textInputAnswerEl.classList.remove('d-none');
    });

    question.answer_type = 'input';

    if (reloadTree) {
      this.loadAnswers(question.answers);
      this.reloadTree();
    }

    mainForm.updatePreview();
  };

  this.loadQuestion = function(nodeId) {
    const textInput = document.getElementById('question-text');
    const textDesc = document.getElementById('question-desc');
    const row = questionDb[nodeId];
    const question = row.question;
    const set = row.set;
    const answers = question.answers || [];
    const defaultResultCheck = mainContainer.querySelector('.default-result-check');
    const defaultResultEl = mainContainer.querySelector(
      '.question-inputs .default-result',
    );
    const answerType = question.answer_type || 'choice';

    this.currentNodeId = nodeId;
    question.result = question.result || {};

    this.resultComponent = new Result(this, defaultResultEl, question.result, question);
    this.resultComponent.load();

    switch(answerType) {
      case 'choice':
        this.openChoiceAnswer();
        break;
      case 'input':
        this.openInputAnswer();
    }

    if (question.action === 'show-result') {
      defaultResultCheck.checked = true;
    } else {
      defaultResultCheck.checked = false;
    }

    this.questionSet = new QuestionSet(question, set, row.treeNode);

    textInput.value = this.fieldHelpers.getValue(question, 'text');
    textDesc.value = this.fieldHelpers.getValue(question, 'description');

    this.toggleDefaultResult(defaultResultCheck.checked);
    this.highlightNode(nodeId);
    this.loadAnswers(answers);

    mainForm.updatePreview();
  };

  this.loadAnswers = function(answers, opts) {
    const questionSet = this.questionSet;
    const answersCont = document.querySelector('.question-inputs .answers');
    const question = questionSet.current();
    const answerType = question.answer_type;

    helpers.empty(answersCont);

    this.answerSet = new AnswerSet(question, answers);

    answers.forEach((answerData) => {
      const answerObj = answerType === 'input' ? InputAnswer : Answer;
      const answer = new answerObj(this, answerData);
      answer.add();
    });
  };

  this.attachEvents = function() {
    const moveUpBtn = questionBar.querySelector('.move-question-up');
    const moveDownBtn = questionBar.querySelector('.move-question-down');
    const deleteBtn = questionBar.querySelector('.delete');
    const addQuestion = questionBar.querySelector('.add');
    const addAnswerBtn = newAnswerCont.querySelector('.add-answer');
    const defaultResultCheck = mainContainer.querySelector('.default-result-check');
    const questionText = mainContainer.querySelector('#question-text');
    const questionDesc = mainContainer.querySelector('#question-desc');
    const addFirstQuestion = mainContainer.querySelector('.no-questions .add-question');
    const openChoiceBtn = mainContainer.querySelector('.open-choice');
    const openInputBtn = mainContainer.querySelector('.open-multi');

    moveUpBtn.addEventListener('click', () => {
      this.moveQuestionUp();
    });

    moveDownBtn.addEventListener('click', () => {
      this.moveQuestionDown();
    });

    deleteBtn.addEventListener('click', () => {
      const questionSet = this.questionSet;
      if (!questionSet) {
        return;
      }
      this.destroyCurrentQuestion();
    });

    addAnswerBtn.addEventListener('click', () => {
      this.addNewAnswer();
    });

    addQuestion.addEventListener('click', () => {
      this.addNewQuestion();
    });

    defaultResultCheck.addEventListener('change', (e) => {
      this.toggleDefaultResult(e.target.checked);
    });

    questionText.addEventListener('change', (e) => {
      const questionSet = this.questionSet;
      const question = questionSet.current();
      question.text = e.target.value;
      mainForm.updatePreview();
    });

    questionDesc.addEventListener('change', (e) => {
      const questionSet = this.questionSet;
      const question = questionSet.current();
      question.description = e.target.value;
      mainForm.updatePreview();
    });

    addFirstQuestion.addEventListener('click', () => {
      this.addNewQuestion();
    });

    openChoiceBtn.addEventListener('click', (e) => {
      e.preventDefault();

      if (e.target.classList.contains('active')) {
        return;
      }

      this.openChoiceAnswer(true);
    });

    openInputBtn.addEventListener('click', (e) => {
      e.preventDefault();

      if (e.target.classList.contains('active')) {
        return;
      }

      this.openInputAnswer(true);
    });
  };

  this.toggleDefaultResult = function(checked) {
    const questionSet = this.questionSet;
    const defaultResult = mainContainer.querySelector('.question-inputs .default-result');
    const question = questionSet.current();

    if (checked) {
      question.action = 'show-result';
      this.resultComponent.loadValues();
      this.resultComponent.triggerEvents();
      defaultResult.classList.remove('d-none');
    } else {
      question.action = '';
      defaultResult.classList.add('d-none');
    }

    this.reloadTree();
  };

  this.destroyCurrentQuestion = function() {
    const questionSet = this.questionSet;
    const questions = questionSet.set;
    const questionNode = questionSet.treeNode;
    const question = questionSet.current();
    let nodeId = question.nodeId;
    const index = questionSet.index();
    const parentNode = questionNode.parent();

    questionSet.remove();

    delete questionDb[nodeId];

    if (questions.length > 0) {
      // It was the last
      // It takes the current last question node Id
      if (index >= questions.length) {
        nodeId = questions[questions.length - 1].nodeId;
      }

      this.reloadTree(nodeId);
      this.loadQuestion(nodeId);
    } else {
      if (parentNode.parentId !== -1) {
        this.loadParentQuestion(parentNode);
      } else {
        // Root parent
        questionSet.question = null;
        this.answerSet = null;
        this.showNoQuestionsMessage();
        this.reloadTree(-1);
        mainForm.updatePreview();
      }
    }
  };

  this.hideNoQuestionsMessage = function() {
    const noQuestions = mainContainer.querySelector('.no-questions');
    const inputs = mainContainer.querySelector('.question-inputs');
    noQuestions.classList.add('d-none');
    inputs.classList.remove('d-none');
  };

  this.showNoQuestionsMessage = function() {
    const noQuestions = mainContainer.querySelector('.no-questions');
    const inputs = mainContainer.querySelector('.question-inputs');
    noQuestions.classList.remove('d-none');
    inputs.classList.add('d-none');
  };

  this.loadParentQuestion = function(parent) {
    while(parent) {
      const nodeEl = parent.nodeDOM;
      const nodeId = nodeEl.id;

      if (nodeId.startsWith('q')) {
        this.reloadTree(nodeId);
        this.loadQuestion(nodeId);
        break;
      } else {
        if (typeof parent.parent === 'function') {
          parent = parent.parent();
        }
      }
    }
  };

  this.addNewQuestion = function() {
    const questionSet = this.questionSet;
    mainForm.validate(() => {
      const question = {
        text: I18n.t('lead_tools.quiz.new_question.title'),
        description: I18n.t('lead_tools.quiz.new_question.description'),
        answers: [{
          text: I18n.t('lead_tools.quiz.new_question.answer.text'),
          value: I18n.t('lead_tools.quiz.new_question.answer.value')
        }],
      };

      this.hideNoQuestionsMessage();
      questionSet.add(question);
      this.buildNodeIds(questions);

      this.loadQuestion(question.nodeId);
      mainForm.updatePreview();
    },
    null,
    {
      validate_on: 'question_add',
    });
  };

  this.loadQuestionPath = function(answer) {
    const questionSet = this.questionSet;
    answer.questions = answer.questions || [];
    const questions = answer.questions;

    if (questions.length > 0) {
      this.loadQuestion(questions[0].nodeId);
    } else {
      questionSet.set = answer.questions,
      this.addNewQuestion();
    }
  };

  this.addNewAnswer = function() {
    const questionSet = this.questionSet;
    const config = {
      text: I18n.t('lead_tools.quiz.answer.text'), // For locale
      value: '',
    };
    const answers = this.answerSet.set;
    const question = questionSet.current();
    const buildOptions = {
      templateOpts: {
        show: true,
      },
    };

    let answer;
    answers.push(config);
    this.reloadTree(question.nodeId);

    answer = new Answer(this, config);
    answer.add(buildOptions);

    mainForm.updatePreview();
  };

  this.reloadTree = function(nodeId) {
    treeConfig.nodeStructure.children = [];

    const questionSet = this.questionSet;
    const nodeTree = treeConfig.nodeStructure;
    const treeContainerHeight = quizTreeEl.offsetHeight;
    questionDb = {};
    resultDb = {};
    answerDb = {};

    this.processedQuestions = 0;

    quizTreeEl.style.height = treeContainerHeight + 'px';

    this.tree.destroy();
    this.buildNodeIds(questions);
    this.translateToTreeFormat(nodeTree, questions, 0);
    if (window.Treant) this.tree = new window.Treant(treeConfig);

    const question = questionSet.current();

    if (question) {
      nodeId = question.nodeId;
      questionSet.treeNode = questionDb[question.nodeId].treeNode;
    }

    nodeId && this.highlightNode(nodeId);
    quizTreeEl.style.height = '';
  };

  this.updatePreview = function() {
    const questionSet = this.questionSet;
    const question = questionSet.current();
    const cssSettings = this.prepareDesignSettings();

    if (!question) {
      toolPreview.hide();
      return;
    }

    const htmlVariables = this.prepareContent();
    toolPreview.update(htmlVariables, cssSettings);
  };

  this.prepareContent = function() {
    const questionSet = this.questionSet;
    const input = mainForm.input;
    const question = questionSet.current();
    const questionText = this.fieldHelpers.getValue(question, 'text');
    const questionDescription = this.fieldHelpers.getValue(question, 'description');
    const qVariables = {
      text: questionText,
      description: questionDescription,
      options: this.buildOptions(),
    };
    const variables = Object.assign(qVariables, input.content);

    return variables;
  };

  this.prepareDesignSettings = function() {
    return mainForm.input.design_settings;
  };

  this.buildInputOption = function() {
    const question = this.getCurrentQuestion();
    const inputType = question.input_type || 'textarea';
    const options = [];
    const inputEl = inputType === 'textarea'
      ? this.answerTextareaEl
      : this.answerInputLineEl;
    options.push(inputEl);
    return options.join('');
  };

  this.buildOptions = function() {
    const questionSet = this.questionSet;
    const question = questionSet.current();
    const answerType = question.answer_type || 'choice';
    const answers = question.answers;
    const options = [];
    let currentDesign, optionTemplate;

    if (mainForm.isQuiz) {
      currentDesign = mainForm.getChosenDesign();
    } else {
      currentDesign = mainForm.currentDesign;
    }

    optionTemplate = currentDesign.quiz_option_html_template;

    if (!optionTemplate) {
      throw 'HTML option template not found';
    }

    if (answerType === 'input') {
      return this.buildInputOption();
    }

    answers.forEach((answer) => {
      let optionHtml;
      const tmp = document.createElement('div');
      const answerText = this.fieldHelpers.getValue(answer, 'text');
      const variables = {
        value: answer.value,
        id: answer.nodeId,
        name: question.nodeId,
        text: answerText,
      }

      if (answer.image) {
        variables.image_url = answer.image.url;
      }

      if (answer.icon) {
        variables.icon = answer.icon;
      }

      optionHtml = TextMerger.merge(optionTemplate, variables);
      if (!variables.image_url) {
        optionHtml = optionHtml.replace(new RegExp('<img.*>', 'g'), '');
      }

      if (!variables.icon) {
        optionHtml = optionHtml.replace(new RegExp('<i.*></i>', 'g'), '');
      }

      tmp.innerHTML = optionHtml;
      options.push(tmp.innerHTML);
    });

    return options.join('');
  };

  this.load = function() {
    const nodeTree = treeConfig.nodeStructure;

    this.buildNodeIds(questions);
    this.translateToTreeFormat(nodeTree, questions, 0);
    if (window.Treant) this.tree = new window.Treant(treeConfig);

    this.attachEvents();

    this.questionSet = new QuestionSet(null, questions, null);

    if (questions.length) {
      this.loadQuestion('q-0');
    } else {
      this.showNoQuestionsMessage();
    }
  };
};

export default Quiz;